import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Icon, Grid } from '@mui/material'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import { CMS_PATH } from '../../../conf/basepath'
import Burger from './Burger'
import NavBar from './NavBar'

class App extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        open: false,
    }

    componentDidMount() {
        if (window.location.href.split('#')[1] === '/') {
            this.props.push('/home')
        }
    }

    componentDidUpdate() {
        if (window.location.href.split('#')[1] === '/') {
            this.props.push('/home')
        }
    }

    backHome = () => {
        this.props.push('/home')
        window.scrollTo(0, 0)
    }

    toggleBurger = () => {
        const { open } = this.state
        this.setState({ open: !open })
    }

    render() {
        const { cmsLogo } = this.props
        const logoUrl = cmsLogo && cmsLogo.document && cmsLogo.document.length ? CMS_PATH + cmsLogo.document[0].name : ''

        return (
            <div>
                <NavBar />
                <Burger toggleBurger={this.toggleBurger} open={this.state.open} />
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{
                        zIndex: '1000',
                        position: 'fixed',
                        bottom: '0',
                        height: '60px',
                        backgroundColor: 'white',
                        padding: '0 15px',
                        borderTop: 'solid 1px grey',
                    }}
                >
                    <Grid item className='clickable' >
                        <Icon onClick={this.toggleBurger} style={{ fontSize: '2rem' }}>
                            menu
                        </Icon>
                    </Grid>
                    <Grid item className='clickable' onClick={this.backHome}>
                        {logoUrl && (
                            <img src={logoUrl} alt='' height='40px' />
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

App.propTypes = {
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    push: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        cmsLogo: store.HomeReducer.cmsLogo,
    }
}

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
