import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { compact } from 'lodash'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import TileWMS from 'ol/source/TileWMS'
import { OSM, Vector as VectorSource } from 'ol/source'
import { Point } from 'ol/geom'
import { Style, Icon as Marker } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import { Feature, Map, Overlay, View } from 'ol'
import { ScaleLine, Rotate, defaults as defaultControls } from 'ol/control'
import { Grid, Icon, Checkbox, Slide, Card, Slider } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import { getWGS84Coordinate } from '../../../utils/mapUtils/CoordinateUtils'
import markerOrange from '../../../ressources/static/media/markers/marker_orange.png'
import markerGreen from '../../../ressources/static/media/markers/marker_green.png'
import markerGrey from '../../../ressources/static/media/markers/marker_grey.png'
import { MainButton } from '../components/styled/buttons'
import { Container, Item } from '../components/styled/grid'

class MapPoints extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: null,
            openMenu: false,
            showArrestUnderground: false,
            opacityArrestUnderground: 0,
            showArrestSurface: false,
            opacityArrestSurface: 0,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        if (exploitation && exploitation.idExploitation) {
            this.setExploitation(exploitation)
        } else {
            this.props.fetchExploitation().then(() => {
                this.setExploitation(this.props.exploitation)
            })
        }

        this.props.setTitle([
            {
                title: i18n.myFolder,
                href: '/exploitation',
            },
            {
                title: i18n.map,
                href: '/exploitation/map',
            },
        ])
    }

    setExploitation = (exploitation) => {
        const { installations } = this.props
        if (!installations.length) {
            this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                this.setMap(exploitation)
            })
        } else {
            this.setMap(exploitation)
        }
    }

    goToDetailsPoint = (id) => {
        this.props.push(`/exploitation/point/${id}`)
    }

    getMarker = (stateCode) => {
        switch (stateCode) {
            case 1:
                return { marker: markerGreen, color: 'green' }
            case 2:
                return { marker: markerOrange, color: 'orange' }
            case 3:
                return { marker: markerGrey, color: 'grey' }
            default:
                return { marker: markerOrange, color: 'orange' }
        }
    }

    onSelectLayer = (layer, show) => {
        const { map, opacityArrestSurface, opacityArrestUnderground } = this.state
        const now = new Date().toISOString().slice(0, 10)
        if (layer === 'sup') {
            if (show) {
                map.addLayer(new TileLayer({
                    className: 'Zones_Niveau_Sup',
                    zIndex: 50,
                    opacity: opacityArrestSurface || 1,
                    visible: true,
                    source: new TileWMS({
                        projection: 'EPSG:2154',
                        url: `https://eau.agriculture.sieau.fr:444/WMS-T.php?map=/export/home/rimboval/geomap/mapfiles/propluvia.map&DATE=${now}`,
                        params: { LAYERS: 'Zones_Niveau_Sup', TILED: true },
                        serverType: 'geoserver',
                    }),
                }))
                this.setState({ opacityArrestSurface: opacityArrestSurface || 1 })
            } else {
                map.getLayers().forEach((l) => {
                    if (l && l.className_ === 'Zones_Niveau_Sup') {
                        map.removeLayer(l)
                    }
                })
            }
            this.setState({ showArrestSurface: show })
        } else if (layer === 'sout') {
            if (show) {
                map.addLayer(new TileLayer({
                    className: 'Zones_Niveau_Sou',
                    zIndex: 50,
                    opacity: opacityArrestUnderground || 1,
                    visible: true,
                    source: new TileWMS({
                        projection: 'EPSG:2154',
                        url: `https://eau.agriculture.sieau.fr:444/WMS-T.php?map=/export/home/rimboval/geomap/mapfiles/propluvia.map&DATE=${now}`,
                        params: { LAYERS: 'Zones_Niveau_Sou', TILED: true },
                        serverType: 'geoserver',
                    }),
                }))
                this.setState({ opacityArrestUnderground: opacityArrestUnderground || 1 })
            } else {
                map.getLayers().forEach((l) => {
                    if (l && l.className_ === 'Zones_Niveau_Sou') {
                        map.removeLayer(l)
                    }
                })
            }
            this.setState({ showArrestUnderground: show })
        }
    }

    onChangeLayerOpacity = (layer, opacity) => {
        const { map } = this.state
        if (layer === 'sup') {
            map.getLayers().forEach((l) => {
                if (l.className_ === 'Zones_Niveau_Sup') {
                    l.setOpacity(opacity)
                    this.setState({ opacityArrestSurface: opacity })
                }
            })
        } else if (layer === 'sout') {
            map.getLayers().forEach((l) => {
                if (l.className_ === 'Zones_Niveau_Sou') {
                    l.setOpacity(opacity)
                    this.setState({ opacityArrestUnderground: opacity })
                }
            })
        }
    }

    setMap = (exploitation) => {
        /**
         * Elements that make up the popup.
         */
        const container = document.getElementById('popup')
        const closer = document.getElementById('popup-closer')

        /**
         * Create an overlay to anchor the popup to the map.
         */
        const overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        })

        const layers = [
            new TileLayer({
                zIndex: 0,
                source: new OSM()
            }),
        ]
        const olMap = new Map({
            target: 'ol-map',
            controls: defaultControls().extend([
                new ScaleLine({
                    units: 'metric',
                }),
                new Rotate()
            ]),
            layers,
            overlays: [overlay],
        })

        const { installations, citiesIndex } = this.props
        const wgs84Sites = compact(exploitation.link_samplings.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode]
                const coordinates = getWGS84Coordinate(point)
                const { marker, color } = this.getMarker(linkPoint.stateCode)
                const markersLayer = new VectorLayer({
                    className: 'clickable',
                    zIndex: 100,
                    source: new VectorSource({
                        features: [
                            new Feature({
                                geometry: new Point(fromLonLat(coordinates)),
                                color,
                                name: (
                                    <Grid
                                        key={point.id}
                                        className='clickable'
                                        onClick={this.props.onClickPoint ? () => this.props.onClickPoint(point) : () => this.goToDetailsPoint(point.id)}
                                    >
                                        <Grid><b>{city ? city.name : ''}</b></Grid>
                                        <Grid>{point.location || ''}</Grid>
                                        <Grid>{`${point.code || ''} ${`- ${point.parcel || ''} ${point.section || ''}`}`}</Grid>
                                        <Grid>{point.name || ''}</Grid>
                                    </Grid>
                                ),
                            })
                        ]
                    }),
                    style: new Style({
                        image: new Marker({
                            anchor: [0.5, 1],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'fraction',
                            scale: 0.75,
                            src: marker,
                        })
                    }),
                })
                olMap.addLayer(markersLayer)
                return coordinates
            }
            return null
        }))
        const sumX = wgs84Sites.map((coord) => coord[0]).reduce((a, b) => a + b, 0) || 2.549
        const sumY = wgs84Sites.map((coord) => coord[1]).reduce((a, b) => a + b, 0) || 47.233
        olMap.setView(new View({
            center: fromLonLat([sumX / (wgs84Sites.length || 1), sumY / (wgs84Sites.length || 1)]),
            zoom: wgs84Sites.length ? 10 : 6,
        }))

        /**
         * Add a click handler to hide the popup.
         * @return {boolean}.
         */
        closer.onclick = () => {
            overlay.setPosition(undefined)
            closer.blur()
            return false
        }
        /**
         * Add a click handler to the map to render the popup.
         */
        olMap.on('singleclick', (evt) => {
            const color = olMap.forEachFeatureAtPixel(evt.pixel, (feature) => {
                return feature.get('color')
            })
            const name = olMap.forEachFeatureAtPixel(evt.pixel, (feature) => {
                return feature.get('name')
            })
            if (name) {
                container.style.display = 'block'
                const coordinate = evt.coordinate
                this.setState({ content: name, color })
                overlay.setPosition(coordinate)
            } else {
                container.style.display = 'none'
            }
        })

        this.setState({ map: olMap })
    }

    render() {
        const {
            content,
            color,
            showArrestSurface,
            showArrestUnderground,
            openMenu,
            opacityArrestSurface,
            opacityArrestUnderground,
        } = this.state

        const iconButton = (right) => (
            <Card
                className='clickable'
                style={{
                    position: 'absolute',
                    top: 75,
                    right,
                    backgroundColor: 'white',
                    padding: 6,
                    borderRadius: '4px 0 0 4px',
                    boxShadow: '-5px 0px 5px 3px rgb(0 0 0 / 20%)',
                    zIndex: right ? 1 : 0,
                }}
                onClick={() => this.setState({ openMenu: !openMenu })}
            >
                <Icon style={{ fontSize: '2.5rem' }}>layers</Icon>
            </Card>
        )

        return (
            <>
                <div id='ol-map'>
                    <div
                        id='popup'
                        className='ol-popup'
                        style={{
                            background: `linear-gradient(to right, ${color}, ${color} 1rem, white 1rem, white 100%)`
                        }}
                    >
                        <a href='#' id='popup-closer' className='ol-popup-closer' />
                        <div id='popup-content'>{content}</div>
                    </div>
                </div>
                {iconButton(0)}
                <Slide direction='left' in={openMenu} mountOnEnter unmountOnExit>
                    {iconButton(255)}
                </Slide>
                <Slide direction='left' in={openMenu} mountOnEnter unmountOnExit>
                    <Card style={{ position: 'absolute', top: 75, right: 0, backgroundColor: 'white', width: 256, borderRadius: '0 0 0 4px', boxShadow: '-5px 0px 5px 3px rgb(0 0 0 / 20%)' }}>
                        <Container justifyContent='flex-start' alignItems='center'>
                            <Item xs={2}>
                                <Checkbox
                                    checked={showArrestSurface}
                                    onChange={(e) => this.onSelectLayer('sup', e.target.checked)}
                                />
                            </Item>
                            <Container item xs={10} justifyContent='center' alignItems='space-around'>
                                <Item xs={12}>
                                    Arrêtés sécheresse Eau Sup
                                </Item>
                                <Item xs={11}>
                                    <Slider
                                        disabled={!showArrestSurface}
                                        value={opacityArrestSurface}
                                        min={0}
                                        max={1}
                                        step={0.01}
                                        onChange={(_, v) => this.onChangeLayerOpacity('sup', v)}
                                        aria-labelledby='continuous-slider'
                                    />
                                </Item>
                            </Container>
                        </Container>
                        <Container justifyContent='flex-start' alignItems='center'>
                            <Item xs={2}>
                                <Checkbox
                                    checked={showArrestUnderground}
                                    onChange={(e) => this.onSelectLayer('sout', e.target.checked)}
                                />
                            </Item>
                            <Container item xs={10} justifyContent='center' alignItems='space-around'>
                                <Item xs={12}>
                                    Arrêtés sécheresse Eau Sout
                                </Item>
                                <Item xs={11}>
                                    <Slider
                                        disabled={!showArrestUnderground}
                                        value={opacityArrestUnderground}
                                        min={0}
                                        max={1}
                                        step={0.01}
                                        onChange={(_, v) => this.onChangeLayerOpacity('sout', v)}
                                        aria-labelledby='continuous-slider'
                                    />
                                </Item>
                            </Container>
                        </Container>
                    </Card>
                </Slide>
                <Container justifyContent='center' alignItems='center' style={{ height: '60px' }}>
                    <Item xs={11} md={2}>
                        <MainButton
                            reverse
                            variant='contained'
                            onClick={() => this.props.push('/exploitation')}
                        >
                            <Icon>keyboard_arrow_left</Icon>
                            {i18n.myFolder}
                        </MainButton>
                    </Item>
                </Container>
            </>
        )
    }
}

MapPoints.propTypes = {
    selectType: PropTypes.bool,
    hideIcon: PropTypes.bool,
    classes: PropTypes.instanceOf(PropTypes.object),
    location: PropTypes.instanceOf(PropTypes.object),
    push: PropTypes.func,
    onClickPoint: PropTypes.func,
    setTitle: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
}

MapPoints.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapPoints)
