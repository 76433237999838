import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Container } from '../../../components/styled/grid'
import { InputRow } from '../../../components/styled/inputs'
import { hasValue } from '../../../../../utils/NumberUtil'
import { MainButton } from '../../../components/styled/buttons'
import DtoVariousMateriel from '../../installations/dto/DtoVariousMateriel'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/dialog'

const ModalEditPump = ({
    open,
    toggleModal,
    onSave = () => { },
    onChange = () => { },
    pump = {},
}) => {
    const handleClose = () => {
        toggleModal()
    }

    const onClickButton = () => {
        toggleModal()
        onSave()
    }

    const {
        variousMatTypes,
        codesSandre,
    } = useSelector(store => ({
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const pumpNature = useMemo(() => variousMatTypes.find((t) => t.materielType === pump.materielType), [])
    const pumpCategory = useMemo(() => codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === pump.mobilityCode), [])
    const pumpType = useMemo(() => codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === pump.pumpType), [])

    return (
        <DialogMUI
            maxWidth='md'
            open={open}
        >
            <DialogTitleMUI><b>{i18n.changeInfos}</b></DialogTitleMUI>
            <DialogContentMUI>
                <Container direction='column'>
                    <InputRow
                        item
                        id='name'
                        label={i18n.name}
                        type='text'
                        value={pump.name}
                        onChange={(e) => onChange({ name: e.target.value })}
                        variant='outlined'
                    />
                    <p>{i18n.nature} : {pumpNature ? pumpNature.name : ''}</p>
                    <p>{i18n.categ} : {pumpCategory ? pumpCategory.name : ''}</p>
                    <p>{i18n.type} : {pumpType ? pumpType.name : ''}</p>
                    <p>{i18n.tauxAffectation} : {hasValue(pump.assignmentRate) ? `${pump.assignmentRate}%` : ''}</p>
                    <p>{i18n.sharedEquipment} : {pump.isShared ? i18n.yes : i18n.no}</p>
                    <p>{i18n.forRent} : {pump.rent ? i18n.yes : i18n.no}</p>
                    <p>{i18n.debitMax} : {pump.maxFlow || ''}</p>
                    <p>{i18n.debitFonctionnement} : {pump.operatingFlow || ''}</p>
                    <p>{i18n.comment} : {pump.comment}</p>
                </Container>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Container
                    direction='column'
                    justifyContent='stretch'
                    alignItems='center'
                >
                    <MainButton onClick={onClickButton}>
                        {i18n.validate}
                    </MainButton>
                    <MainButton reverse onClick={handleClose}>
                        {i18n.cancel}
                    </MainButton>
                </Container>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ModalEditPump.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    pump: PropTypes.instanceOf(DtoVariousMateriel),
}

export default ModalEditPump