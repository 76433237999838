import React from 'react'
import PropTypes from 'prop-types'
import { Card, Modal } from '@mui/material'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import DtoExploitation from '../../online/agri/dto/exploitation/DtoExploitation'
import { MainButton } from '../../online/components/styled/buttons'
import { formatPhone } from '../../../utils/StringUtil'
import LittleMap from '../../online/points/LittleMap'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import { Container, Item } from '../../online/components/styled/grid'

function ModalFolder({ open, exploitation, onCancel, onValidate, citiesIndex }) {
    const city = citiesIndex[exploitation.inseeCode] || {}
    const body = (
        <Card style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
            <Container
                className='overflowY'
                style={{
                    marginBottom: 105,
                    padding: '0 16px',
                    height: isLargeWindow() ? '-webkit-fill-available' : 'auto',
                }}>
                <Item xs={12}>
                    <h2 id='modal-title'>
                        {exploitation.codification}
                    </h2>
                </Item>
                <Item xs={12} md={6}>
                    <p><b>{exploitation.name || ''}</b></p>
                    <p>{exploitation.structureType || ''}</p>
                    <p>{`${exploitation.city || ''} [${exploitation.inseeCode || ''}]`}</p>
                    <p>{exploitation.address || ''}</p>
                    <p>{formatPhone(exploitation.phoneTel || '')}</p>
                    <p>{formatPhone(exploitation.mobile || '')}</p>
                    <p>{exploitation.email || ''}</p>
                </Item>
                <Item xs={12} md={6} style={{ height: isLargeWindow() ? 'calc(100% - 75px)' : 200 }}>
                    {city.x && city.y && (
                        <LittleMap point={city} height={isLargeWindow() ? 'auto' : 200} />
                    )}
                </Item>
            </Container>
            <Container
                justifyContent='space-between'
                alignItems='center'
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    bottom: 0,
                    height: 105,
                    padding: '4px 16px',
                }}
            >
                <MainButton reverse variant='contained' onClick={onCancel}>
                    {i18n.cancel}
                </MainButton>
                <MainButton variant='contained' onClick={onValidate}>
                    {i18n.accessFolder}
                </MainButton>
            </Container>
        </Card>
    )

    return (
        <Modal
            open={open}
            className='modal'
            style={{ bottom: 'inherit', zIndex: 1100, overflowY: 'hidden' }}
        >
            {body}
        </Modal>
    )
}

ModalFolder.propTypes = {
    open: PropTypes.bool.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation).isRequired,
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(ModalFolder)