/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, LinearProgress, Icon } from '@mui/material'
import { compact } from 'lodash'
import { MainButton } from '../../../components/styled/buttons'
import { SubTitleCard } from '../../../components/styled/texts'
import ModalAddPoint from '../modals/ModalAddPoint'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import AgriAction from '../../../agri/actions/AgriAction'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import DtoInstallation from '../../../referencials/installations/dto/DtoInstallation'
import { formatMilliers } from '../../../../../utils/StringUtil'
import ToastrAction from '../../../components/toasters/ToastrAction'
import { orderPointsList } from '../../../../../utils/ObjectUtils'

class Etape3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            selectedPoints: [],
            points: [],
            pointsLoaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchExploitation().then(() => {
            const { exploitation } = this.props
            this.setInstallations(exploitation)
        })
    }

    setInstallations = (exploitation) => {
        const { installations, declaration, citiesIndex } = this.props
        const exploitPoints = exploitation.link_samplings.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                return {
                    ...point,
                    cityName: city.name,
                }
            }
            return null
        })
        const orderedPoints = orderPointsList(exploitPoints)
        const selectedPoints = declaration.link_declarationInstallation || []
        this.setState({ points: orderedPoints, selectedPoints, pointsLoaded: true })
    }

    getPointCard = (installation, index) => {
        const { declaration } = this.props
        if (installation) {
            let total = 0
            installation.link_usages.forEach((u) => {
                total = total + (isNaN(parseInt(u.requestedYearVolume)) ? 0 : u.requestedYearVolume)
            })
            return (
                <Grid
                    key={index}
                    container
                    className='clickable'
                    direction='row'
                    justifyContent='flex-start'
                    style={{ padding: '10px 20px', backgroundColor: 'white', borderBottom: '1px solid black' }}
                    onClick={() =>
                        this.props.push({
                            pathname: `/enquete/point/${installation.id}`,
                            declaration,
                        })
                    }
                >
                    <Grid item xs={11} style={{ marginBottom: '5px' }}>
                        <b>{installation.cityName || ''}</b>
                    </Grid>
                    <Grid item xs={1}>
                        <Icon>edit</Icon>
                    </Grid>
                    <Grid item xs={11}>
                        {installation.location || ''}
                    </Grid>
                    <Grid item xs={11} style={{ marginBottom: '5px' }}>
                        <b>{installation.code || ''}</b>{` ${`- ${installation.parcel || ''} ${installation.section || ''}`}${installation.name ? ` - ${installation.name}` : ''}`}
                    </Grid>
                    <Grid item xs={11}>
                        {`${formatMilliers(total) || 0} m3 ${i18n.askeds}`}
                    </Grid>
                    {installation.stateCode === 2 &&
                        <Grid item xs={1}>
                            <Icon>delete</Icon>
                        </Grid>
                    }
                </Grid>
            )
        }
        ToastrAction.warning('Une erreur est survenue lors de la récupération des points de prélèvement. Veuillez contactez l\'administrateur.')
        return ''
    }

    getFormattedPoint = (point) => {
        const { installations, citiesIndex } = this.props
        const installation = installations.find((i) => i.id === point.idInstallation)
        if (installation) {
            const city = citiesIndex[installation.townCode] || {}
            let total = 0
            point.link_usages.forEach((u) => {
                total = total + (isNaN(parseInt(u.requestedYearVolume)) ? 0 : u.requestedYearVolume)
            })
            return {
                ...installation,
                stateCode: point.stateCode,
                link_usages: point.link_usages,
                cityName: city.name,
            }
        }
        ToastrAction.warning('Une erreur est survenue lors de la récupération des points de prélèvement. Veuillez contactez l\'administrateur.')
        return null
    }

    getListPointsUsed = () => {
        const { selectedPoints } = this.state
        const usedPoints = selectedPoints.filter((p) => p.stateCode !== 2 && p.stateCode !== 3)
        const formattedPoints = compact(usedPoints.map((p) => this.getFormattedPoint(p)))
        const orderedPoints = orderPointsList(formattedPoints)
        return (
            <>
                <Grid item xs={12}>
                    <SubTitleCard
                        left='true'
                        margin='0'
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            padding: '0 16px',
                        }}
                    >
                        <b style={{ fontSize: '2rem' }}>{usedPoints.length}</b>
                        &nbsp;
                        <b style={{ marginBottom: '8px' }}>{`${usedPoints.length > 1 ? i18n.usedPoints : i18n.usedPoint}`}</b>
                    </SubTitleCard>
                </Grid>
                {orderedPoints.map((point, index) => this.getPointCard(point, index))}
            </>
        )
    }

    getListPointsToDelete = () => {
        const { selectedPoints } = this.state
        const toDeletePoints = selectedPoints.filter((p) => p.stateCode === 2)
        const formattedPoints = compact(toDeletePoints.map((p) => this.getFormattedPoint(p)))
        const orderedPoints = orderPointsList(formattedPoints)
        return (
            <>
                <Grid item xs={12}>
                    <SubTitleCard
                        left='true'
                        margin='0'
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            padding: '0 16px',
                            marginTop: 20,
                        }}
                    >
                        <b style={{ fontSize: '2rem' }}>{toDeletePoints.length}</b>
                        &nbsp;
                        <b style={{ marginBottom: '8px' }}>{`${toDeletePoints.length > 1 ? i18n.deletePoints : i18n.deletePoint}`}</b>
                    </SubTitleCard>
                </Grid>
                {orderedPoints.map((point, index) => this.getPointCard(point, index))}
            </>
        )
    }

    getListPointsToAdjourn = () => {
        const { selectedPoints } = this.state
        const toDeletePoints = selectedPoints.filter((p) => p.stateCode === 3)
        const formattedPoints = compact(toDeletePoints.map((p) => this.getFormattedPoint(p)))
        const orderedPoints = orderPointsList(formattedPoints)
        return (
            <>
                <Grid item xs={12}>
                    <SubTitleCard
                        left='true'
                        margin='0'
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            padding: '0 16px',
                            marginTop: 20,
                        }}
                    >
                        <b style={{ fontSize: '2rem' }}>{toDeletePoints.length}</b>
                        &nbsp;
                        <b style={{ marginBottom: '8px' }}>{`${toDeletePoints.length > 1 ? 'Points ajournés' : 'Point ajourné'}`}</b>
                    </SubTitleCard>
                </Grid>
                {orderedPoints.map((point, index) => this.getPointCard(point, index))}
            </>
        )
    }

    addPoint = (selectedPoints) => {
        this.setState({ selectedPoints })
        this.props.onChangeDeclaration('link_declarationInstallation', selectedPoints)
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    render() {
        const { openModal, selectedPoints, points, pointsLoaded } = this.state
        const { declaration, exploitation, readMode } = this.props

        return (
            <>
                {pointsLoaded ? (
                    <>
                        <Grid item container alignItems='center' style={{ paddingBottom: '60px' }}>
                            {this.getListPointsUsed()}
                            {this.getListPointsToAdjourn()}
                            {this.getListPointsToDelete()}
                        </Grid>
                        {!readMode && (
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-evenly'
                                alignItems='center'
                                className='borderTop-Grey'
                                style={{
                                    position: 'fixed',
                                    bottom: '60px',
                                    height: '60px',
                                    left: '0',
                                    backgroundColor: 'white',
                                    zIndex: '1000',
                                    padding: '5px 0',
                                }}
                            >
                                <Grid item xs={11} md={9}>
                                    <MainButton variant='contained' onClick={this.toggleModal}>
                                        {i18n.addPointNotUsed}
                                    </MainButton>
                                </Grid>
                            </Grid>
                        )}
                        <ModalAddPoint
                            open={openModal}
                            toggleModal={this.toggleModal}
                            addPoint={this.addPoint}
                            selectedPoints={selectedPoints}
                            installationPoints={points}
                            declaration={declaration}
                            exploitation={exploitation}
                        />
                    </>
                ) : (
                    <Grid container alignItems='center'>
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }
}

Etape3.propTypes = {
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    onChangeDeclaration: PropTypes.func.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    citiesIndex: PropTypes.arrayOf(PropTypes.object),
    fetchExploitation: PropTypes.func,
    readMode: PropTypes.bool,
    push: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        installations: store.InstallationsReducer.installations,
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Etape3)
