import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Accordion, AccordionSummary, AccordionDetails, Icon, Grid } from '@mui/material'

const useStyles = makeStyles(() => ({
    headAccordion: {
        backgroundColor: 'lightgray',
        width: 'auto',
        padding: '8px',
        display: 'flex',
        justifyContent: 'left',
        fontWeight: 'bold',
        border: '1px solid grey',
        height: 30,
    },
    detailsAccordion: {
        padding: '0',
    }
}))

export default function Accordeon({
    title,
    disabled,
    expanded,
    children,
    headStyle = {},
    showIcon = false,
    icon = 'edit',
    onClickIcon = () => { },
    noShadow,
}) {
    const classes = useStyles()

    const onClickAccordeonIcon = (event) => {
        event.stopPropagation()
        onClickIcon()
    }

    return (
        <Accordion
            disabled={disabled}
            defaultExpanded={expanded}
            disableGutters
            style={noShadow ? { boxShadow: 'none' } : {}}
            TransitionProps={{ unmountOnExit: true, timeout: 250 }}
        >
            <AccordionSummary
                expandIcon={<Icon style={{ border: '1px solid', borderRadius: '50%' }}>expand_more</Icon>}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.headAccordion}
                style={headStyle}
            >
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Grid item xs={10}>
                        {title}
                    </Grid>
                    {showIcon && (
                        <Grid item className='clickable' onClick={(event) => onClickAccordeonIcon(event)}>
                            <Icon>{icon}</Icon>
                        </Grid>
                    )}
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsAccordion}>
                {children.length
                    ? children.map((child) => React.cloneElement(child, {}))
                    : React.cloneElement(children, {})}
            </AccordionDetails>
        </Accordion>
    )
}

Accordeon.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    showIcon: PropTypes.bool,
    noShadow: PropTypes.bool,
    icon: PropTypes.string,
    onClickIcon: PropTypes.func,
    children: PropTypes.element,
    headStyle: PropTypes.instanceOf(PropTypes.object),
}