/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, LinearProgress } from '@mui/material'
import { orderBy } from 'lodash'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import { MainButton } from '../../../components/styled/buttons'
import ModalContributor from '../modals/ModalContributor'
import ContactAction from '../../../contact/actions/ContactAction'
import DtoIntervenant from '../../../referencials/dto/DtoIntervenant'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../../../agri/actions/AgriAction'
import DtoIntervenantDeclaration from '../../../agri/dto/enquete/DtoIntervenantDeclaration'
import DtoSandreCode from '../../../referencials/dto/DtoSandreCode'
import ContactCard from '../../../components/cards/ContactCard'
import { getLogin } from '../../../../../utils/UserUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'

class Etape2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            contributors: [],
            contributorsLoaded: false,
            openModal: false,
            contributorToEdit: false,
            contributorTypeEdit: null,
        }
    }

    componentDidMount() {
        this.getContributors()
    }

    getContributors = () => {
        const { declaration, contacts } = this.props
        this.setState({ contributorsLoaded: false })
        this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
            if (!contacts.length) {
                this.props.fetchContacts(declaration).then(() => {
                    this.setContacts(declaration)
                })
            } else {
                this.setContacts(declaration)
            }
        })
    }

    setContacts = (declaration) => {
        const { exploitation, contributors, contacts } = this.props
        const contributorsExploitation = []
        exploitation.link_contributors.forEach((c) => {
            const linkUpdated = declaration.link_contributorTypes.find((cT) => !cT.isTemp && cT.idContributor === c.contactCode && cT.contributorType === c.contributorType)
            if (!linkUpdated) {
                contributorsExploitation.push({
                    idContributor: c.contactCode,
                    isTemp: false,
                    contributorType: c.contributorType,
                    surveyId: declaration.idSurvey,
                    idExploitation: exploitation.idExploitation,
                    updateLogin: getLogin(),
                    mode: 'u',
                })
            }
        })
        declaration.link_contributorTypes.forEach((cT) => {
            if (cT.mode !== 'd') {
                contributorsExploitation.push(cT)
            }
        })
        if (!contributorsExploitation.find((cT) => cT.contributorType === -1 && cT.mode !== 'd')) {
            const preleveur = contributors.find((c) => c.id === exploitation.operatorCode) || {}
            const legalRepresentative = contacts.find((c) => c.id === preleveur.legalRepresentative)
            if (legalRepresentative) {
                contributorsExploitation.push({
                    idContributor: legalRepresentative.id,
                    isTemp: false,
                    contributorType: -1,
                    surveyId: declaration.idSurvey,
                    idExploitation: exploitation.idExploitation,
                    updateLogin: getLogin(),
                    mode: 'u',
                })
            }
        }
        const sortedContributors = orderBy(contributorsExploitation, 'contributorType')
        this.props.setContributors(sortedContributors)
        this.setState({
            contributors: sortedContributors,
            contributorsLoaded: true,
        })
    }

    editContributor = (contributor = false, contributorTypeEdit) => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal, contributorToEdit: contributor, contributorTypeEdit })
    }

    onSave = (contributor, type) => {
        const { declaration, exploitation } = this.props
        const { contributors, contributorTypeEdit } = this.state
        const id = contributor.idContributorTemp || contributor.idContributor || contributor.id
        const newLink = {
            contributorType: type,
            surveyId: declaration.idSurvey,
            idExploitation: exploitation.idExploitation,
            updateLogin: getLogin(),
        }
        const contribFormatted = {
            ...contributor,
            idContributor: id,
            surveyId: declaration.idSurvey,
            contactType: 2,
        }
        // si le contact est déjà lié
        if (id && contributors.find((cT) => cT.idContributor === id && !!cT.isTemp === !!contributor.idContributorTemp && cT.contributorType === type)) {
            // si c'est un ajout du même type de lien
            if (!contributorTypeEdit) {
                ToastrAction.warning('Ce lien existe déjà')
                // sinon, c'est une modification des infos du contact
            } else {
                this.setState({ contributorsLoaded: false })
                this.props.updateDeclarationContributor(declaration.idSurvey, contribFormatted).then(() => {
                    this.getContributors()
                })
                this.editContributor()
            }
            // si c'est une création de nouveau contact
        } else if (!id) {
            this.setState({ contributorsLoaded: false })
            this.props.updateDeclarationContributor(declaration.idSurvey, contribFormatted).then((idReturned) => {
                this.props.onChangeDeclaration('link_contributorTypes', [
                    ...declaration.link_contributorTypes,
                    {
                        ...newLink,
                        idContributor: idReturned,
                        isTemp: true,
                        mode: 'c',
                    },
                ])
                this.props.fetchDeclarationByExploitationId(declaration.idExploitation).then(() => {
                    this.getContributors()
                })
            })
            this.editContributor()
            // si c'est un rajout de lien non existant
        } else {
            this.setState({ contributorsLoaded: false })
            this.props.updateDeclarationContributor(declaration.idSurvey, contribFormatted).then(() => {
                this.props.onChangeDeclaration('link_contributorTypes', [
                    ...(contributorTypeEdit ?
                        declaration.link_contributorTypes.filter((cT) => !(cT.idContributor === id && !!cT.isTemp === !!contributor.idContributorTemp && cT.contributorType === contributorTypeEdit)) :
                        declaration.link_contributorTypes),
                    {
                        ...newLink,
                        idContributor: id,
                        isTemp: !!contributor.idContributorTemp,
                        mode: contributorTypeEdit ? 'u' : 'c',
                    },
                ])
                this.props.fetchDeclarationByExploitationId(declaration.idExploitation).then(() => {
                    this.getContributors()
                })
            })
            this.editContributor()
        }
    }

    onDelete = () => {
        const { declaration, exploitation } = this.props
        const { contributorToEdit, contributorTypeEdit } = this.state
        const id = contributorToEdit.idContributorTemp || contributorToEdit.idContributor || contributorToEdit.id
        this.setState({ contributorsLoaded: false })
        this.props.onChangeDeclaration('link_contributorTypes', [
            ...declaration.link_contributorTypes.filter((cT) => !(cT.idContributor === id && !!cT.isTemp === !!contributorToEdit.idContributorTemp && cT.contributorType === contributorTypeEdit)),
            {
                contributorType: contributorTypeEdit,
                surveyId: declaration.idSurvey,
                idExploitation: exploitation.idExploitation,
                updateLogin: getLogin(),
                idContributor: id,
                isTemp: !!contributorToEdit.idContributorTemp,
                mode: 'd',
            },
        ])
        this.props.fetchDeclarationByExploitationId(declaration.idExploitation).then(() => {
            this.getContributors()
        })
        this.editContributor()
    }

    onCancel = () => {
        this.getContributors()
        this.editContributor()
    }

    formatContact = (contact, updatedContact) => {
        if (updatedContact) {
            return {
                ...contact,
                ...updatedContact,
                address: updatedContact.road,
                additionalAddress: updatedContact.addressComplement,
                desktopTel: updatedContact.fax,
                postalBox: updatedContact.postalCode,
            }
        }
        return contact
    }

    getListContributors = () => {
        const { contributors, editMode } = this.state
        const { codesSandre, declarationContributors, contacts, readMode } = this.props
        const typesContributors = [{ code: -1, name: i18n.legalRepresentative }, ...codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]
        return contributors.map((cT) => {
            const contact = cT.isTemp ? {} : (contacts.find((c) => c.id === cT.idContributor) || {})
            const contactUpdated = declarationContributors.find((c) => (cT.isTemp ? c.idContributorTemp : c.idContributor) === cT.idContributor && c.contactType === 2)
            const formattedContact = this.formatContact(contact, contactUpdated)
            const type = typesContributors.find((t) => t.code === cT.contributorType)
            return (
                <Grid item xs={12} md={4}>
                    <ContactCard
                        contact={formattedContact}
                        title={type ? type.name : i18n.unknown}
                        editMode={editMode}
                        toggleEditMode={() => this.editContributor(formattedContact, cT.contributorType)}
                        onCancel={this.onCancel}
                        hideIcon={readMode}
                    />
                </Grid>
            )
        })
    }

    showLegalRepresentative = () => {
        const { contributors } = this.state
        return !contributors.find((c) => c.mode !== 'd' && c.contributorType === -1)
    }

    render() {
        const { contributorsLoaded, openModal, contributorToEdit, contributorTypeEdit } = this.state
        const { readMode } = this.props

        return (
            <>
                <Grid container alignItems='center' className='padding-top-1' style={{ paddingBottom: 60 }}>
                    {contributorsLoaded ? (
                        this.getListContributors()
                    ) : (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    )}
                </Grid>
                {!readMode && (
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-evenly'
                        alignItems='center'
                        className='borderTop-Grey'
                        style={{
                            position: 'fixed',
                            bottom: '60px',
                            height: '60px',
                            left: '0',
                            backgroundColor: 'white',
                            zIndex: '1000',
                            padding: '5px 0',
                        }}
                    >
                        <Grid item xs={11} md={9}>
                            <MainButton
                                variant='contained'
                                onClick={() => this.editContributor(new DtoIntervenantDeclaration({}))}
                            >
                                {i18n.addContributor}
                            </MainButton>
                        </Grid>
                    </Grid>
                )}
                {openModal && (
                    <ModalContributor
                        showLegalRepresentative={this.showLegalRepresentative()}
                        contributor={contributorToEdit}
                        contributorType={contributorTypeEdit}
                        open={openModal}
                        onSave={this.onSave}
                        onDelete={this.onDelete}
                        onCancel={this.onCancel}
                    />
                )}
            </>
        )
    }
}

Etape2.propTypes = {
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    onChangeDeclaration: PropTypes.func.isRequired,
    fetchContacts: PropTypes.func,
    setContributors: PropTypes.func.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    updateDeclarationContributor: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    operator: PropTypes.instanceOf(DtoIntervenant),
    readMode: PropTypes.bool,
}

const mapStateToProps = (store) => {
    return {
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        declarationContributors: store.AgriReducer.declarationContributors,
        codesSandre: store.ReferencialReducer.codesSandre,
        operator: store.AgriReducer.operator,
        contributors: store.ReferencialReducer.contributors,
    }
}

const mapDispatchToProps = {
    fetchContacts: ContactAction.fetchContacts,
    updateDeclarationContributor: AgriAction.updateDeclarationContributor,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Etape2)
