import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { sortBy } from 'lodash'
import { MainButton } from '../online/components/styled/buttons'
import { InputRow } from '../online/components/styled/inputs'
import { CMS_PATH } from '../../conf/basepath'
import DtoCMSEvent from '../online/cms/dto/DtoCMSEvent'
import { isRunningApp } from '../../utils/LocalStorageUtils'
import ToastrAction from '../online/components/toasters/ToastrAction'
import ModalUpdatePassword from '../online/account/components/ModalUpdatePassword'
import HomeAction from './actions/HomeAction'

const styles = () => ({
    gridTop: {
        backgroundSize: 'cover',
        height: '15%',
    },
    logoTop: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '100%',
        height: '15%',
    },
    img: {
        height: '100%',
        width: 'auto',
    },
})

class Password extends Component {
    constructor(props) {
        super(props)
        this.state = {
            identifiant: '',
            openModal: false,
            images: [],
            backgroundUrl: '',
            hasResetCode: false,
            needLogin: false,
            resetCode: null,
            settingsLoaded: false,
            newMdp: null,
            mdpConfirmation: null,
        }
    }

    componentDidMount() {
        this.props.fetchCMSHome(3).then((cms) => {
            const backgroundUrl = cms && cms.document && cms.document.length ? CMS_PATH + cms.document[0].name : ''
            this.setState({ backgroundUrl: backgroundUrl })
        })
        this.props.fetchCMSLogo(39).then(() => {
            const { cmsLogo } = this.props
            const logoUrl = cmsLogo && cmsLogo.document && cmsLogo.document.length ? CMS_PATH + cmsLogo.document[0].name : ''
            this.setState({ logoUrl: logoUrl })
        })
        this.props.fetchApplicationSettings().then(() => {
            const { applicationSettings } = this.props
            const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
            if (applicationName.value) {
                document.title = applicationName.value
            }
            this.setState({ settingsLoaded: true })
        })
        this.getImages()
    }

    handleChangeValue = (value) => {
        this.setState({ identifiant: value })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSubmit = () => {
        const { identifiant } = this.state
        if (identifiant) {
            this.props.resetPassword(identifiant, false).then((json) => {
                if (!json) {
                    ToastrAction.info(i18n.sendEmail)
                    this.setState({ hasResetCode: true, needLogin: false })
                }
            })
        }
    }

    getImages = () => {
        this.props.getCmsPartenaire().then(() => {
            const { cms } = this.props
            const list = []
            sortBy(cms, 'subtitle').map((c) => {
                if (c.cmsDocument[0]) {
                    list.push({
                        link: c.link,
                        docName: c.cmsDocument[0].name,
                    })
                }
            })
            this.setState({
                images: list,
            })
        })
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    getLogoPartenaires = () => {
        const { classes } = this.props
        const { images } = this.state
        // const size = images.length % 2 === 0 ? 6 : 4
        return images.map((i, index) => {
            const img = (
                <img
                    src={`${CMS_PATH}${i.docName}`}
                    alt=''
                    className={`${classes.img} clickable`}
                    onClick={() => this.openWebSite(i.link)}
                    style={{
                        height: '100%',
                        width: 'auto',
                        maxWidth: '100%',
                    }}
                />
            )
            return (
                <Grid item key={index} style={{ height: '10vw', minHeight: '40%' }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        style={{ height: '100%' }}
                    >
                        {img}
                    </Grid>
                </Grid>
            )
        })
    }

    onValidateResetCode = (login, resetCode) => {
        if (login) {
            if (resetCode) {
                this.props.verifyResetCode(login, resetCode.toString()).then(valide => {
                    if (valide) {
                        this.setState({ openModal: true })
                    }
                })
            } else {
                ToastrAction.error('Veuillez renseigner le code qui vous a été envoyé par mail', true)
            }
        } else {
            ToastrAction.error('Veuillez renseigner votre identifiant', true)
        }
    }

    toggleModalPassword = () => {
        this.setState(({ openModal }) => ({ openModal: !openModal }))
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, resetCode } = this.state
        const { applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    this.props.updatePassword(newMdp, resetCode).then(() => {
                        ToastrAction.success(i18n.passwordUpdatedSuccessfully)
                        this.props.push('/login')
                    })
                } else {
                    ToastrAction.error(regexHelp, true)
                }
            } else {
                ToastrAction.error('Les mots de passe ne sont pas identiques', true)
            }
        }
    }

    getPanel = () => {
        const { hasResetCode, identifiant, resetCode, needLogin } = this.state
        if (hasResetCode) {
            return (
                <Grid
                    container
                    className='overflowY'
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    style={{ height: '40%', padding: '0 20px' }}
                >
                    <Grid item container direction='column' justifyContent='center' alignItems='center'>
                        <p style={{ textAlign: 'center' }}>
                            Saisissez le code à 4 chiffres reçu par email pour finaliser votre réinitialisation de mot de passe.
                        </p>
                    </Grid>
                    {needLogin && (
                        <Grid item xs={12}>
                            <InputRow
                                item='true'
                                id='identifiant'
                                label={i18n.id}
                                type='text'
                                value={identifiant}
                                onChange={(event) => this.handleChangeValue(event.target.value)}
                                variant='outlined'
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <InputRow
                            item='true'
                            id='password'
                            label={i18n.code}
                            type='number'
                            inputProps={{ min: 0, max: 9999 }}
                            value={resetCode}
                            onChange={(event) => this.setState({ resetCode: event.target.value })}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MainButton variant='contained' onClick={() => this.onValidateResetCode(identifiant, resetCode)}>
                            {i18n.validate}
                        </MainButton>
                    </Grid>
                    <Grid item xs={12}>
                        <MainButton
                            id='backToLogin'
                            variant='contained'
                            reverse='true'
                            onClick={() => this.setState({ hasResetCode: false })}
                        >
                            {i18n.back}
                        </MainButton>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid
                container
                className='overflowY'
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                style={{ height: '40%', padding: '0 20px' }}
            >
                <Grid item container direction='column' justifyContent='center' alignItems='center'>
                    <h4 style={{ margin: '0' }}>Mot de passe oublié ?</h4>
                    <p style={{ textAlign: 'center' }}>
                        Pour renouveler votre mot de passe, saisissez votre identifiant
                        ci-dessous.
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <InputRow
                        item='true'
                        id='password'
                        label={i18n.id}
                        type='text'
                        value={identifiant}
                        onChange={(event) => this.handleChangeValue(event.target.value)}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={12}>
                    <MainButton variant='contained' onClick={this.onSubmit}>
                        Envoyer
                    </MainButton>
                </Grid>
                <Grid item xs={12}>
                    <MainButton id='hasCode' variant='contained' onClick={() => this.setState({ hasResetCode: true, needLogin: true })}>
                        J'ai déjà un code
                    </MainButton>
                </Grid>
                <Grid item xs={12}>
                    <MainButton
                        id='cancel'
                        variant='contained'
                        reverse='true'
                        onClick={() => this.props.push('/login')}
                    >
                        {i18n.cancel}
                    </MainButton>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { backgroundUrl, logoUrl, settingsLoaded, openModal } = this.state
        const { classes, applicationSettings } = this.props
        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription') || {}
        return (
            <div
                style={{
                    height: '100%',
                    margin: '-70px 0',
                    backgroundColor: 'white',
                }}
            >
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='flex-end'
                    className={classes.gridTop}
                    style={{ backgroundImage: `url(${backgroundUrl})` }}
                />
                <Grid className={classes.logoTop}>
                    <img src={logoUrl} alt='' width='190px' />
                </Grid>
                {this.getPanel()}
                <Grid
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    style={{ height: '30%', padding: '0 8%', overflowY: 'auto' }}
                >
                    {this.getLogoPartenaires()}
                </Grid>
                {settingsLoaded && openModal && (
                    <ModalUpdatePassword
                        title={i18n.changePassword}
                        label={regexHelp.value}
                        open={openModal}
                        toggleModal={this.toggleModalPassword}
                        handleChangeNewMdp={(e) => this.setState({ newMdp: e.target.value })}
                        handleChangeConfirmation={(e) => this.setState({ mdpConfirmation: e.target.value })}
                        onSavePassword={this.onUpdatePassword}
                        firstLogin
                    />
                )}
            </div>
        )
    }
}

const mapDispatchToProps = {
    resetPassword: HomeAction.resetPassword,
    getCmsPartenaire: HomeAction.getCmsPartenaire,
    fetchCMSHome: HomeAction.fetchCMSHome,
    fetchCMSLogo: HomeAction.fetchCMSLogo,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    verifyResetCode: HomeAction.verifyResetCode,
    updatePassword: HomeAction.updatePassword,
    push,
}

const mapStateToProps = (store) => {
    return {
        cms: store.HomeReducer.cms,
        cmsLogo: store.HomeReducer.cmsLogo,
        applicationSettings: store.HomeReducer.applicationSettings
    }
}

Password.propTypes = {
    updatePassword: PropTypes.func,
    resetPassword: PropTypes.func,
    getCmsPartenaire: PropTypes.func,
    fetchCMSHome: PropTypes.func,
    fetchCMSLogo: PropTypes.func,
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
    fetchApplicationSettings: PropTypes.func,
    verifyResetCode: PropTypes.func,
    push: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.object),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Password))
