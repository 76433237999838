/* eslint-disable indent */
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction='up' ref={ref} {...props} />
})

export const DialogMUI = styled((other) => (
    <Dialog
        fullWidth
        maxWidth='lg'
        fullScreen={useMediaQuery(useTheme().breakpoints.down('md'))}
        TransitionComponent={Transition}
        PaperProps={!useMediaQuery(useTheme().breakpoints.down('md')) ? {
            style: {
                minHeight: '90%',
                maxHeight: '90%',
            }
        } : {}}
        {...other}
    />
))({})

export const DialogTitleMUI = styled((other) => (
    <DialogTitle
        {...other}
    />))(() => ({
        borderBottom: 'solid 1px grey',
        fontSize: '20px',
        lineHeight: '20px',
        backgroundColor: 'lightgray',
    }))

export const DialogContentMUI = styled((other) => (
    <DialogContent
        {...other}
    />))({
        backgroundColor: 'white',
        padding: '8px 16px !important',
    })

export const DialogActionsMUI = styled((other) => (
    <DialogActions
        {...other}
    />))({
        borderTop: 'solid 1px grey',
    })