/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon, LinearProgress } from '@mui/material'
import { maxBy } from 'lodash'
import { MainButton } from '../../../components/styled/buttons'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import AgriAction from '../../../agri/actions/AgriAction'
import { formatMilliers } from '../../../../../utils/StringUtil'
import ModalSynthese from '../modals/ModalSynthese'
import ModalNothingDeclared from '../modals/ModalNothingDeclared'
import { getDate } from '../../../../../utils/DateUtil'
import { mainOrange } from '../../../components/styled/theme'
import DtoTankDeclaration from '../../../agri/dto/enquete/DtoTankDeclaration'
import DtoAgriTanksType from '../../../agri/dto/DtoAgriTanksType'
import { getSetting, getSettingInt } from '../../../../../utils/FormUtils'
import DtoEnquete from '../../../agri/dto/enquete/DtoEnquete'

class Etape5 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            openModalNothingDeclared: false,
            usedPoints: [],
            totalVolumeAsked: 0,
            nbUsagesReal: 0,
            nbUsagesPrevi: 0,
            totalTanks: 0,
        }
    }

    componentDidMount() {
        const { declaration, agriTanksTypes, applicationSettings } = this.props
        const minVolumeEligibilityTank = getSettingInt(applicationSettings, 'minVolumeEligibilityTank')
        let totalVolumeAsked = 0
        let nbUsagesReal = 0
        let nbUsagesPrevi = 0
        let totalTanks = 0
        const idsTanks = []
        declaration.link_declarationInstallation.forEach((point) => {
            nbUsagesReal = nbUsagesReal + (point.link_usagesCurrent && point.link_usagesCurrent.length || 0)
            nbUsagesPrevi = nbUsagesPrevi + (point.link_usages && point.link_usages.length || 0)
            const pointExpoit = (declaration.link_exploitationInstallation || []).find((p) => p.idInstallation === point.idInstallation) || {}
            if (point.stateCode === 1) {
                const totalTanksPoint = (pointExpoit.link_sampleTanks || []).map((retenue) => {
                    if (!idsTanks.includes(retenue.idTank)) {
                        const updatedRetenue = point.link_sampleTanks.find((o) => o.idTank === retenue.idTank)
                        const retenueDecla = updatedRetenue ? {
                            ...retenue,
                            ...new DtoTankDeclaration(updatedRetenue),
                        } : retenue
                        const type = agriTanksTypes.find((t) => t.id === retenueDecla.tankType) || {}
                        idsTanks.push(retenue.idTank)
                        return type.eligibility && retenueDecla.capacity >= minVolumeEligibilityTank ? (retenueDecla.capacity / 100 * (type.percent || 100)) : 0
                    }
                    return 0
                }).reduce((acc, v) => acc + v, 0)
                totalTanks = totalTanks + totalTanksPoint
            }
            point.link_usages.forEach((usage) => {
                totalVolumeAsked = isNaN(parseInt(usage.requestedYearVolume))
                    ? totalVolumeAsked + 0
                    : totalVolumeAsked + usage.requestedYearVolume
            })
        })
        const usedPoints = declaration.link_declarationInstallation.filter((p) => p.stateCode === 1)
        if (usedPoints.length === 0 && totalVolumeAsked === 0) {
            this.setState({ openModalNothingDeclared: true })
        }
        this.setState({ usedPoints, totalVolumeAsked, dataLoaded: true, nbUsagesReal, nbUsagesPrevi, totalTanks })
    }

    toggleModal = () => {
        this.setState(({ openModal }) => ({ openModal: !openModal }))
    }

    validateDeclaration = () => {
        const { declaration, readMode } = this.props
        if (readMode) {
            this.props.push('/home')
        } else {
            this.props.updateDeclaration({ ...declaration, lastStep: 5, statusCode: 3 }).then(() => {
                this.toggleModal()
            })
        }
    }

    render() {
        const { openModal, openModalNothingDeclared, usedPoints, totalVolumeAsked, dataLoaded, nbUsagesReal, nbUsagesPrevi, totalTanks } = this.state
        const { declaration, survey, readMode } = this.props
        const lastIndex = maxBy(declaration.link_chronicles, 'measureDate')
        const date = survey.endDate ? new Date(survey.endDate).toLocaleString() : ''

        if (!dataLoaded) {
            return <LinearProgress />
        }
        return (
            <>
                <Grid container direction='column' alignItems='center' style={{ padding: '15px' }}>
                    {(getSetting(survey.link_params, 'step3') || '1') === '1' && (getSetting(survey.link_params, 'consos') || '1') !== '0' && (
                        <Grid item container alignItems='center'>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {lastIndex ? '' : <Icon style={{ marginRight: 8, color: mainOrange }}>warning</Icon>}
                                <b style={{ fontSize: '2em' }}>{lastIndex ? getDate(lastIndex.measureDate) : i18n.unknown}</b>
                            </Grid>
                            <Grid item xs={6} className='padding-1'>
                                <span>{i18n.lastIndex}</span>
                            </Grid>
                        </Grid>
                    )}
                    {(getSetting(survey.link_params, 'step3') || '1') !== '0' && (
                        <Grid item container alignItems='center'>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {usedPoints.length ? '' : <Icon style={{ marginRight: 8, color: mainOrange }}>warning</Icon>}
                                <b style={{ fontSize: '2em' }}>{usedPoints.length}</b>
                            </Grid>
                            <Grid item xs={6} className='padding-1'>
                                <p>
                                    {usedPoints.length > 1
                                        ? `${i18n.pointsPrelevement} ${i18n.useds}`
                                        : `${i18n.pointPrelevement} ${i18n.used}`
                                    }
                                </p>
                            </Grid>
                        </Grid>
                    )}
                    {(getSetting(survey.link_params, 'step3') || '1') === '1' && (getSetting(survey.link_params, 'usagesReal') || '1') !== '0' && (
                        <Grid item container alignItems='center'>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {nbUsagesReal ? '' : <Icon style={{ marginRight: 8, color: mainOrange }}>warning</Icon>}
                                <b style={{ fontSize: '2em' }}>{nbUsagesReal}</b>
                            </Grid>
                            <Grid item xs={6} className='padding-1'>
                                <span>{i18n.currentUsages}</span>
                            </Grid>
                        </Grid>
                    )}
                    {(getSetting(survey.link_params, 'step3') || '1') === '1' && (getSetting(survey.link_params, 'usagesPrevi') || '1') !== '0' && (
                        <Grid item container alignItems='center'>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {nbUsagesPrevi ? '' : <Icon style={{ marginRight: 8, color: mainOrange }}>warning</Icon>}
                                <b style={{ fontSize: '2em' }}>{nbUsagesPrevi}</b>
                            </Grid>
                            <Grid item xs={6} className='padding-1'>
                                <span>{i18n.prevUsages}</span>
                            </Grid>
                        </Grid>
                    )}
                    {(getSetting(survey.link_params, 'step3') || '1') === '1' && (getSetting(survey.link_params, 'usagesPrevi') || '1') !== '0' && (
                        <Grid item container alignItems='center'>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {totalVolumeAsked ? '' : <Icon style={{ marginRight: 8, color: mainOrange }}>warning</Icon>}
                                <b style={{ fontSize: '2em' }}>{formatMilliers(totalVolumeAsked) || 0}</b>
                            </Grid>
                            <Grid item xs={6} className='padding-1'>
                                <span>{` m3 ${i18n.askeds}`}</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <b style={{ fontSize: '2em' }}>{formatMilliers(totalTanks) || 0}</b>
                            </Grid>
                            <Grid item xs={6} className='padding-1'>
                                <span>{' m3 de substitution'}</span>
                            </Grid>
                        </Grid>
                    )}
                    {(getSetting(survey.link_params, 'step3') || '1') !== '0' && (
                        <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
                            <MainButton variant='contained' onClick={() => this.props.onGetBack(3)} disabled={readMode}>
                                Modifier
                            </MainButton>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <p style={{ textAlign: 'center' }}>
                            Cliquez ci-dessous pour enregistrer votre déclaration. Elle reste modifiable jusqu’au {date}.
                        </p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        bottom: '60px',
                        height: '60px',
                        left: '0',
                        backgroundColor: 'white',
                        borderTop: 'solid 1px grey',
                        zIndex: '1000',
                        padding: '5px 0',
                    }}
                >
                    <Grid item xs={11} md={9}>
                        <MainButton variant='contained' onClick={this.validateDeclaration}>
                            {readMode ? i18n.backHome : i18n.validDeclaration}
                        </MainButton>
                    </Grid>
                </Grid>
                {openModal && (
                    <ModalSynthese
                        open={openModal}
                        toggleModal={() => this.setState({ openModal: false })}
                        push={() => this.props.push('/home')}
                    />
                )}
                {openModalNothingDeclared && (
                    <ModalNothingDeclared
                        open={openModalNothingDeclared}
                        toggleModal={() => this.setState({ openModalNothingDeclared: false })}
                        push={() => this.props.onGetBack(3)}
                    />
                )}
            </>
        )
    }
}

Etape5.propTypes = {
    toggleEdit: PropTypes.func,
    updateDeclaration: PropTypes.func,
    push: PropTypes.func,
    onGetBack: PropTypes.func,
    readMode: PropTypes.bool,
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    classes: PropTypes.instanceOf(PropTypes.object),
    survey: PropTypes.instanceOf(DtoEnquete),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        survey: store.AgriReducer.survey,
        agriTanksTypes: store.ReferencialReducer.agriTanksTypes,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    updateDeclaration: AgriAction.updateDeclaration,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Etape5)
