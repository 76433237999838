import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Grid, Icon, Modal } from '@mui/material'
import i18n from 'simple-react-i18n'
import { MainButton } from '../../../components/styled/buttons'

function getModalStyle() {
    return {
        top: '0%',
        left: '0%',
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1),
        margin: '10px auto',
        bottom: 'inherit',
        height: 'auto',
        width: '90vw',
        overflowY: 'auto',
        border: '5px solid red',
    },
}))

export default function ModalNothingDeclared({ open, toggleModal, push }) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    const getBack = () => {
        push()
        toggleModal()
    }

    const handleClose = () => {
        toggleModal()
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id='modal-title'>
                <Grid container>
                    <Grid item style={{ alignSelf: 'center', marginRight: 8 }}><Icon style={{ fontSize: '2.5rem' }}>warning</Icon></Grid>
                    <Grid item>Déclaration vide</Grid>
                </Grid>
            </h2>
            <p id='modal-text'>
                Vous n'avez déclaré aucun point de prélèvement utilisé et aucun usage. Etes vous sûr de vouloir continuer ?
            </p>
            <p id='modal-text'>
                Pour ajouter des usages et des volumes, cliquez sur chaque point de prélèvement utilisé puis ajouter les usages réels, les usages prévisionnels ainsi que les volumes demandés.
            </p>
            <p id='modal-description' className='italic'>Pour tout renseignement, veuillez contacter votre OUGC.</p>
            <MainButton variant='contained' onClick={getBack}>
                Revenir en arrière
            </MainButton>
            <MainButton reverse variant='contained' onClick={handleClose}>
                {i18n.close}
            </MainButton>
        </div>
    )

    return (
        <Modal
            open={open}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            {body}
        </Modal>
    )
}

ModalNothingDeclared.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}
