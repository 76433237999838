'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { isRunningApp } from '../../../../utils/LocalStorageUtils'
import { CONTENT_PATH } from '../../../../conf/basepath'
import ToastrAction from '../../components/toasters/ToastrAction'
import {
    RECEIVE_EXPLOITATION,
    RECEIVE_EXPLOITATIONS_DATA,
    RECEIVE_EXPLOITATION_VOLUMES,
    RECEIVE_EXPLOITATIONS,
    RECEIVE_DECLARATION,
    RECEIVE_LAST_DECLARATION,
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION,
    RECEIVE_DECLARATIONS,
    RECEIVE_OPERATOR,
    RECEIVE_MODES_IRRIGATIONS,
    RECEIVE_DECLARATION_CONTRIBUTORS,
    RECEIVE_SURVEYS,
    RECEIVE_WATERTURNS_RESTRICTIONS,
    RECEIVE_WATERTURNS_EXPLOITATION,
    RECEIVE_WATERTURNS,
    RECEIVE_SURVEY,
    RECEIVE_WATERTURNS_SLOTS,
} from '../constants/AgriConstants'
import LogAction from '../../../../utils/log/actions/LogAction'
import { RESET_AGRI } from '../../../offline/constants/HomeConstants'

const AgriAction = {
    receiveExploitation(exploitation) {
        return { type: RECEIVE_EXPLOITATION, exploitation }
    },

    fetchExploitation() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.exploitations(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json = {}) => {
                    dispatch(AgriAction.receiveExploitation(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.exploitation} : ${err}`)
                })
        }
    },

    receiveExploitations(exploitations) {
        return { type: RECEIVE_EXPLOITATIONS, exploitations }
    },

    promiseExploitations() {
        return fetch(ApplicationConf.agri.exploitations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchExploitations() {
        return (dispatch) => {
            return AgriAction.promiseExploitations()
                .then((json = []) => {
                    dispatch(AgriAction.receiveExploitations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.exploitations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.exploitations)
                })
        }
    },

    receiveExploitationsExportFullData(exploitationsExportFullData) {
        return { type: RECEIVE_EXPLOITATIONS_DATA, exploitationsExportFullData }
    },

    promiseExploitationsExportFullData() {
        return fetch(ApplicationConf.agri.exploitationsExportFull(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchExploitationsExportFullData: () => dispatch => AgriAction.promiseExploitationsExportFullData()
        .then(json => dispatch(AgriAction.receiveExploitationsExportFullData(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.exploitations} : ${err}`))
            ToastrAction.error(i18n.fetchError + i18n.exploitations)
        }),

    promiseExploitationsAvailable() {
        return fetch(ApplicationConf.agri.exploitationsAvailable(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchExploitationsAvailable: () => dispatch => AgriAction.promiseExploitationsAvailable()
        .then(json => dispatch(AgriAction.receiveExploitationsExportFullData(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.exploitations} : ${err}`))
            ToastrAction.error(i18n.fetchError + i18n.exploitations)
        }),

    createExploitation(exploitation) {
        return () => {
            return fetch(ApplicationConf.agri.exploitations(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(exploitation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch((err) => {
                    LogAction.logError(`${i18n.createError + i18n.exploitation} : ${err}`)
                    ToastrAction.error(i18n.createError + i18n.exploitation)
                })
        }
    },

    updateExploitation(exploitation) {
        return () => {
            return fetch(ApplicationConf.agri.exploitation(exploitation.idExploitation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(exploitation),
            })
                .then(checkAuth)
                .then(checkError)
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.exploitation} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.exploitation)
                })
        }
    },

    receiveExploitationVolumes(exploitationVolumes) {
        return { type: RECEIVE_EXPLOITATION_VOLUMES, exploitationVolumes }
    },

    promiseExploitationVolumes(idExploitation) {
        return fetch(ApplicationConf.agri.exploitationVolumes(idExploitation), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchExploitationVolumes(idExploitation) {
        return (dispatch) => {
            return AgriAction.promiseExploitationVolumes(idExploitation)
                .then((json) => {
                    dispatch(AgriAction.receiveExploitationVolumes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.exploitation} : ${err}`)
                })
        }
    },

    receiveDeclaration(declaration) {
        return { type: RECEIVE_DECLARATION, declaration }
    },

    fetchDeclarationByExploitationId(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declarationExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json = {}) => {
                    if (json && json.idDeclaration) {
                        dispatch(AgriAction.receiveDeclaration(json))
                        return json
                    }
                    return false
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.declaration} : ${err}`)
                })
        }
    },

    receiveWaterTurnsRestrictions(restrictions) {
        return { type: RECEIVE_WATERTURNS_RESTRICTIONS, restrictions }
    },

    fetchWaterTurnsRestrictions() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.waterTurnsRestrictions(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(AgriAction.receiveWaterTurnsRestrictions(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.waterTurns} : ${err}`)
                })
        }
    },

    receiveWaterTurnsSlots(waterTurnsSlots) {
        return { type: RECEIVE_WATERTURNS_SLOTS, waterTurnsSlots }
    },

    fetchWaterTurnsSlots() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.waterTurnsSlots(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(AgriAction.receiveWaterTurnsSlots(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.waterTurns} : ${err}`)
                })
        }
    },

    receiveWaterTurnsExploitation(waterTurns) {
        return { type: RECEIVE_WATERTURNS_EXPLOITATION, waterTurns }
    },

    fetchWaterTurnsExploitation(idExploitation) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.waterTurns(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ idExploitations: [idExploitation] })
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(AgriAction.receiveWaterTurnsExploitation(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.waterTurns} : ${err}`)
                })
        }
    },

    receiveAllWaterTurns(waterTurns) {
        return { type: RECEIVE_WATERTURNS, waterTurns }
    },

    fetchAllWaterTurns() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.waterTurns(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({}),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(AgriAction.receiveAllWaterTurns(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.waterTurns} : ${err}`)
                })
        }
    },

    resetAgriStore() {
        return { type: RESET_AGRI }
    },

    changeExploitationAdministrator(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.changeAdministrator(id), {
                method: 'PUT',
                headers: getAuthorization(),
            })
                .then(() => dispatch(AgriAction.resetAgriStore()))
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
                    ToastrAction.error(i18n.updateError)
                })
        }
    },

    receiveDeclarations(declarations) {
        return { type: RECEIVE_DECLARATIONS, declarations }
    },

    promiseDeclarations() {
        return fetch(ApplicationConf.agri.declarations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchDeclarations() {
        return (dispatch) => {
            return AgriAction.promiseDeclarations()
                .then((json = []) => {
                    dispatch(AgriAction.receiveDeclarations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.declarations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.declarations)
                })
        }
    },

    createDeclaration() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declarations(), {
                method: 'POST',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(AgriAction.receiveDeclaration(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.createError + i18n.declaration} : ${err}`)
                    ToastrAction.error(i18n.createError + i18n.declaration)
                })
        }
    },

    updateDeclaration(declaration) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declaration(declaration.idDeclaration), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(declaration),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    dispatch(AgriAction.fetchDeclarationByExploitationId(declaration.idExploitation))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.declaration} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.declaration)
                })
        }
    },

    receiveOperator(operator) {
        return { type: RECEIVE_OPERATOR, operator }
    },

    fetchOperator() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.operator(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(AgriAction.receiveOperator(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.operator} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.operator)
                })
        }
    },

    receiveModesIrrigations(modesIrrigations) {
        return { type: RECEIVE_MODES_IRRIGATIONS, modesIrrigations }
    },

    promiseModesIrrigations() {
        return fetch(ApplicationConf.agri.modesIrrigations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchModesIrrigations() {
        return (dispatch) => {
            return AgriAction.promiseModesIrrigations()
                .then((json = []) => {
                    // use as modes irrigations in front
                    dispatch(AgriAction.receiveModesIrrigations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.modesIrrigation} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.modesIrrigation)
                })
        }
    },

    calculVolumeUsage(usage) {
        return () => {
            return fetch(ApplicationConf.agri.calculVolume(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(usage),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    return json || 0
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.errorCalculVolume} : ${err}`)
                    ToastrAction.error(i18n.errorCalculVolume)
                })
        }
    },

    receiveDeclarationContributors(declarationContributors) {
        return { type: RECEIVE_DECLARATION_CONTRIBUTORS, declarationContributors }
    },

    promiseDeclarationContributors(idEnquete) {
        return fetch(ApplicationConf.agri.getdeclarationContributors(idEnquete), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchDeclarationContributors(idEnquete) {
        return (dispatch) => {
            return AgriAction.promiseDeclarationContributors(idEnquete)
                .then((json = []) => {
                    dispatch(AgriAction.receiveDeclarationContributors(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contributors)
                })
        }
    },

    updateDeclarationContributor(idEnquete, contributor) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declarationContributor(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contributor),
            })
                .then(checkAuth)
                .then(checkError)
                .then((id) => {
                    dispatch(AgriAction.fetchDeclarationContributors(idEnquete))
                    return id
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.contributor} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.contributor)
                })
        }
    },

    receiveSurveys(surveys) {
        return { type: RECEIVE_SURVEYS, surveys }
    },

    fetchSurveys() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.surveys(), {
                method: 'GET',
                headers: getAuthorization()
            })
                .then(getJson)
                .then(json => {
                    dispatch(AgriAction.receiveSurveys(json))
                })
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.enquete} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.enquete)
                })
        }
    },

    receiveSurvey: survey => ({ type: RECEIVE_SURVEY, survey }),

    promiseSurvey: id => fetch(ApplicationConf.agri.survey(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(getJson),

    fetchSurvey: id => dispatch => AgriAction.promiseSurvey(id)
        .then(json => dispatch(AgriAction.receiveSurvey(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.enquete} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.enquete))
        }),

    exportDeclarationFull(id) {
        ToastrAction.info(i18n.loadingDocument)
        return () => {
            return fetch(ApplicationConf.agri.exportDeclarationFull(id), {
                method: 'GET',
                headers: getAuthorization()
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    const pathPDF = `${CONTENT_PATH}${json.targetPath}`
                    if (isRunningApp()) {
                        window.open(pathPDF, '_system')
                    } else {
                        fetch(pathPDF).then((resp) => {
                            return resp.blob().then((b) => {
                                let a = document.createElement('a')
                                a.href = URL.createObjectURL(b)
                                const fileName = json.targetPath.split('/')[1]
                                a.setAttribute('download', fileName)
                                a.click()
                            })
                        })
                    }
                })
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.declaration} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.declaration)
                })
        }
    },

    receiveLastDeclaration(declaration) {
        return { type: RECEIVE_LAST_DECLARATION, declaration }
    },

    fetchLastDeclarationByExploitationId(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.lastDeclarationExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.declaration} : ${err}`)
                })
                .then((json = {}) => {
                    dispatch(AgriAction.receiveLastDeclaration(json))
                })
        }
    },

    receiveDeclarationsExploitation(declarations) {
        return { type: RECEIVE_DECLARATIONS_STATS_EXPLOITATION, declarations }
    },

    fetchDeclarationsByExploitationId(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declarationsExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.declarations} : ${err}`)
                })
                .then((json = []) => {
                    dispatch(AgriAction.receiveDeclarationsExploitation(json))
                })
        }
    },

    calculPointVolumeReal(idPoint, idExploitation, startDate, endDate) {
        return () => {
            return fetch(ApplicationConf.agri.calculPointVolumeReal(idPoint, idExploitation, startDate, endDate), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    return json
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.errorCalculVolume} : ${err}`)
                    ToastrAction.error(i18n.errorCalculVolume)
                })
        }
    },

    getNotifEdition(idExploitation, year) {
        ToastrAction.info(i18n.loadingDocument)
        return () => {
            return fetch(ApplicationConf.agri.editionNotification(idExploitation, year), {
                method: 'GET',
                headers: getAuthorization()
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    const pathPDF = `${CONTENT_PATH}${json.targetPath}`
                    if (isRunningApp()) {
                        window.open(pathPDF, '_system')
                    } else {
                        fetch(pathPDF).then((resp) => {
                            return resp.blob().then((b) => {
                                let a = document.createElement('a')
                                a.href = URL.createObjectURL(b)
                                const fileName = json.targetPath.split('/')[1]
                                a.setAttribute('download', fileName)
                                a.click()
                            })
                        })
                    }
                })
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.notification} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.notification)
                })
        }
    },
}

export default AgriAction
