import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Icon, Grid, Modal, Accordion, AccordionSummary, AccordionDetails, ClickAwayListener } from '@mui/material'
import { withStyles } from '@mui/styles'
import { MainButton } from '../styled/buttons'
import { HelpTooltip, SubTitleCard } from '../styled/texts'
import { mainOrange } from '../styled/theme'

const styles = (theme) => ({
    root: {
        borderRadius: 15,
        border: '1px solid grey',
        // boxShadow: '0px 0px 5px 5px rgb(0 0 0 / 30%)',
    },
    title: {
        fontWeight: 'bold',
        color: 'black',
    },
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
    },
    headAccordion: {
        backgroundColor: 'lightgray',
        width: 'auto',
        padding: '8px',
        display: 'flex',
        justifyContent: 'left',
        fontWeight: 'bold',
        border: '1px solid grey',
        height: 30,
    },
    detailsAccordion: {
        padding: '0',
    }
})

class EditableCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openTooltip: false,
        }
    }

    onClickAccordeonIcon = (event) => {
        event.stopPropagation()
        this.props.toggleEditMode()
    }

    toggleTooltip = () => {
        const { openTooltip } = this.state
        this.setState({ openTooltip: !openTooltip })
    }

    render() {
        const { title, editTitle, editMode, hideIcon, children, accordeon, expanded, style, colorTitle, styleTitle,
            otherIcon, icon, styleContainer, noInnerPadding, className, classes, tooltipIcon, tooltipContent } = this.props
        const { openTooltip } = this.state
        if (editMode) {
            return (
                <Modal
                    open
                    className={classes.paper}
                    style={{
                        bottom: 'inherit',
                        zIndex: '1000',
                    }}
                >
                    <Card className='overflowY' style={{ width: '100%', height: '100%' }}>
                        <Grid
                            container
                            className='overflowY'
                            direction='row'
                            alignItems='flex-start'
                            style={{
                                marginBottom: '105px',
                                height: '-webkit-fill-available',
                                padding: '0',
                                display: 'block',
                            }}
                        >
                            <Grid item xs={12}>
                                <SubTitleCard left bold margin='0' padding='12px 8px'>
                                    {editTitle || title}
                                </SubTitleCard>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '10px 16px' }}>
                                {children.length
                                    ? children.map((child) => React.cloneElement(child, {}))
                                    : React.cloneElement(children, {})}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            style={{
                                position: 'absolute',
                                backgroundColor: 'white',
                                bottom: '0',
                                height: '105px',
                                padding: '4px 16px',
                            }}
                        >
                            <MainButton variant='contained' onClick={this.props.onSave}>
                                {i18n.validate}
                            </MainButton>
                            <MainButton
                                variant='contained'
                                reverse='true'
                                onClick={this.props.onCancel}
                            >
                                {i18n.cancel}
                            </MainButton>
                        </Grid>
                    </Card>
                </Modal>
            )
        }
        return (
            accordeon ? (
                <Accordion className={className} defaultExpanded={expanded} disableGutters TransitionProps={{ unmountOnExit: true, timeout: 250 }}>
                    <AccordionSummary
                        expandIcon={<Icon style={{ border: '1px solid', borderRadius: '50%' }}>expand_more</Icon>}
                        aria-label='Expand'
                        aria-controls='additional-actions1-content'
                        id='additional-actions1-header'
                        className={classes.headAccordion}
                    >
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            {title}
                            {!hideIcon && (
                                <Grid item className='clickable' onClick={(event) => this.onClickAccordeonIcon(event)}>
                                    {!editMode && <Icon>{icon || 'edit'}</Icon>}
                                </Grid>
                            )}
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsAccordion}>
                        <CardContent style={{ padding: '4px 16px' }}>
                            {children.length
                                ? children.map((child) => React.cloneElement(child, {}))
                                : React.cloneElement(children, {})}
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            ) : (
                <div className={className} style={{ padding: '5 10', ...styleContainer }}>
                    <Card className={classes.root} style={style}>
                        {title && (
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                style={{
                                    backgroundColor: `${colorTitle || 'white'}`,
                                    borderBottom: '1px solid grey',
                                    padding: '8px',
                                }}
                            >

                                <Grid item>
                                    <Typography className={classes.title} color='textSecondary' style={styleTitle}>
                                        <span style={{ height: '100%', width: 4, backgroundColor: mainOrange, marginRight: 4 }}>&nbsp;</span>
                                        {title}
                                    </Typography>
                                </Grid>
                                {!hideIcon && (
                                    <Grid item className='clickable' onClick={this.props.toggleEditMode}>
                                        {!editMode && <Icon>{icon || 'edit'}</Icon>}
                                    </Grid>
                                )}
                                {otherIcon && (
                                    <Grid item className='clickable' onClick={this.props.onClickOtherIcon}>
                                        {<Icon>{otherIcon}</Icon>}
                                    </Grid>
                                )}
                                {tooltipIcon && (
                                    <Grid item className='clickable' onClick={this.toggleTooltip}>
                                        <ClickAwayListener onClickAway={() => this.setState({ openTooltip: false })}>
                                            <HelpTooltip
                                                title={tooltipContent}
                                                open={openTooltip}
                                                arrow
                                                disableHoverListener
                                                placement='top'
                                            >
                                                {<Icon>{tooltipIcon}</Icon>}
                                            </HelpTooltip>
                                        </ClickAwayListener>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        <CardContent style={{ padding: noInnerPadding ? 0 : '4px 16px' }}>
                            {children.length
                                ? children.map((child) => React.cloneElement(child, {}))
                                : React.cloneElement(children, {})}
                        </CardContent>
                    </Card>
                </div>
            )
        )
    }
}

EditableCard.propTypes = {
    title: PropTypes.string.isRequired,
    editTitle: PropTypes.string,
    editMode: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    otherIcon: PropTypes.string,
    icon: PropTypes.string,
    onClickOtherIcon: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    hideIcon: PropTypes.bool,
    noInnerPadding: PropTypes.bool,
    accordeon: PropTypes.bool,
    expanded: PropTypes.bool,
    children: PropTypes.element,
    colorTitle: PropTypes.string,
    className: PropTypes.string,
    tooltipIcon: PropTypes.string,
    tooltipContent: PropTypes.string,
    classes: PropTypes.instanceOf(PropTypes.object),
    style: PropTypes.instanceOf(PropTypes.object),
    styleContainer: PropTypes.instanceOf(PropTypes.object),
    styleTitle: PropTypes.instanceOf(PropTypes.object),
}

EditableCard.defaultProps = {
    editable: false,
    accordeon: false,
}

export default withStyles(styles)(EditableCard)
