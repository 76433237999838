/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { MainButton, RedButton } from '../components/styled/buttons'
import DtoMatEventsType from '../referencials/materiels/dto/DtoMatEventsType'
import { InputRow } from '../components/styled/inputs'
import SimpleSelect from '../components/forms/SimpleSelect'
import ToastrAction from '../components/toasters/ToastrAction'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
    },
})

const emptyEvent = {
    materielType: 'divers',
    idMateriel: null,
    idType: null,
    eventDate: null,
    comment: null,
}

class ModalMatEvent extends Component {
    constructor(props) {
        super(props)
        const idMateriel = props.materiels.length ? props.materiels[0].id : null
        this.state = {
            event: props.event || { ...emptyEvent, idMateriel, idType: 1 },
        }
    }

    handleChange = (obj) => {
        this.setState(({ event }) => ({ event: { ...event, ...obj } }))
    }

    onCancel = () => {
        this.setState({ event: { ...emptyEvent } })
        this.props.onCancel()
    }

    onDelete = () => {
        const { event } = this.props
        this.setState({ event: { ...emptyEvent } })
        this.props.onDelete(event)
    }

    onValidate = () => {
        const { event } = this.state
        if (event.idMateriel && event.idType && event.eventDate) {
            this.setState({ event: { ...emptyEvent } })
            this.props.onValidate({ ...event, eventDate: new Date(event.eventDate).getTime() })
        } else {
            ToastrAction.warning(i18n.fillAllFields)
        }
    }

    render() {
        const { event } = this.state
        const { idMateriel, idType, eventDate, comment } = event
        const { open, matEventsTypes, materiels } = this.props

        return (
            <DialogMUI open={open}>
                <DialogTitleMUI><b>{i18n.reportIncident}</b></DialogTitleMUI>
                <DialogContentMUI>
                    <SimpleSelect
                        id='equipment'
                        required
                        noNullValue
                        label={i18n.equipment}
                        value={idMateriel}
                        onChange={(v) => this.handleChange({ idMateriel: v })}
                        integerValue
                        options={materiels}
                        keyValue='id'
                        keyName='reference'
                    />
                    <SimpleSelect
                        id='idType'
                        required
                        noNullValue
                        label={i18n.eventType}
                        value={idType}
                        onChange={(v) => this.handleChange({ idType: v })}
                        integerValue
                        options={matEventsTypes}
                        keyValue='id'
                    />
                    <InputRow
                        id='eventDate'
                        label={i18n.date}
                        type='date'
                        required
                        variant='outlined'
                        value={moment(eventDate).format('YYYY-MM-DD')}
                        inputProps={{
                            max: '9999-12-31'
                        }}
                        onChange={(e) => this.handleChange({ eventDate: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <InputRow
                        item
                        id='comment'
                        type='text'
                        variant='outlined'
                        placeholder={i18n.comment}
                        value={comment}
                        onChange={(e) => this.handleChange({ comment: e.target.value })}
                        multiline
                        rows={10}
                        rowsMax={Infinity}
                        InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                    />
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='column'
                        alignItems='stretch'
                    >
                        <Grid item>
                            <MainButton
                                variant='contained'
                                onClick={this.onValidate}
                            >
                                {i18n.validate}
                            </MainButton>
                        </Grid>
                        {event.id && (
                            <RedButton
                                variant='outlined'
                                onClick={this.onDelete}
                            >
                                {i18n.delete}
                            </RedButton>
                        )}
                        <MainButton
                            variant='contained'
                            reverse
                            onClick={this.onCancel}
                        >
                            {i18n.cancel}
                        </MainButton>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalMatEvent.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.object),
    event: PropTypes.instanceOf(DtoMatEvent),
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onValidate: PropTypes.func,
    open: PropTypes.bool,
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalMatEvent))
