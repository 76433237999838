import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Modal } from '@mui/material'
import i18n from 'simple-react-i18n'
import { MainButton } from '../../../components/styled/buttons'

function getModalStyle() {
    return {
        top: '0%',
        left: '0%',
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1),
        margin: '10px auto',
        bottom: 'inherit',
        height: 'auto',
        width: '90vw',
        overflowY: 'auto',
    },
}))

export default function ModalSynthese({ open, toggleModal, push }) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    const handleClose = () => {
        push()
        toggleModal()
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id='modal-title'>
                Déclaration terminée
            </h2>
            <p id='modal-text'>
                La synthèse de votre déclaration est disponible dans "Mes documents".
            </p>
            <p id='modal-description' className='italic'>Pour tout renseignement, veuillez contacter votre OUGC.</p>
            <MainButton reverse variant='contained' onClick={handleClose}>
                {i18n.close}
            </MainButton>
        </div>
    )

    return (
        <Modal
            open={open}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            {body}
        </Modal>
    )
}

ModalSynthese.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}
