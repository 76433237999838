import React from 'react'
import PropTypes from 'prop-types'
import { Card, Modal } from '@mui/material'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { groupBy, range } from 'lodash'
import DtoExploitation from '../../online/agri/dto/exploitation/DtoExploitation'
import { MainButton } from '../../online/components/styled/buttons'
import LittleMap from '../../online/points/LittleMap'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import { formatPhone } from '../../../utils/StringUtil'
import { Container, Item } from '../../online/components/styled/grid'
import DtoInstallationGeo from '../../online/referencials/installations/dto/DtoInstallationGeo'
import DtoWaterTurn from '../../online/agri/dto/DtoWaterTurn'
import DtoWaterTurnRestriction from '../../online/agri/dto/DtoWaterTurnRestriction'
import { getDayDisplay, getTextSlot } from '../../../utils/AgriUtils'
import { getWeek } from '../../../utils/DateUtil'
import DtoWaterTurnsSlot from '../../online/agri/dto/DtoWaterTurnsSlot'

function ModalPoint({ open, point, exploitations, pointWaterTurns, waterTurnsRestrictions, onCancel, onValidate, citiesIndex, waterTurnsSlots }) {
    const city = citiesIndex[point.townCode] || {}
    const body = (
        <Card style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
            <Container
                className='overflowY'
                style={{
                    marginBottom: 60,
                    padding: '0 16px',
                    height: isLargeWindow() ? '-webkit-fill-available' : 'auto',
                }}>
                <Item xs={12}>
                    <h2 id='modal-title'>{i18n.pointPrelevement} {point.code}</h2>
                </Item>
                <Container item xs={12} md={6} style={{ marginBottom: 8 }}>
                    <Item xs={12}>
                        <p><b>{point.name || ''}</b></p>
                        <p>{point.parcel && point.section ? `${point.parcel} ${point.section}` : ''}</p>
                        <p>{city ? city.labelWithCode : ''}</p>
                        <p>{point.address || ''}</p>
                        <p>{point.location || ''}</p>
                    </Item>
                    {exploitations.map((e) => {
                        const data = pointWaterTurns.filter((w) => w.idExploitation === e.idExploitation && w.idInstallation === point.id)
                        const groupedSlotData = groupBy(data, 'idSlot')
                        return (
                            <Item xs={12} style={{ height: 'fit-content', padding: 4, border: '1px solid grey', borderRadius: 5 }}>
                                <div style={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <b>{e.codification || ''}</b>
                                    <MainButton variant='contained' noFullWidth onClick={() => onValidate(e.idExploitation)}>
                                        {i18n.accessFolder}
                                    </MainButton>
                                </div>
                                <p>{e.name || ''}</p>
                                <p>{e.structureType || ''}</p>
                                <p>{e.siret || ''}</p>
                                <p>{e.email || ''}</p>
                                <p>{formatPhone(e.phoneTel || '')}</p>
                                <p>{formatPhone(e.mobile || '')}</p>
                                {Object.keys(groupedSlotData).map(idSlotKey => {
                                    const slot = waterTurnsSlots.find(wts => wts.id === parseInt(idSlotKey)) || { nbSlots: 1, id: -1 }
                                    return (
                                        <>
                                            <p><b>{i18n.waterTurns || ''} : </b>({getTextSlot(slot)})</p>
                                            {groupedSlotData[idSlotKey].map(d => {
                                                const slotsDisplay = range(slot.nbSlots || 1)
                                                return (
                                                    <div style={{ display: 'flex', overflowX: 'auto', paddingBottom: 8 }}>
                                                        {data ? getWeek().map((day) => (
                                                            getDayDisplay(d[day.value], day.label, slotsDisplay, waterTurnsRestrictions)
                                                        )) : i18n.noInformation}
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )
                                }).flat()}
                            </Item>
                        )
                    })}
                </Container>
                <Item xs={12} md={6} style={{ height: isLargeWindow() ? 'calc(100% - 75px)' : 200, padding: 4 }}>
                    {point.x && point.y && (
                        <LittleMap point={point} height={isLargeWindow() ? 'auto' : 200} />
                    )}
                </Item>
            </Container>
            <Container
                justifyContent='space-between'
                alignItems='center'
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    bottom: 0,
                    height: 60,
                    padding: '4px 16px',
                }}
            >
                <MainButton reverse variant='contained' onClick={onCancel}>
                    {i18n.cancel}
                </MainButton>
            </Container>
        </Card>
    )

    return (
        <Modal
            open={open}
            className='modal'
            style={{ bottom: 'inherit', zIndex: 1100, overflowY: 'hidden' }}
        >
            {body}
        </Modal>
    )
}

ModalPoint.propTypes = {
    open: PropTypes.bool.isRequired,
    point: PropTypes.instanceOf(DtoInstallationGeo).isRequired,
    exploitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitation)).isRequired,
    pointWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)).isRequired,
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }
}

export default connect(mapStateToProps)(ModalPoint)