import moment from 'moment'
import { capitalizeFirstLetter } from './StringUtil'

const formatDate = (timestamp) => {
    if (timestamp) {
        return new Date(timestamp).toLocaleDateString()
    }
    return ''
}

const getDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY')
    }
    return ''
}
const getHour = (hour) => {
    if (hour) {
        return moment(hour).format('HH:mm:ss')
    }
    return ''
}

const getDateObj = (date) => {
    if (date) {
        return moment(`${getDate(date)}`, 'DD/MM/YYYY').format()
    }
    return ''
}

const getHourObj = (hour, format = '') => {
    if (hour) {
        return moment(`${getHour(hour)}`, 'HH:mm:ss').format(format)
    }
    return ''
}

const getFullDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY - HH:mm:ss')
    }
    return ''
}

const getDateAndHourObj = (date, hour, format = '') => {
    if (date && hour) {
        return moment(`${getDate(date)} ${getHour(hour)}`, 'DD/MM/YYYY HH:mm:ss').format(format)
    }
    return ''
}

const getDateAndHour = (date, hour) => {
    if (date && hour) {
        return getDateAndHourObj(date, hour, 'DD/MM/YYYY HH:mm')
    }
    return ''
}

const getDateWithHour = (date, hour) => {
    if (date && hour) {
        const mhour = moment(hour)
        return moment(date).hour(mhour.hour()).minute(mhour.minute()).second(mhour.second())
    }
    if (date) {
        return moment(date)
    }
    return null
}

const getHourAndMinute = (hour) => {
    if (hour) {
        return moment(hour).format('HH:mm')
    }
    return ''
}
const getMiniDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YY')
    }
    return ''
}
const getMiniDateNoYear = (date) => {
    if (date) {
        return moment(date).format('DD/MM')
    }
    return ''
}
const getYearDate = (date) => {
    if (date) {
        return moment(date).format('YYYY')
    }
    return ''
}

const getDateExport = () => {
    return moment().format('YYYYMMDDHHmmss')
}

const getYear = (date) => {
    return moment(date).year()
}

const getYearOrString = (date, string) => {
    return getYear(date) === getYear(new Date()) ? string : getYear(date)
}

const getDayDiff = (date1, date2) => {
    return moment(date1).diff(date2, 'days')
}

const getDay = (dayNumber) => {
    return capitalizeFirstLetter(moment().isoWeekday(dayNumber).format('dddd'))
}

const sortByDate = (a, b) => {
    const updateDateA = a.updateDate
    const updateDateB = b.updateDate
    let comparison = 0
    if (updateDateA && updateDateB) {
        if (updateDateA > updateDateB) {
            comparison = 1
        } else if (updateDateA < updateDateB) {
            comparison = -1
        }
        return comparison
    }
    return comparison
}

const getWeek = () => [{
    label: 'Lundi',
    value: 'day1',
}, {
    label: 'Mardi',
    value: 'day2',
}, {
    label: 'Mercredi',
    value: 'day3',
}, {
    label: 'Jeudi',
    value: 'day4',
}, {
    label: 'Vendredi',
    value: 'day5',
}, {
    label: 'Samedi',
    value: 'day6',
}, {
    label: 'Dimanche',
    value: 'day7',
}]

export {
    formatDate,
    getDate,
    getMiniDate,
    getMiniDateNoYear,
    getDayDiff,
    getYearDate,
    getYear,
    getHour,
    getDateExport,
    getYearOrString,
    getDateObj,
    getFullDate,
    getDateAndHourObj,
    getDateWithHour,
    getDateAndHour,
    getHourAndMinute,
    getDay,
    getHourObj,
    sortByDate,
    getWeek,
}
