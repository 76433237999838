export default class DtoVariousMatCounter {
    constructor(obj) {
        this.id = obj.id // Long,
        this.counterType = obj.counterType // Option[Int],
        this.brand = obj.brand // Option[String],
        this.readingCoefficient = obj.readingCoefficient // Option[Int],
        this.installationYear = obj.installationYear // Option[Int],
        this.revisionYear = obj.revisionYear // Option[Int]
        this.installationDate = obj.installationDate
        this.revisionDate = obj.revisionDate
        this.provenImpossibility = obj.provenImpossibility // Option[Boolean],
        this.agencyDecisionDate = obj.agencyDecisionDate // Option[DateTime]
    }
}