// Choisir une des deux usedUrl ci dessous

import { isRunningApp } from '../utils/LocalStorageUtils'

// A utiliser pour travailler en local (NE PAS COMMIT !!!)
// const usedUrl = 'psp.integration.aquasys.fr/'

// A utiliser pour le serveur
const usedUrl = (window.location.href || document.URL).split('#')[0]

// ////////////////////////////////////////////////////////////
const urlApp = isRunningApp() ? 'https://manager.medeau.fr/' : usedUrl
const pathFormat = urlApp.endsWith('?') ? urlApp.slice(0, -1) : urlApp
const httpsPath = `${pathFormat.startsWith('https://') ? '' : 'https://'}${pathFormat}${pathFormat.endsWith('/') ? '' : '/'}`
const path = `${httpsPath}api/`
const CONTENT_PATH = `${httpsPath}contents/`
const CMS_PATH = `${CONTENT_PATH}CMS/`

export { path, CONTENT_PATH, CMS_PATH }