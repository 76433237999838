import { orderBy, range } from 'lodash'
import React from 'react'
import i18n from 'simple-react-i18n'

const getWaterTurns = (waterTurns, restrictions, waterTurnsSlots) => {
    const allActualLevels = waterTurns.map((w) => {
        const slot = waterTurnsSlots.find(wts => wts.id === w.idSlot) || { nbSlots: 1, id: -1 }
        const slotsDisplay = range(slot.nbSlots || 1)
        const date = new Date()
        const startHour = 2
        const day = `day${date.getDay() || 7}`
        const hour = Math.abs(date.getHours() - startHour)
        const dureePlage = 24 / slot.nbSlots
        const value = w[day][slotsDisplay[Math.trunc(hour / dureePlage)]] || 'X'
        return [
            ...restrictions,
            {
                id: 'X',
                color: '#52D100',
                level: 'X',
            },
        ].find((r) => `${r.level}` === value)
    })
    const x = allActualLevels.filter((r) => r.level === 'x')
    const others = orderBy(allActualLevels.filter((r) => r.level !== 'x'), 'level')
    return [...x, ...others][0] || {}
}

const getSquare = (color, isLast) => (
    <div
        style={{
            backgroundColor: color || 'white',
            display: 'inline-block',
            width: '20px',
            border: 'solid black 1px',
            borderRight: isLast ? 'solid black 1px' : '',
        }}
    >
        &nbsp;
    </div>
)

const getDayDisplay = (value, dayLabel, slotsDisplay, restrictions) => {
    const allRestrictions = [
        ...restrictions,
        {
            id: 'X',
            color: '#52D100',
            level: 'X',
        },
    ]
    if (value) {
        const slots = value.split('')
        return (
            <div style={{ width: 'fit-content', padding: '0 8' }}>
                <span>{dayLabel}</span>
                <div style={{ display: 'flex', width: 'fit-content' }}>
                    {slotsDisplay.map((s) => getSquare((allRestrictions.find(r => `${r.level}` === slots[s]) || {}).color, s === slotsDisplay.length - 1))}
                </div>
            </div>
        )
    }
    return null
}

const getTextSlot = (waterTurnSlots) => {
    const slots = waterTurnSlots && waterTurnSlots.slots && waterTurnSlots.slots.split('/')
    return slots && slots.map((slot => {
        const hours = slot && slot.split('-')
        return hours.map(hour => `${hour}h`).join('-') || ''
    })).join('  ') || i18n.notDefined
}

export { getWaterTurns, getDayDisplay, getSquare, getTextSlot }
