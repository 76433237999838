import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, Badge, styled } from '@mui/material'
import { CMS_PATH } from '../../../conf/basepath'
import HomeAction from '../../offline/actions/HomeAction'
import { MainButton } from '../components/styled/buttons'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import AgriAction from '../agri/actions/AgriAction'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import EditableCard from '../components/cards/EditableCard'
import ModalEnquete from './ModalEnquete'

const GridFlexItem = styled(({ ...other }) => (<Grid {...other} />))({
    display: 'flex',
    height: '85px',
    margin: '0 10px',
    justifyContent: (props) => (props.center ? 'center' : 'flex-end'),
})

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            showDecla: false,
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.home,
                href: '/home',
            },
        ])
        this.props.fetchCMSHome(3).then((homeCms) => {
            this.setState({ homeCms })
        })
        const { exploitation } = this.props
        if (!exploitation.idExploitation) {
            this.props.fetchExploitation().then(() => {
                this.getDeclaration()
            })
        } else {
            this.getDeclaration()
        }
    }

    getDeclaration = () => {
        const { exploitation } = this.props
        this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then((find) => {
            const { declaration } = this.props
            if (find) {
                this.setState({ showDecla: true })
                if (!declaration.statusCode) {
                    this.setState({ openModal: true })
                }
            }
        })
    }

    onNavigateEnquete = () => {
        const { declaration } = this.props
        const updateDeclaration = {
            ...declaration,
            statusCode: declaration.statusCode || 1,
        }
        this.props.updateDeclaration(updateDeclaration).then(() => {
            this.setState({ openModal: false })
            this.navigate('enquete')
        })
    }

    navigate = (path) => {
        this.props.push(path)
        window.scrollTo(0, 0)
    }

    toggleModal = () => {
        const { openModal } = this.state
        const { declaration } = this.props
        const updateDeclaration = {
            ...declaration,
            statusCode: -1,
        }
        this.props.updateDeclaration(updateDeclaration).then(() => {
            this.setState({ openModal: !openModal })
        })
    }

    getLibelle = () => {
        const { declaration } = this.props
        const { lastStep, statusCode } = declaration
        if (statusCode === 3) {
            return i18n.validated
        } else if (statusCode === 4) {
            return <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.validatedByAdminWrap}</span>
        } else if (lastStep > 0 && lastStep <= 5) {
            return i18n.inProgress
        } else if (!lastStep && !statusCode) {
            return i18n.new
        }
        return i18n.new
    }

    getEnquete = () => {
        const { surveys, declaration } = this.props
        return surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
    }

    render() {
        const { openModal, showDecla, homeCms } = this.state
        const { cmsLogo, exploitation, exploitationWaterTurns } = this.props
        const logoUrl = cmsLogo && cmsLogo.document && cmsLogo.document.length ? CMS_PATH + cmsLogo.document[0].name : ''

        return (
            <div style={{ padding: '8px' }}>
                <EditableCard>
                    <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
                        {logoUrl && (
                            <img src={logoUrl} alt='' width='190px' style={{ margin: '20px 0 0' }} />
                        )}
                        <GridFlexItem item style={{ height: 'fit-content' }}>
                            {homeCms && (
                                <span style={{ textAlign: 'center' }}>
                                    {homeCms && <div dangerouslySetInnerHTML={{ __html: homeCms.comment || '' }} />}
                                </span>
                            )}
                        </GridFlexItem>
                    </Grid>
                </EditableCard>
                <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={2} style={{ marginTop: '20px' }}>
                    {exploitation.idExploitation && (
                        <>
                            <GridFlexItem item xs={12} md={3}>
                                <MainButton
                                    variant='contained'
                                    onClick={() => this.navigate('exploitation')}
                                >
                                    {i18n.myFolder}
                                </MainButton>
                            </GridFlexItem>
                            {exploitationWaterTurns.length ? (
                                <GridFlexItem item xs={12} md={3}>
                                    <MainButton
                                        variant='contained'
                                        onClick={() => this.navigate('waterTurns')}
                                    >
                                        {i18n.myWaterTurns}
                                    </MainButton>
                                </GridFlexItem>
                            ) : ''}
                            <GridFlexItem item xs={12} md={3}>
                                <MainButton
                                    variant='contained'
                                    onClick={() => this.navigate('consommation')}
                                >
                                    {i18n.myConso}
                                </MainButton>
                            </GridFlexItem>
                        </>
                    )}
                    {showDecla && (
                        <GridFlexItem item xs={12} md={3}>
                            <Badge
                                id='bagdeCurrent'
                                badgeContent={this.getLibelle()}
                                color='primary'
                                style={{ width: '100%' }}
                            >
                                <MainButton
                                    variant='contained'
                                    onClick={() => this.onNavigateEnquete()}
                                >
                                    {i18n.myDeclaration}
                                </MainButton>
                            </Badge>
                        </GridFlexItem>
                    )}
                    {exploitation.idExploitation && (
                        <GridFlexItem item xs={12} md={3}>
                            <MainButton
                                variant='contained'
                                onClick={() => this.navigate('documents')}
                            >
                                {i18n.myDocuments}
                            </MainButton>
                        </GridFlexItem>
                    )}
                    <GridFlexItem item xs={12} md={3}>
                        <MainButton
                            variant='contained'
                            onClick={() => this.navigate('contact')}
                        >
                            {`${i18n.contactOUGC} ${homeCms && homeCms.subtitle ? `l'${homeCms.subtitle}` : ''}`}
                        </MainButton>
                    </GridFlexItem>
                </Grid>
                <ModalEnquete
                    open={openModal}
                    toggleModal={this.toggleModal}
                    onClick={this.onNavigateEnquete}
                    enquete={this.getEnquete()}
                />
            </div>
        )
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchCMSHome: HomeAction.fetchCMSHome,
    setTitle: HomeAction.setTitle,
    push,
}

const mapStateToProps = (store) => {
    return {
        declaration: store.AgriReducer.declaration,
        surveys: store.AgriReducer.surveys,
        exploitation: store.AgriReducer.exploitation,
        cmsLogo: store.HomeReducer.cmsLogo,
        codesSandre: store.ReferencialReducer.codesSandre,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
    }
}

Home.propTypes = {
    fetchExploitation: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    updateDeclaration: PropTypes.func,
    fetchCMSHome: PropTypes.func,
    setTitle: PropTypes.func,
    push: PropTypes.func,
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    surveys: PropTypes.arrayOf(PropTypes.object),
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
