/* eslint-disable jsx-quotes */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Icon from '@mui/material/Icon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Grid } from '@mui/material'
import { Fragment } from 'react'
import HomeAction from '../../offline/actions/HomeAction'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import packageJson from '../../../../package.json'
import DtoUser from '../account/dto/DtoUser'
import { loadWMSProjections } from '../../../utils/mapUtils/CoordinateUtils'

class Burger extends Component {
    constructor(props) {
        super(props)
        this.state = {
            homeCms: {},
        }
    }

    componentDidMount() {
        loadWMSProjections()
        this.props.fetchCMSHome(3).then((homeCms) => {
            this.setState({ homeCms })
        })
    }

    onClickButton = (path) => {
        this.props.toggleBurger()
        this.props.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    disconnect = () => {
        this.props.logout()
    }

    render() {
        const { homeCms } = this.state
        const { accountUser } = this.props

        return (
            <div>
                <Drawer
                    anchor='bottom'
                    open={this.props.open}
                    onClose={this.props.toggleBurger}
                    style={{
                        zIndex: '2000',
                        width: '20vw',
                    }}
                >
                    <List style={{ marginBottom: '60px' }} disablePadding>
                        <ListItem button onClick={() => this.onClickButton('home')}>
                            <ListItemIcon>
                                <Icon>home</Icon>
                            </ListItemIcon>
                            <ListItemText primary={i18n.home} />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => this.onClickButton('account')}>
                            <ListItemIcon>
                                <Icon>person</Icon>
                            </ListItemIcon>
                            <ListItemText primary={i18n.myAccount} />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => this.onClickButton('contact')}>
                            <ListItemIcon>
                                <Icon>chat</Icon>
                            </ListItemIcon>
                            <ListItemText
                                primary={`${i18n.contactOUGC} ${homeCms && homeCms.subtitle ? `l'${homeCms.subtitle}` : ''}`}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => this.onClickButton('actualities')}>
                            <ListItemIcon>
                                <Icon>announcement</Icon>
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.actualities}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => this.onClickButton('about')}>
                            <ListItemIcon>
                                <Icon>info</Icon>
                            </ListItemIcon>
                            <ListItemText primary={i18n.legalInfos} />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => this.onClickButton('tutoriel')}>
                            <ListItemIcon>
                                <Icon>help</Icon>
                            </ListItemIcon>
                            <ListItemText primary={i18n.tuto} />
                        </ListItem>
                        <Divider />
                        {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                            <>
                                <ListItem button onClick={() => this.onClickButton('admin')}>
                                    <ListItemIcon>
                                        <Icon>folder</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={i18n.selectFolder} />
                                </ListItem>
                                <Divider />
                            </>
                        )}
                        <ListItem button onClick={this.disconnect}>
                            <ListItemIcon>
                                <Icon>power_settings_new</Icon>
                            </ListItemIcon>
                            <ListItemText primary={i18n.disconnect} />
                        </ListItem>
                        <Divider />
                    </List>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        style={{
                            position: 'fixed',
                            bottom: '0',
                            height: '60px',
                            padding: '0 15px',
                        }}
                    >
                        <Grid item>
                            <Icon className='clickable' onClick={this.props.toggleBurger} style={{ fontSize: '2rem' }}>
                                menu
                            </Icon>
                        </Grid>
                        <Grid item>
                            <span>v{packageJson.version}</span>
                        </Grid>
                    </Grid>
                </Drawer>
            </div>
        )
    }
}

Burger.propTypes = {
    fetchCMSHome: PropTypes.func,
    logout: PropTypes.func,
    push: PropTypes.func,
    toggleBurger: PropTypes.func,
    open: PropTypes.bool,
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    accountUser: PropTypes.instanceOf(DtoUser),
}

const mapStateToProps = (store) => {
    return {
        cmsEvents: store.CmsReducer.cmsEvents,
        accountUser: store.AccountReducer.accountUser,
    }
}

const mapDispatchToProps = {
    fetchCMSHome: HomeAction.fetchCMSHome,
    logout: HomeAction.logout,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Burger)
