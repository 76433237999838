module.exports = {
    RECEIVE_INSTALLATION: 'RECEIVE_INSTALLATION',
    RECEIVE_INSTALLATIONS: 'RECEIVE_INSTALLATIONS',
    RECEIVE_ALL_VARIOUS_MATERIELS: 'RECEIVE_ALL_VARIOUS_MATERIELS',
    RECEIVE_VARIOUS_MAT_SITUATIONS: 'RECEIVE_VARIOUS_MAT_SITUATIONS',
    RECEIVE_VARIOUS_MAT_TYPES: 'RECEIVE_VARIOUS_MAT_TYPES',
    RECEIVE_ALL_INSTALLATIONS_GEO: 'RECEIVE_ALL_INSTALLATIONS_GEO',
    pointStatus: [
        { code: null, label: '' },
        { code: 1, label: 'Utilisé' },
        { code: 2, label: 'Non utilisé' },
        { code: 3, label: 'A supprimer' },
    ]
}
