import i18n from 'simple-react-i18n'
import {
    RECEIVE_CMS_CATEGORIES,
    RECEIVE_CMS_EVENT,
    RECEIVE_CMS_EVENTS,
    RECEIVE_CMS_DOCUMENT,
    RECEIVE_CMS_CATEGORY,
    RECEIVE_CMS_ACTUALITIES,
} from '../constants/CmsConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'

const CmsAction = {
    receiveCMSEvents(cmsEvents) {
        return { type: RECEIVE_CMS_EVENTS, cmsEvents: cmsEvents }
    },

    receiveCMSCategories(categories) {
        return { type: RECEIVE_CMS_CATEGORIES, categories: categories }
    },

    promiseCMSCategories() {
        return fetch(ApplicationConf.cms.categories(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCMSCategories() {
        return (dispatch) => {
            return CmsAction.promiseCMSCategories()
                .then((json) => {
                    dispatch(CmsAction.receiveCMSCategories(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.categories} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.categories)
                })
        }
    },

    receiveCMSEvent(cmsEvent) {
        return { type: RECEIVE_CMS_EVENT, cmsEvent }
    },

    fetchCMSEvent(id, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.get(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    dispatch(CmsAction.receiveCMSEvent(json))
                    callback()
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.article} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.article)
                })
        }
    },

    promiseCMSEvents() {
        return fetch(ApplicationConf.cms.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchCMSEvents(page) {
        return (dispatch) => {
            return CmsAction.promiseCMSEvents()
                .then((json = []) => {
                    dispatch(CmsAction.receiveCMSEvents(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.articles} : ${err}`)
                    if (page !== 'dashboard') {
                        ToastrAction.error(i18n.fetchError + i18n.articles)
                    }
                })
        }
    },

    receiveCMSDocument(document) {
        return { type: RECEIVE_CMS_DOCUMENT, document }
    },

    fetchCMSDocument(name) {
        return (dispatch) =>
            fetch(ApplicationConf.files.getDocument(name), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    dispatch(CmsAction.receiveCMSDocument(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
    },

    receiveCmsActualities(actualities) {
        return { type: RECEIVE_CMS_ACTUALITIES, actualities }
    },

    fetchCmsActualities() {
        return dispatch => {
            return fetch(ApplicationConf.cms.getByCategory(6), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(CmsAction.receiveCmsActualities(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    fetchCmsByCategory(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(CmsAction.receiveCmsCateg(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    receiveCmsCateg(cms) {
        return { type: RECEIVE_CMS_CATEGORY, cms }
    },
}

export default CmsAction
