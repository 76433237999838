'use strict'
import {
    RECEIVE_INSTALLATION,
    RECEIVE_INSTALLATIONS,
    RECEIVE_ALL_VARIOUS_MATERIELS,
    RECEIVE_VARIOUS_MAT_SITUATIONS,
    RECEIVE_VARIOUS_MAT_TYPES,
    RECEIVE_ALL_INSTALLATIONS_GEO,
} from '../constants/InstallationsConstants'
import DtoInstallation from '../dto/DtoInstallation'
import DtoVariousMateriel from '../dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../dto/DtoVariousMatSituation'
import DtoVariousMatType from '../dto/DtoVariousMatType'
import DtoInstallationGeo from '../dto/DtoInstallationGeo'
import { FULL_RESET, RESET_AGRI } from '../../../../offline/constants/HomeConstants'

export const store = {
    installation: {},
    installations: [],
    variousMateriels: [],
    variousMatSituations: [],
    variousMatTypes: [],
    installationsWithGeo: [],
}

export function InstallationsReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_INSTALLATIONS_GEO:
            return {
                ...state,
                installationsWithGeo: action.installationsWithGeo.map((i) => new DtoInstallationGeo(i)),
            }
        case RECEIVE_INSTALLATION:
            return {
                ...state,
                installation: new DtoInstallation(action.installation),
            }
        case RECEIVE_INSTALLATIONS:
            return {
                ...state,
                installations: action.installations.map((i) => new DtoInstallationGeo(i)),
            }
        case RECEIVE_ALL_VARIOUS_MATERIELS:
            return {
                ...state,
                variousMateriels: action.variousMateriels.map((m) => new DtoVariousMateriel(m)),
            }
        case RECEIVE_VARIOUS_MAT_SITUATIONS:
            return {
                ...state,
                variousMatSituations: action.variousMatSituations.map((s) => new DtoVariousMatSituation(s)),
            }
        case RECEIVE_VARIOUS_MAT_TYPES:
            return {
                ...state,
                variousMatTypes: action.variousMatTypes.map((s) => new DtoVariousMatType(s)),
            }
        case RESET_AGRI:
            return {
                ...store,
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
