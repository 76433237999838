/* eslint-disable camelcase */
import DtoDeclarationVolumes from './DtoDeclarationVolumes'

export default class DtoDeclarationWithStats {
    constructor(object) {
        this.idDeclaration = object.idDeclaration // Long,
        this.idContributor = object.idContributor // Long,
        this.statusCode = object.statusCode // Option[Int],
        this.updateDate = object.updateDate // Option[DateTime],
        this.updateLogin = object.updateLogin // Option[String],
        this.comment = object.comment // Option[String],
        this.lastStep = object.lastStep // Option[Int],
        this.idSurvey = object.idSurvey // Option[Long],
        this.idExploitation = object.idExploitation // Option[Long],

        this.contactType = object.contactType // Option[Long]
        this.attributionComment = object.attributionComment // Option[String]

        this.nbPtPrevi = object.nbPtPrevi // Option[Long]
        this.nbPtReal = object.nbPtReal // Option[Long]
        this.prevVolume = object.prevVolume // Option[Double]
        this.realVolume = object.realVolume // Option[Double]
        this.nbPts = object.nbPts // Option[Double]
        this.link_volumes = object.link_volumes ? object.link_volumes.map((v) => new DtoDeclarationVolumes(v)) : [] // Option[Seq[DeclarationVolumes]] = None
        this.link_declarationInstallation = object.link_declarationInstallation || [] // Option[Seq[SamplingPointExploitation]]

        this.name = object.name // Option[String],
        this.email = object.email // Option[String],
        this.phoneTel = object.phoneTel // Option[String],
        this.mobile = object.mobile // Option[String],
        this.cityCode = object.cityCode // Option[String],
        this.city = object.city // Option[String],
        this.structureType = object.structureType // Option[String],
        this.codification = object.codification // Option[String],
        this.status = object.status // Option[String]
    }
}
