import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Grid, Icon } from '@mui/material'

const useBoolean = (defaultValue = false) => {
    const [value, setValue] = useState(defaultValue)

    const setTrue = useCallback(() => setValue(true), [])
    const setFalse = useCallback(() => setValue(false), [])
    const toggle = useCallback(() => setValue(x => !x), [])

    return {
        value,
        setTrue,
        setFalse,
        toggle,
        setValue,
    }
}

const RIGHT = 'right'
const LEFT = 'left'

const SimpleTabSideList = ({
    defaultTab,
    tabs = [],
    children,

    position = RIGHT,
    isOpenByDefault = false,
}) => {
    const {
        value: isOpen,
        toggle,
    } = useBoolean(isOpenByDefault)

    const isRight = position === RIGHT

    const [tab, setTab] = useState(defaultTab)

    return (
        <div
            style={{
                zIndex: 11,
                height: '100%',
                position: 'absolute',
                [position]: isOpen ? '0' : '-80%',
                width: '80%',
                transition: 'all 0.4s cubic-bezier(0.19, 0.9, 0.46, 0.99)',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    [position]: '100%',
                    width: '50px',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Grid container direction='column' rowSpacing={'10px'} style={{ paddingTop: 10 }}>
                    {
                        tabs.map(({ icon, constant }, i) => {
                            const isCurrent = constant === tab
                            return (
                                <Grid
                                    item
                                    key={i}
                                    style={{
                                        zIndex: isCurrent ? 9 : undefined,
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: isCurrent ? 'white' : '#f4f4f4',
                                            padding: '10px',
                                            borderRadius: isRight ? '8px 0 0 8px' : '0 8px 8px 0',
                                            textAlign: 'center',
                                            display: 'flex',
                                            boxShadow: `${isRight ? '5px' : '-5px'} 0px 2px white, inset ${isRight ? '-2px' : '2px'} 0px 2px white, 0px 0px 5px #888`,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (isCurrent || !isOpen) {
                                                toggle()
                                            }
                                            setTab(constant)
                                        }}
                                    >
                                        <Icon>{icon}</Icon>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
            <div
                style={{
                    position: 'relative',
                    height: '100%',
                    display: 'block',
                    backgroundColor: 'white',
                    width: '100%',
                    overflowY: 'auto',
                    boxShadow: isOpen ? '0px 0px 5px #888' : undefined,
                }}
            >
                {children(tab)}
            </div>
        </div>
    )
}

SimpleTabSideList.propTypes = {
    defaultTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        label: PropTypes.string,
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
    children: PropTypes.func.isRequired,

    position: PropTypes.oneOf([RIGHT, LEFT]),
    isOpenByDefault: PropTypes.bool,
}

export default SimpleTabSideList
