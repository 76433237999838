import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Icon, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { compact, orderBy } from 'lodash'
import { CMS_PATH } from '../conf/basepath'
import { formatDate } from '../utils/DateUtil'
import { getLogin } from '../utils/UserUtils'
import { isRunningApp } from '../utils/LocalStorageUtils'
import CmsAction from './online/cms/actions/CmsAction'
import DtoUser from './online/account/dto/DtoUser'
import DtoCMSEvent from './online/cms/dto/DtoCMSEvent'
import { MainButton } from './online/components/styled/buttons'
import HomeAction from './offline/actions/HomeAction'
import AccountAction from './online/account/actions/AccountAction'

const styles = () => ({
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '8px',
        boxShadow: 'none',
    },
    text: {
        fontSize: '16px',
        boxShadow: 'none',
        textAlign: 'justify',
        width: '100%',
        border: 'none',
        font: 'unset',
        resize: 'none',
        color: 'black',
        opacity: 'unset',
    },
})

class Actualities extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsLoaded: false,
            actuToShow: [],
            actu: 0,
        }
    }

    componentDidMount() {
        this.props.fetchUser(getLogin()).then(() => {
            this.props.fetchCmsActualities().then(() => {
                const { actualities, accountUser } = this.props
                const actuNotSeen = compact(actualities.map((c) => {
                    if (c.status === 1 || accountUser.isAdmin === '1') {
                        return c
                    }
                    return null
                }))
                const actuToShow = orderBy(actuNotSeen, 'updateDate')
                this.setState({ cmsLoaded: true, actuToShow, actu: actuToShow.length ? actuToShow.length - 1 : 0 })
            })
        })
        this.props.setTitle([
            {
                title: i18n.actualities,
                href: '/actualities',
            },
        ])
    }

    openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    getIcon = (status) => {
        switch (status) {
            case 2:
                return 'create'
            case 3:
                return 'folderOpen'
            default:
                return 'create'
        }
    }

    getPanel = () => {
        const { actu, actuToShow } = this.state
        const actuality = actuToShow[actu] || {}
        return (
            <Grid item className='overflowY'>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item className='padding-2'>
                        <span id='actu-title' style={{ fontSize: '1.5em' }}>{actuality.title}</span><br />
                        <span id='actu-description' className='italic' style={{ display: 'flex' }}>{formatDate(actuality.updateDate)}{actuality.status !== 1 && <Icon>{this.getIcon(actuality.status)}</Icon>}</span>
                    </Grid>
                    {!!actuality.document.length && (
                        <Icon
                            className='clickable'
                            onClick={() => this.openDocument(actuality.document[0])}
                            fontSize='large'
                        >
                            insert_drive_file
                        </Icon>
                    )}
                </Grid>
                {actuality.subtitle && (
                    <p id='actu-subtitle'>
                        {actuality.subtitle}
                    </p>
                )}
                <div style={{ backgroundColor: 'white', padding: 4, borderRadius: 10, border: '1px solid' }} dangerouslySetInnerHTML={{ __html: actuality.comment || '' }} />
                <p id='actu-footer' className='italic'>{actuality.footerContent}</p>
                <p
                    className='blue-text'
                    onClick={() => this.openWebSite(actuality.link)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {actuality.link}
                    <Icon>launch</Icon>
                </p>
            </Grid>
        )
    }

    prevActu = () => {
        this.setState(({ actu }) => ({ actu: actu - 1 }))
    }

    nextActu = () => {
        this.setState(({ actu }) => ({ actu: actu + 1 }))
    }

    getContent = () => {
        const { actu, actuToShow } = this.state
        return (
            <div style={{ width: '100%', paddingBottom: 60 }}>
                <Grid
                    container
                    className='overflowY padding-2'
                    direction='row'
                    alignItems='flex-start'
                    style={{
                        display: 'block',
                    }}
                >
                    {!!actuToShow.length && this.getPanel()}
                </Grid>
                {actuToShow.length > 1 && (
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={1}
                        className='borderTop-Grey'
                        style={{
                            position: 'fixed',
                            bottom: 60,
                            height: 60,
                            backgroundColor: 'white',
                            padding: '4px 16px',
                        }}
                    >
                        <Grid item xs={6}>
                            {!!actu && (
                                <MainButton
                                    variant='contained'
                                    onClick={this.prevActu}
                                >
                                    <Icon>keyboard_arrow_left</Icon>
                                    {i18n.previous}
                                </MainButton>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {actu !== actuToShow.length - 1 && (
                                <MainButton
                                    variant='contained'
                                    onClick={this.nextActu}
                                >
                                    {i18n.next}
                                    <Icon>keyboard_arrow_right</Icon>
                                </MainButton>
                            )}
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }

    render() {
        const { accountUser } = this.props
        const { cmsLoaded } = this.state

        if (cmsLoaded && accountUser.login) {
            return this.getContent()
        }
        return <LinearProgress />
    }
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
    actualities: store.CmsReducer.actualities,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCmsActualities: CmsAction.fetchCmsActualities,
    fetchUser: AccountAction.fetchUser,
}

Actualities.propTypes = {
    fetchCmsActualities: PropTypes.func,
    fetchUser: PropTypes.func,
    setTitle: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.object),
    accountUser: PropTypes.instanceOf(DtoUser),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Actualities))
