import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { LinearProgress, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { sortById } from '../utils/ObjectUtils'
import CGUValidDateCard from './online/components/cards/CGUValidDateCard'
import CmsAction from './online/cms/actions/CmsAction'
import DtoCMSEvent from './online/cms/dto/DtoCMSEvent'
import HomeAction from './offline/actions/HomeAction'
import EditableCard from './online/components/cards/EditableCard'

const styles = () => ({
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '8px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    text: {
        fontSize: '16px',
        boxShadow: 'none',
        textAlign: 'justify',
        width: '100%',
        border: 'none',
        font: 'unset',
        resize: 'none',
        color: 'black',
        opacity: 'unset',
    },
})

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsLoaded: true,
        }
    }

    componentDidMount() {
        this.getCMS()
        this.props.setTitle([
            {
                title: i18n.legalInfos,
                href: '/about',
            },
        ])
    }

    getCMS = () => {
        const { cmsEvents } = this.props
        if (!cmsEvents.length) {
            this.setState({ cmsLoaded: false })
            this.props.fetchCMSEvents().then(() => {
                this.setState({ cmsLoaded: true })
            })
        }
    }

    showCMS = (category) => {
        const { cmsEvents = [] } = this.props
        const filteredCms = cmsEvents.filter((cms) => cms.idCategory === category)
        filteredCms.sort(sortById)

        return filteredCms.map((cms) => {
            return (
                <Grid key={cms.id} item xs={12}>
                    <EditableCard
                        title={cms.title}
                        hideIcon
                    >
                        <div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                    </EditableCard>
                </Grid>
            )
        })
    }

    render() {
        const { cmsLoaded } = this.state

        if (cmsLoaded) {
            return (
                <Grid container>
                    {this.showCMS(1)}
                    <Grid item xs={12}>
                        <hr style={{ backgroundColor: 'black' }} />
                        <CGUValidDateCard />
                    </Grid>
                    {this.showCMS(3)}
                </Grid>
            )
        }
        return <LinearProgress />
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
}

const mapStateToProps = (store) => {
    return {
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

About.propTypes = {
    fetchCMSEvents: PropTypes.func,
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    setTitle: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.object),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(About))
