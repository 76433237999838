import moment from 'moment'

const onChangeDate = (v, callback = () => { }) => {
    if (v.length >= 10) {
        const regex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
        const date = moment(v, 'DD/MM/YYYY')
        if (v.length === 10 && v[2] === '/' && v[5] === '/' && date.isValid() && regex.test(v)) {
            callback(date.valueOf())
        }
    }
}

const onChangeHour = (v, callback = () => { }) => {
    if (v.length >= 8) {
        const regex = /\d\d:\d\d:\d\d/g
        const date = moment(v, 'HH:mm:ss')
        if (v.length === 8 && v[2] === ':' && v[5] === ':' && date.isValid() && regex.test(v)) {
            callback(date.valueOf())
        }
    }
}

const isValidEmail = (email) => {
    const regex = /.+@+./g
    return regex.test(email)
}

const getSetting = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found ? found.value : null
}

const getSettingInt = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found && parseInt(found.value) ? parseInt(found.value) : null
}

export { onChangeHour, onChangeDate, isValidEmail, getSetting, getSettingInt }
