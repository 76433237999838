import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Checkbox, InputLabel, Select, MenuItem, FormGroup, FormControlLabel } from '@mui/material'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { isEmpty } from '../../../../utils/ObjectUtils'
import { FormControlRow, InputRow } from '../styled/inputs'
import DtoTankDeclaration from '../../agri/dto/enquete/DtoTankDeclaration'
import DtoTankExploit from '../../agri/dto/exploitation/DtoTankExploit'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import { formatMilliers } from '../../../../utils/StringUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import DtoAgriTanksType from '../../agri/dto/DtoAgriTanksType'
import EditableCard from './EditableCard'

class RetenueCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            retenue: props.retenue,
        }
    }

    onChangeRetenue = (key, value) => {
        const { retenue } = this.state
        this.setState({
            retenue: {
                ...retenue,
                [key]: value
            }
        })
    }

    getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    handleChangePeriode = (select, code, tank) => {
        let newTankPeriodes = []
        if (select) {
            newTankPeriodes.push({
                idTank: tank.idTank || -1,
                idPeriode: code,
                idNbPeriode: null,
            })
        } else {
            if (tank.link_periodes.find((p) => p.idNbPeriode === code)) {
                newTankPeriodes = [
                    ...tank.link_periodes,
                ]
                newTankPeriodes = newTankPeriodes.filter((p) => p.idNbPeriode !== null && p.idNbPeriode !== code)
            } else {
                newTankPeriodes = [
                    ...tank.link_periodes,
                    {
                        idTank: tank.idTank || -1,
                        idPeriode: 2,
                        idNbPeriode: code,
                    },
                ]
                newTankPeriodes = newTankPeriodes.filter((p) => p.idNbPeriode !== null)
            }
            if (!newTankPeriodes.length) {
                newTankPeriodes.push({
                    idTank: tank.idTank || -1,
                    idPeriode: 2,
                    idNbPeriode: null,
                })
            }
        }
        this.onChangeRetenue('link_periodes', newTankPeriodes)
    }

    onCancelRetenue = () => {
        const { retenue } = this.props
        this.setState({ retenue })
        this.props.onCancel()
    }

    onSaveRetenue = () => {
        const { retenue } = this.state
        this.props.onSave(retenue)
    }

    getContent = (editMode) => {
        const { retenue } = this.state
        const { codesSandre, agriTanksTypes } = this.props
        const fillingModes = codesSandre.filter((c) => c.field === 'PREL_AGRI.MODE_REMPLISSAGE')
        const fillingPeriodes = codesSandre.filter((c) => c.field === 'PREL_AGRI.PERIODE_REMPLISSAGE')
        const tanksPeriodesDetails = codesSandre.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')

        const tankSaisonnal = (retenue.link_periodes || []).find((p) => p.idPeriode === 2)
        const tankAnnuel = (retenue.link_periodes || []).find((p) => p.idPeriode === 1)
        const tankUnknown = (retenue.link_periodes || []).find((p) => p.idPeriode === 0)
        const fillingPeriod = tankSaisonnal ? 2 : (tankAnnuel ? 1 : (hasValue(tankUnknown) ? 0 : null))

        if (editMode) {
            return (
                <Grid container spacing={1}>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.detentionType}</InputLabel>
                        <Select
                            id='detentionType'
                            label={i18n.detentionType}
                            value={retenue.tankType}
                            onChange={(e) => this.onChangeRetenue('tankType', e.target.value)}
                        >
                            {agriTanksTypes.map((mode) =>
                                <MenuItem value={mode.id}>{mode.name}</MenuItem>
                            )}
                        </Select>
                    </FormControlRow>
                    <InputRow
                        id='capacity'
                        label={i18n.capacity}
                        type='number'
                        value={retenue.capacity}
                        onChange={(e) => this.onChangeRetenue('capacity', parseInt(e.target.value) || 0)}
                        variant='outlined'
                    />
                    <InputRow
                        id='recoveryFlow'
                        label={i18n.recoveryFlow}
                        type='number'
                        value={retenue.recoveryFlow}
                        onChange={(e) => this.onChangeRetenue('recoveryFlow', parseFloat(e.target.value))}
                        variant='outlined'
                    />
                    <InputRow
                        id='reserveFlow'
                        label={i18n.reserveFlow}
                        type='number'
                        value={retenue.reserveFlow}
                        onChange={(e) => this.onChangeRetenue('reserveFlow', parseFloat(e.target.value))}
                        variant='outlined'
                    />
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.fillingMode}</InputLabel>
                        <Select
                            id='fillingMode'
                            label={i18n.fillingMode}
                            value={retenue.fillingMode}
                            onChange={(e) => this.onChangeRetenue('fillingMode', e.target.value)}
                        >
                            {fillingModes.map((mode) =>
                                <MenuItem value={mode.code}>{mode.name}</MenuItem>
                            )}
                        </Select>
                    </FormControlRow>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.fillingPeriod}</InputLabel>
                        <Select
                            id='fillingPeriod'
                            label={i18n.fillingPeriod}
                            value={fillingPeriod}
                            onChange={(e) => this.handleChangePeriode(true, e.target.value, retenue)}
                        >
                            {fillingPeriodes.map((periode) =>
                                <MenuItem value={periode.code}>{periode.name}</MenuItem>
                            )}
                        </Select>
                    </FormControlRow>
                    <FormGroup>
                        {tankSaisonnal && tanksPeriodesDetails.map((p) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={p.code}
                                        checked={this.getSelectedPeriod(p.code, retenue.link_periodes)}
                                        onChange={(e) => this.handleChangePeriode(false, parseInt(e.target.value), retenue)}
                                    />
                                }
                                label={p.name}
                            />
                        ))}
                    </FormGroup>
                    <Grid container item xs={12} spacing={3} justifyContent='center' alignItems='center'>
                        <Grid item xs={8}>
                            {i18n.sharedRetention}
                        </Grid>
                        <Grid item xs={4}>
                            <Checkbox
                                checked={retenue.sharedRetention || false}
                                onChange={(e) => this.onChangeRetenue('sharedRetention', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <InputRow
                        id='usedPercentage'
                        label={i18n.usePourcentage}
                        value={retenue.usedPercentage}
                        type='number'
                        onChange={(e) => {
                            this.onChangeRetenue('usedPercentage', parseInt(e.target.value) || 0)
                        }}
                        variant='outlined'
                    />
                    <Grid item container xs={12} justifyContent='center' alignItems='center'>
                        <InputRow
                            item
                            id='comment'
                            label={i18n.comment}
                            type='text'
                            multiline
                            value={retenue.comment}
                            onChange={(event) => this.onChangeRetenue('comment', event.target.value)}
                            rows={5}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            )
        }
        const tankType = agriTanksTypes.find((type) => type.id === retenue.tankType)
        const fillingMode = fillingModes.find((mode) => mode.code === retenue.fillingMode)
        const fillingPeriode = fillingPeriodes.find((c) => c.code === fillingPeriod) || {}

        let saisons = ''
        if (tankSaisonnal) {
            tanksPeriodesDetails.map((p) => {
                if (this.getSelectedPeriod(p.code, retenue.link_periodes)) {
                    saisons = `${saisons}${p.name}, `
                }
            })
        }

        return (
            isEmpty(retenue) ? (
                <p>{i18n.noInformation}</p>
            ) : (
                <>
                    <p>{i18n.detentionType} : {tankType ? tankType.name : ''}</p>
                    <p>{i18n.capacity} : {`${formatMilliers(retenue.capacity) || 0} m3` || ''}</p>
                    <p>{i18n.recoveryFlow} : {retenue.recoveryFlow || ''}</p>
                    <p>{i18n.reserveFlow} : {retenue.reserveFlow || ''}</p>
                    <p>{i18n.fillingMode} : {fillingMode ? fillingMode.name : ''}</p>
                    <p>{i18n.fillingPeriod} : {fillingPeriode ? fillingPeriode.name : ''}{saisons.length ? ` (${saisons.slice(0, -2)})` : ''}</p>
                    <p>{i18n.sharedRetention} : {retenue.sharedRetention ? i18n.yes : i18n.no}</p>
                    <p>{i18n.usePourcentage} : {retenue.usedPercentage || ''}</p>
                    <p>{i18n.comment} : {retenue.comment || ''}</p>
                </>)
        )
    }

    render() {
        const { title, editTitle, editMode, hideIcon, accordeon, expanded } = this.props
        return (
            <EditableCard
                title={title}
                editTitle={editTitle}
                editMode={editMode}
                onCancel={this.onCancelRetenue}
                onSave={this.onSaveRetenue}
                toggleEditMode={this.props.toggleEditMode}
                hideIcon={hideIcon}
                accordeon={accordeon}
                expanded={expanded}
            >
                {this.getContent(editMode)}
            </EditableCard>
        )
    }
}

RetenueCard.propTypes = {
    title: PropTypes.string.isRequired,
    editTitle: PropTypes.string,
    retenue: PropTypes.instanceOf(DtoTankDeclaration || DtoTankExploit).isRequired,
    hideIcon: PropTypes.bool,
    accordeon: PropTypes.bool,
    expanded: PropTypes.bool,
    editMode: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
}

RetenueCard.defaultProps = {
    editable: false,
    retenue: {},
}

const mapStateToProps = (store) => {
    return {
        codesSandre: store.ReferencialReducer.codesSandre,
        agriTanksTypes: store.ReferencialReducer.agriTanksTypes,
    }
}

export default connect(mapStateToProps, null)(RetenueCard)
