import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid, Icon, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoCity from '../referencials/city/dto/DtoCity'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import HomeAction from '../../offline/actions/HomeAction'
import { MainButton } from '../components/styled/buttons'
import { Item } from '../components/styled/grid'
import { formatDate } from '../../../utils/DateUtil'

const styles = () => ({
    title: {
        backgroundColor: '#ccc',
        fontWeight: 'bold',
        color: 'black',
        borderTop: '1px solid grey',
        borderBottom: '1px solid grey',
        padding: '1em 0',
        textAlign: 'center',
    },
    paper: {
        backgroundColor: '#dfdfdf',
        borderBottom: '1px solid grey',
        padding: '8px 25px',
        cursor: 'pointer',
    },
    listItem: {
        borderBottom: '1px solid grey',
        padding: '8px 16px',
    },
})

class PointsHisto extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.myFolder,
                href: '/exploitation',
            },
            {
                title: i18n.historical,
                href: 'historical',
            },
        ])
    }

    getContent = (linkPoints) => {
        const { classes, citiesIndex, installations } = this.props

        return (
            <>
                {linkPoints.map((p) => {
                    const point = installations.find(({ id }) => id === p.idInstallation)
                    const city = citiesIndex[point.townCode]
                    return (
                        <Grid
                            key={point.id}
                            xs={12}
                            className={classes.listItem}
                        >
                            <Grid><b>{city ? city.name : ''}</b></Grid>
                            <Grid>{point.location || ''}</Grid>
                            <Grid>{`${point.code || ''} ${`- ${point.parcel || ''} ${point.section || ''}${point.name ? ` - ${point.name}` : ''}`}`}</Grid>
                            <Grid className='italic' style={{ marginTop: 8, textAlign: 'end' }}>{`${i18n.endDate} : ${formatDate(p.endDate) || ''}`}</Grid>
                        </Grid>
                    )
                })}
            </>
        )
    }

    render() {
        const {
            classes,
            cities = [],
            installations = [],
            exploitation,
        } = this.props

        if (cities.length && installations.length) {
            return (
                <Grid container alignItems='center' justifyContent='center'>
                    <Item
                        xs={12}
                        className={classes.title}
                    >
                        <b style={{ fontSize: '1.7rem' }}>{exploitation.link_samplingsHisto.length}</b>
                        {exploitation.link_samplingsHisto.length > 1
                            ? ` ${i18n.pointsPrelevement}`
                            : ` ${i18n.pointPrelevement}`}
                    </Item>
                    {this.getContent(exploitation.link_samplingsHisto || [])}
                    <Grid item xs={11}>
                        <MainButton
                            reverse
                            onClick={() => this.props.push('/exploitation')}
                            margintop
                        >
                            <Icon>keyboard_arrow_left</Icon>
                            {i18n.myFolder}
                        </MainButton>
                    </Grid>
                </Grid>
            )
        }
        return <LinearProgress />
    }
}

PointsHisto.propTypes = {
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    classes: PropTypes.instanceOf(PropTypes.object),
    push: PropTypes.func,
    setTitle: PropTypes.func,
    map: PropTypes.bool,
}

PointsHisto.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PointsHisto))
