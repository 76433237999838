/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import {
    Card,
    Grid,
    Icon,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import moment from 'moment'
import { compact } from 'lodash'
import { MainButton } from '../../../components/styled/buttons'
import DtoVariousMatSituation from '../../../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../../../referencials/installations/dto/DtoVariousMateriel'
import DtoSandreCode from '../../../referencials/dto/DtoSandreCode'
import DtoSamplingPointDecla from '../../../agri/dto/exploitation/DtoSamplingPointDecla'
import { FormControlRow, InputRow } from '../../../components/styled/inputs'
import DtoVariousMatType from '../../../referencials/installations/dto/DtoVariousMatType'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import LogAction from '../../../../../utils/log/actions/LogAction'
import { getSetting } from '../../../../../utils/FormUtils'
import { hasValue } from '../../../../../utils/NumberUtil'
import { DialogActionsMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/dialog'

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        zIndex: '1100',
    },
    card: {
        padding: '8px',
    },
    selected: {
        padding: '8px',
        backgroundColor: 'green',
        color: 'white',
    },
    whiteField: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '& .MuiOutlinedInput-input': {
            color: 'white'
        },
        '& .MuiInputLabel-outlined': {
            color: 'white'
        },
    }
})

class ModalAddEquipements extends Component {
    constructor(props) {
        super(props)
        const pointId = props.point.idInstallation
        this.state = {
            link_equipments: props.declaration.link_declarationInstallation.find((p) => p.idInstallation === pointId).link_equipments || [],
            link_pumps_maj: props.declaration.link_pumps || [],
            link_counters_maj: props.declaration.link_counters || [],
            allPointsSituation: props.variousMatSituations.filter((m) => m.siteType === 7 && props.exploitation.link_samplings.find((p) => m.siteCode === p.idInstallation)) || [],
            materiels: props.materiels.filter((m) => m.siteCode !== pointId),
            materielsSelected: props.materiels.filter((m) => m.siteCode === pointId),
            mergedMatSitu: [],
            mergedSelectedMatSitu: [],
            linkAssignments: [],
            linkCounters: [],
            panel: 'pumps',
            pump: null,
            counter: null,
        }
    }

    componentDidMount() {
        const { variousMatSituations } = this.props
        const { materiels, materielsSelected, link_equipments } = this.state

        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'c')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')

        const newSelectedMatsSitu = compact(newMats.map((mLink) => {
            return variousMatSituations.find((mSitu) => mSitu.idVarious === mLink.idMat)
        }))
        const mergedSelectedMatSitu = compact([
            ...materielsSelected.filter((m) => !deletedMats.find((mLink) => m.idVarious === mLink.idMat)),
            ...newSelectedMatsSitu,
        ])

        const newMatsSitu = compact(deletedMats.map((mLink) => {
            return variousMatSituations.find((mSitu) => mSitu.idVarious === mLink.idMat)
        }))
        const mergedMatSitu = compact([
            ...materiels.filter((m) => !newMats.find((mLink) => m.idVarious === mLink.idMat)),
            ...newMatsSitu,
        ])

        const linkAssignments = link_equipments.filter((linkE) => linkE.mode !== 'd').map((linkE) => {
            return {
                ...linkE,
                idMat: linkE.idMat,
                assignmentRate: linkE.assignmentRate,
            }
        })

        let linkCounters = link_equipments.filter((linkE) => linkE.idMatAttachment).map((linkE) => {
            return {
                ...linkE,
                idPump: linkE.idMat,
                idCounter: linkE.idMatAttachment,
                mode: linkE.mode,
            }
        })
        mergedSelectedMatSitu.forEach((mat) => {
            const counterSitu = variousMatSituations.find((mSitu) => mSitu.siteType === 8 && mat.idVarious === mSitu.siteCode)
            if (counterSitu && !linkCounters.find((linkC) => linkC.idPump === counterSitu.siteCode && linkC.idCounter === counterSitu.idVarious)) {
                linkCounters.push({
                    ...counterSitu,
                    idPump: counterSitu.siteCode,
                    idCounter: counterSitu.idVarious,
                    mode: 'u'
                })
            }
        })
        this.setState({ mergedMatSitu, mergedSelectedMatSitu, linkAssignments, linkCounters })
    }

    updateMatSelected = (pompeInfos) => {
        const { mergedMatSitu, mergedSelectedMatSitu } = this.state
        const matFind = mergedSelectedMatSitu.find((m) => m.idVarious === pompeInfos.id)
        if (matFind) {
            this.setState({
                mergedSelectedMatSitu: mergedSelectedMatSitu.filter((p) => p.idVarious !== pompeInfos.id),
                mergedMatSitu: [...mergedMatSitu, matFind],
            })
        } else {
            this.setState({
                mergedSelectedMatSitu: [...mergedSelectedMatSitu, mergedMatSitu.find((m) => m.idVarious === pompeInfos.id)],
                mergedMatSitu: mergedMatSitu.filter((p) => p.idVarious !== pompeInfos.id),
            })
        }
    }

    changePanel = (event, panel, matInfos) => {
        event.stopPropagation()
        document.getElementById('cardModalEquipment').scroll({ top: 0 })
        switch (panel) {
            case 'pumps':
                this.setState({ panel, pump: null })
                break
            case 'counters':
                this.setState({ panel, pump: matInfos, counter: null })
                break
            case 'counterDetails':
                this.setState({ panel, counter: matInfos })
                break
            default:
                this.setState({ panel, pump: null })
                break
        }
    }

    getPumpCard = (pompeInfos, selected) => {
        const { codesSandre, variousMateriels, variousMatTypes, classes } = this.props
        const { linkCounters } = this.state
        const pumpNature = variousMatTypes.find((t) => t.materielType === pompeInfos.materielType)
        const pumpCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === pompeInfos.mobilityCode)
        const pumpType = codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === pompeInfos.pumpType)
        const filteredCounters = variousMateriels.filter((c) => linkCounters.find((linkC) => linkC.idPump === pompeInfos.id && linkC.idCounter === c.id && linkC.mode !== 'd'))
        return (
            <Grid item xs={12} md={3} className='clickable padding-1' onClick={() => this.updateMatSelected(pompeInfos)}>
                <Card className={selected ? classes.selected : classes.card}>
                    <Grid item xs={12} container justifyContent='space-between'>
                        <Grid item xs={10}>
                            <b>{pompeInfos.reference || ''}</b>
                        </Grid>
                        {selected && (
                            <Grid item xs={2} onClick={(e) => this.changePanel(e, 'counters', pompeInfos)} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Icon style={{ fontSize: '2rem' }}>edit</Icon>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <b>{i18n.nature} : </b><span>{pumpNature ? pumpNature.name : ''}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <b>{i18n.categ} : </b><span>{pumpCategory ? pumpCategory.name : ''}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <b>{i18n.type} : </b><span>{pumpType ? pumpType.name : ''}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <b>{`${filteredCounters.length > 1 ? i18n.countingDevices : i18n.countingDevice} : `}</b>
                    </Grid>
                    <Grid item xs={12}>
                        <span>{filteredCounters.length === 0 ? 'Aucun' : filteredCounters.map((mat, index) => `${mat.reference}${index + 1 !== filteredCounters.length ? ', ' : ''}`)}</span>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getPumps = (matsSitu, selected) => {
        const {
            variousMateriels,
            variousMatSituations,
        } = this.props
        const { link_equipments, link_pumps_maj } = this.state
        return compact(matsSitu.map((pointSitu) => {
            const updatedMat = link_equipments.find((mLink) => mLink.idMat === pointSitu.idVarious && mLink.mode !== selected ? 'd' : 'c')
            const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === pointSitu.idVarious)
            const pompeMat = variousMateriels.find((m) => m.id === pointSitu.idVarious) || {}
            const pompe = pompeMat.pump || {}
            const pompeUpdated = link_pumps_maj.find((p) => p.id === pompeMat.id)
            const pompeInfos = {
                ...pompeSituation,
                ...pompeMat,
                ...pompe,
                ...pompeUpdated,
                assignmentRate: updatedMat ? updatedMat.assignmentRate : null
            }
            if (!pompeMat.id) {
                this.props.logError(`Le matériel ayant l'id ${pointSitu.idVarious} ne semble plus exister dans la table des matériels divers`)
                return null
            }
            return this.getPumpCard(pompeInfos, selected)
        }))
    }

    updateCountersSelected = (idPump, idCounter) => {
        const { linkCounters } = this.state
        const linkFind = linkCounters.find((link) => link.idPump === idPump && link.idCounter === idCounter)
        if (linkFind) {
            const filteredLinks = linkCounters.filter((link) => link.idPump !== idPump || link.idCounter !== idCounter)
            if (linkFind.mode === 'd') {
                this.setState({ linkCounters: [...filteredLinks, { ...linkFind, mode: 'u' }] })
            } else {
                this.setState({ linkCounters: [...filteredLinks, { ...linkFind, mode: 'd' }] })
            }
        } else {
            this.setState({ linkCounters: [...linkCounters, { idPump, idCounter, mode: 'c' }] })
        }
    }

    getCounterCard = (compteurInfos, selected) => {
        const { variousMatTypes, classes } = this.props
        const { pump } = this.state
        const counterType = variousMatTypes.find((t) => t.materielType === compteurInfos.materielType)
        return (
            <Grid item xs={12} md={3} className='clickable padding-1' onClick={() => this.updateCountersSelected(pump.id, compteurInfos.id)}>
                <Card className={selected ? classes.selected : classes.card}>
                    <Grid item xs={12} container justifyContent='space-between'>
                        <Grid item xs={10}>
                            <b>{compteurInfos.reference || ''}</b>
                        </Grid>
                        {selected && (
                            <Grid item xs={2} onClick={(e) => this.changePanel(e, 'counterDetails', compteurInfos)} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Icon style={{ fontSize: '2rem' }}>edit</Icon>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <b>{i18n.type} : </b><span>{counterType ? counterType.name : ''}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <b>{i18n.marque} : </b><span>{compteurInfos.brand || ''}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <b>{i18n.reference} : </b><span>{compteurInfos.serialNumber || ''}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <b>{i18n.coefLecture} : </b><span>{compteurInfos.readingCoefficient || ''}</span>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getCounters = (selected) => {
        const { allPointsSituation, linkCounters, pump, link_counters_maj } = this.state
        const {
            variousMateriels,
            variousMatSituations,
            exploitation,
        } = this.props
        if (selected) {
            const filteredVariousMatCounters = variousMateriels.filter((c) => linkCounters.find((linkC) => linkC.idPump === pump.id && linkC.idCounter === c.id && linkC.mode !== 'd') && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
            return filteredVariousMatCounters.map((counterMat) => {
                const compteurInfos = {
                    ...counterMat,
                    ...(counterMat.counter || {}),
                    ...link_counters_maj.find((p) => p.id === counterMat.id)
                }
                return this.getCounterCard(compteurInfos, selected)
            })
        }
        const allPumpsSituation = variousMatSituations.filter((m) => m.siteType === 8 && allPointsSituation.find((p) => m.siteCode === p.idVarious)) || []
        let allIdsCounters = []
        let allCountersCard = []
        allPumpsSituation.forEach((pumpSitu) => {
            if (!allIdsCounters.includes(pumpSitu.idVarious) && !linkCounters.find((linkC) => linkC.idPump === pump.id && linkC.idCounter === pumpSitu.idVarious && linkC.mode !== 'd')) {
                const counterMat = variousMateriels.find((c) => c.id === pumpSitu.idVarious) || {}
                if (!hasValue(counterMat.administrator) || counterMat.administrator === exploitation.operatorCode) {
                    allIdsCounters.push(pumpSitu.idVarious)
                    const compteurInfos = {
                        ...counterMat,
                        ...(counterMat.counter || {}),
                        ...link_counters_maj.find((p) => p.id === pumpSitu.idVarious)
                    }
                    allCountersCard.push(this.getCounterCard(compteurInfos, selected, pumpSitu))
                }
            }
        })
        return allCountersCard
    }

    onChangeCounter = (compteurInfos, key, value) => {
        const { link_counters_maj } = this.state
        const { declaration, point } = this.props
        const counterFind = link_counters_maj.find((c) => c.id === compteurInfos.id)
        if (counterFind) {
            this.setState({
                link_counters_maj: [
                    {
                        ...counterFind,
                        [key]: value,
                    },
                    ...link_counters_maj.filter((c) => c.id !== compteurInfos.id),
                ]
            })
        } else {
            this.setState({
                link_counters_maj: [
                    {
                        idSurvey: declaration.idSurvey,
                        idExploitation: declaration.idExploitation,
                        idInstallation: point.idInstallation,
                        ...compteurInfos,
                        [key]: value,
                    },
                    ...link_counters_maj,
                ]
            })
        }
    }

    onChangePump = (pompeInfos, key, value) => {
        const { link_pumps_maj } = this.state
        const { declaration, point } = this.props
        const pumpFind = link_pumps_maj.find((c) => c.id === pompeInfos.id)
        if (pumpFind) {
            this.setState({
                link_pumps_maj: [
                    {
                        ...pumpFind,
                        [key]: value,
                    },
                    ...link_pumps_maj.filter((c) => c.id !== pompeInfos.id),
                ]
            })
        } else {
            this.setState({
                link_pumps_maj: [
                    {
                        idSurvey: declaration.idSurvey,
                        idExploitation: declaration.idExploitation,
                        idInstallation: point.idInstallation,
                        ...pompeInfos,
                        [key]: value,
                    },
                    ...link_pumps_maj
                ]
            })
        }
    }

    getForm = (type, matInfos) => {
        const { linkAssignments, linkCounters, pump } = this.state
        const { variousMatTypes, codesSandre, applicationSettings, exploitation, point } = this.props
        const materielReadingCoeffEntry = getSetting(applicationSettings, 'materielReadingCoeffEntry')
        if (type === 'pump') {
            const pumpCategories = codesSandre.filter((c) => c.field === 'MAT.MOBILITE')
            const pumpTypes = codesSandre.filter((c) => c.field === 'POMPES.TYPE')
            const link = linkAssignments.find((linkA) => linkA.idMat === matInfos.id && !linkA.idMatAttachment) || {}
            const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === point.idInstallation && m.idElement2 === matInfos.id) || {}
            const pumpTypesFiltered = variousMatTypes.filter((t) => t.category === 1)
            return (
                <>
                    <InputRow
                        item
                        id='assignmentRate'
                        variant='outlined'
                        label={i18n.tauxAffectationPompe}
                        type='number'
                        value={link.assignmentRate || pumpRep.repartition}
                        onChange={(e) => this.onChangeAssignmentPump({ assignmentRate: parseInt(e.target.value) || null }, matInfos)}
                        inputProps={{ min: 0, max: 100 }}
                    />
                    <InputRow
                        id='startDatePump'
                        label={i18n.startDate}
                        type='date'
                        required
                        variant='outlined'
                        value={link.startDate ? moment(link.startDate).format('YYYY-MM-DD') : (link.situationDate ? moment(link.situationDate).format('YYYY-MM-DD') : '')}
                        inputProps={{
                            max: link.endDate || '9999-12-31'
                        }}
                        onChange={(e) => this.onChangeAssignmentPump({ startDate: new Date(e.target.value).valueOf() }, matInfos)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <InputRow
                        id='endDatePump'
                        label={i18n.endDate}
                        type='date'
                        required
                        variant='outlined'
                        value={link.endDate ? moment(link.endDate).format('YYYY-MM-DD') : (link.situationEndDate ? moment(link.situationEndDate).format('YYYY-MM-DD') : '')}
                        inputProps={{
                            min: link.startDate
                        }}
                        onChange={(e) => this.onChangeAssignmentPump({ endDate: new Date(e.target.value).valueOf() }, matInfos)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.nature}</InputLabel>
                        <Select
                            id='pumpNature'
                            label={i18n.nature}
                            value={matInfos.materielType}
                            onChange={(e) => this.onChangePump(matInfos, 'materielType', e.target.value)}
                        >
                            {pumpTypesFiltered.map((t) => <MenuItem value={t.materielType}>{t.name}</MenuItem>)}
                        </Select>
                    </FormControlRow>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.categ}</InputLabel>
                        <Select
                            id='mobilityCode'
                            label={i18n.categ}
                            value={matInfos.mobilityCode}
                            onChange={(e) => this.onChangePump(matInfos, 'mobilityCode', e.target.value)}
                        >
                            {pumpCategories.map((c) => <MenuItem value={c.code}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControlRow>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.type}</InputLabel>
                        <Select
                            id='pumpNature'
                            label={i18n.type}
                            value={matInfos.pumpType}
                            onChange={(e) => this.onChangePump(matInfos, 'pumpType', e.target.value)}
                        >
                            {pumpTypes.map((c) => <MenuItem value={c.code}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControlRow>
                    <InputRow
                        item
                        id='maxFlow'
                        variant='outlined'
                        label={i18n.debitMax}
                        type='number'
                        inputProps={{ min: 0 }}
                        value={matInfos.maxFlow || ''}
                        onChange={(e) => this.onChangePump(matInfos, 'maxFlow', parseFloat(e.target.value))}
                    />
                    <InputRow
                        item
                        id='operatingFlow'
                        variant='outlined'
                        label={i18n.debitFonctionnement}
                        type='number'
                        inputProps={{ min: 0 }}
                        value={matInfos.operatingFlow || ''}
                        onChange={(e) => this.onChangePump(matInfos, 'operatingFlow', parseFloat(e.target.value))}
                    />
                    <Grid item xs={12} md={6} container spacing={1} alignItems='center'>
                        <Grid item>
                            <span>{i18n.sharedEquipment}</span><br />
                        </Grid>
                        <Grid item>
                            <Checkbox
                                color='default'
                                checked={matInfos.isShared}
                                onChange={(e) => this.onChangePump(matInfos, 'isShared', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} container spacing={1} alignItems='center'>
                        <Grid item>
                            <span>{i18n.forRent}</span><br />
                        </Grid>
                        <Grid item>
                            <Checkbox
                                color='default'
                                checked={matInfos.rent}
                                onChange={(e) => this.onChangePump(matInfos, 'rent', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <InputRow
                        item
                        id='comment'
                        type='text'
                        variant='outlined'
                        placeholder={i18n.comment}
                        value={matInfos.comment || ''}
                        onChange={(e) => this.onChangePump(matInfos, 'comment', e.target.value)}
                        multiline
                        rows={2}
                        rowsMax={Infinity}
                        InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                    />
                </>
            )
        }
        const counterTypesFiltered = variousMatTypes.filter((t) => t.category === 2)
        const link = linkCounters.find((linkC) => linkC.idCounter === matInfos.id && linkC.idPump === pump.id) || {}
        const counterRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 2 && m.idElement1 === pump.id && m.idElement2 === matInfos.id) || {}
        return (
            <>
                <InputRow
                    item
                    id='assignmentRateCounter'
                    variant='outlined'
                    label={i18n.tauxAffectation}
                    type='number'
                    value={link.assignmentRate || counterRep.repartition}
                    onChange={(e) => this.onChangeAssignmentCounter({ assignmentRate: parseInt(e.target.value) || null }, pump, matInfos)}
                    inputProps={{ min: 0, max: 100 }}
                />
                <InputRow
                    id='startDateCounter'
                    label={i18n.startDate}
                    type='date'
                    required
                    variant='outlined'
                    value={link.startDate ? moment(link.startDate).format('YYYY-MM-DD') : (link.situationDate ? moment(link.situationDate).format('YYYY-MM-DD') : '')}
                    inputProps={{
                        max: link.endDate || '9999-12-31'
                    }}
                    onChange={(e) => this.onChangeAssignmentCounter({ startDate: new Date(e.target.value).valueOf() }, pump, matInfos)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <InputRow
                    id='endDateCounter'
                    label={i18n.endDate}
                    type='date'
                    required
                    variant='outlined'
                    value={link.endDate ? moment(link.endDate).format('YYYY-MM-DD') : (link.situationEndDate ? moment(link.situationEndDate).format('YYYY-MM-DD') : '')}
                    inputProps={{
                        min: link.startDate
                    }}
                    onChange={(e) => this.onChangeAssignmentCounter({ endDate: new Date(e.target.value).valueOf() }, pump, matInfos)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControlRow variant='outlined'>
                    <InputLabel>{i18n.type}</InputLabel>
                    <Select
                        id='type'
                        label={i18n.type}
                        value={matInfos.materielType}
                        onChange={(e) => this.onChangeCounter(matInfos, 'materielType', e.target.value)}
                    >
                        {counterTypesFiltered.map((t) => <MenuItem value={t.materielType}>{t.name}</MenuItem>)}
                    </Select>
                </FormControlRow>
                <InputRow
                    item
                    id='brand'
                    variant='outlined'
                    label={i18n.marque}
                    type='text'
                    value={matInfos.brand || ''}
                    onChange={(e) => this.onChangeCounter(matInfos, 'brand', e.target.value)}
                />
                <InputRow
                    item
                    id='serialNumber'
                    variant='outlined'
                    label={i18n.reference}
                    type='text'
                    value={matInfos.serialNumber || ''}
                    onChange={(e) => this.onChangeCounter(matInfos, 'serialNumber', e.target.value)}
                />
                {materielReadingCoeffEntry === '1' || matInfos.materielType === 10 ? (
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.coefLecture}</InputLabel>
                        <Select
                            id='readingCoefficient'
                            label={i18n.coefLecture}
                            value={matInfos.readingCoefficient}
                            onChange={(e) => this.onChangeCounter(matInfos, 'readingCoefficient', e.target.value)}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </Select>
                    </FormControlRow>
                ) : (
                    <InputRow
                        item
                        id='readingCoefficient'
                        variant='outlined'
                        label={i18n.coefLecture}
                        type='number'
                        inputProps={{ min: 0 }}
                        value={matInfos.readingCoefficient || ''}
                        onChange={(e) => this.onChangeCounter(matInfos, 'readingCoefficient', parseFloat(e.target.value))}
                    />
                )}
                <InputRow
                    item
                    id='installationDate'
                    variant='outlined'
                    label={i18n.installationDate}
                    type='date'
                    inputProps={{
                        min: link.revisionDate
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => this.onChangeCounter(matInfos, 'installationDate', new Date(e.target.value).valueOf())}
                    value={matInfos.installationDate ? moment(matInfos.installationDate).format('YYYY-MM-DD') : ''}
                />
                <InputRow
                    item
                    id='revisionDate'
                    variant='outlined'
                    label={i18n.revisionDate}
                    type='date'
                    inputProps={{
                        min: link.installationDate
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => this.onChangeCounter(matInfos, 'revisionDate', new Date(e.target.value).valueOf())}
                    value={matInfos.revisionDate ? moment(matInfos.revisionDate).format('YYYY-MM-DD') : ''}
                />
                <Grid item xs={12} md={6} container spacing={1} alignItems='center'>
                    <Grid item>
                        <span>{i18n.sharedDispositif}</span><br />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            color='default'
                            checked={matInfos.isShared}
                            onChange={(e) => this.onChangeCounter(matInfos, 'isShared', e.target.checked)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} container spacing={1} alignItems='center'>
                    <Grid item>
                        <span>{i18n.forRent}</span><br />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            color='default'
                            checked={matInfos.rent}
                            onChange={(e) => this.onChangeCounter(matInfos, 'rent', e.target.checked)}
                        />
                    </Grid>
                </Grid>
                <InputRow
                    item
                    id='comment'
                    type='text'
                    variant='outlined'
                    placeholder={i18n.comment}
                    value={matInfos.comment || ''}
                    onChange={(e) => this.onChangeCounter(matInfos, 'comment', e.target.value)}
                    multiline
                    rows={3}
                    rowsMax={Infinity}
                    InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                />
            </>
        )
    }

    getContent = () => {
        const {
            mergedMatSitu,
            mergedSelectedMatSitu,
            panel,
            pump,
            counter,
            link_pumps_maj,
            link_counters_maj,
        } = this.state
        switch (panel) {
            case 'pumps':
                return (
                    <>
                        <div style={{ borderBottom: '1px solid grey' }}>
                            <Grid container alignItems='stretch'>
                                {this.getPumps(mergedSelectedMatSitu, true)}
                            </Grid>
                        </div>
                        <div>
                            <h3 style={{ padding: '0 15px' }}>{mergedMatSitu.length > 1 ? i18n.othersEquipAvailable : i18n.otherEquipAvailable}</h3>
                            <Grid container alignItems='stretch'>
                                {this.getPumps(mergedMatSitu, false)}
                            </Grid>
                            <i style={{ padding: 8 }}>{i18n.clickToSelectOrDeselect}</i>
                        </div>
                    </>
                )
            case 'counters':
                const pumpUpdated = {
                    ...pump,
                    ...link_pumps_maj.find((e) => e.id === pump.id)
                }
                return (
                    <>
                        <Grid item xs={12} container style={{ padding: '10px 16px', borderBottom: '1px solid grey' }}>
                            {this.getForm('pump', pumpUpdated)}
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0 16px' }}>
                            <h3>{i18n.countingDeviceAssociated}</h3>
                        </Grid>
                        <Grid item xs={12} style={{ borderBottom: '1px solid grey' }}>
                            <Grid container alignItems='stretch'>
                                {this.getCounters(true)}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0 16px' }}>
                            <h3>{i18n.otherCountingDevice}</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems='stretch'>
                                {this.getCounters(false)}
                            </Grid>
                            <i style={{ padding: 8 }}>{i18n.clickToSelectOrDeselect}</i>
                        </Grid>
                    </>
                )
            case 'counterDetails':
                const counterUpdated = {
                    ...counter,
                    ...link_counters_maj.find((e) => e.id === counter.id)
                }
                return (
                    <Grid item xs={12} container style={{ padding: '10px 16px', borderBottom: '1px solid grey' }}>
                        {this.getForm('counter', counterUpdated)}
                    </Grid>
                )
            default:
                return ''
        }
    }

    getTitle = () => {
        const {
            mergedSelectedMatSitu,
            panel,
            pump,
            counter,
            link_pumps_maj,
            link_counters_maj,
        } = this.state
        switch (panel) {
            case 'pumps':
                return mergedSelectedMatSitu.length > 1 ? i18n.pumpsInstalled : i18n.pumpInstalled
            case 'counters':
                const pumpUpdated = {
                    ...pump,
                    ...link_pumps_maj.find((e) => e.id === pump.id)
                }
                return `${i18n.equipementPompage} ${pumpUpdated.reference || ''}`
            case 'counterDetails':
                const counterUpdated = {
                    ...counter,
                    ...link_counters_maj.find((e) => e.id === counter.id)
                }
                return `${i18n.countingDevice} ${counterUpdated.reference || ''}`
            default:
                return ''
        }
    }

    onChangeAssignmentPump = (obj, pump) => {
        const { linkAssignments } = this.state
        const linkUpdated = linkAssignments.find((link) => link.idMat === pump.id && !link.idMatAttachment) || {}
        const newLinks = [
            ...linkAssignments.filter((link) => link.idMat !== pump.id),
            {
                ...linkUpdated,
                idMat: pump.id,
                ...obj,
            },
        ]
        this.setState({ linkAssignments: newLinks })
    }

    onChangeAssignmentCounter = (obj, pump, counter) => {
        const { linkCounters } = this.state
        const linkUpdated = linkCounters.find((linkC) => linkC.idCounter === counter.id && linkC.idPump === pump.id) || {}
        const newLinks = [
            ...linkCounters.filter((linkC) => !(linkC.idCounter === counter.id && linkC.idPump === pump.id)),
            {
                ...linkUpdated,
                ...obj,
            },
        ]
        this.setState({ linkCounters: newLinks })
    }

    onSave = () => {
        const {
            exploitation,
            declaration,
            point,
        } = this.props
        const {
            materiels,
            materielsSelected,
            linkAssignments,
            linkCounters,
            mergedMatSitu,
            mergedSelectedMatSitu,
            link_pumps_maj,
            link_counters_maj,
        } = this.state
        let newlink_equipments = []
        const globalInfos = {
            idSurvey: declaration.idSurvey,
            idExploitation: exploitation.idExploitation,
            idInstallationAttachment: point.idInstallation,
        }
        mergedSelectedMatSitu.forEach((newMatSitu) => {
            const linkAssignment = linkAssignments.find((link) => link.idMat === newMatSitu.idVarious) || {}
            const newLink_equip = {
                ...globalInfos,
                idMat: newMatSitu.idVarious,
                assignmentRate: linkAssignment.assignmentRate,
                startDate: linkAssignment.startDate,
                endDate: linkAssignment.endDate,
            }
            if (materielsSelected.find((matSitu) => newMatSitu.idVarious === matSitu.idVarious)) {// update
                newlink_equipments.push({
                    ...newLink_equip,
                    mode: 'u',
                })
            } else if (materiels.find((matSitu) => newMatSitu.idVarious === matSitu.idVarious)) {// create
                newlink_equipments.push({
                    ...newLink_equip,
                    mode: 'c',
                })
            }
        })
        mergedMatSitu.forEach((newMatSitu) => {
            const linkAssignment = linkAssignments.find((link) => link.idMat === newMatSitu.idVarious) || {}
            if (materielsSelected.find((matSitu) => newMatSitu.idVarious === matSitu.idVarious)) {// delete
                newlink_equipments.push({
                    ...globalInfos,
                    idMat: newMatSitu.idVarious,
                    mode: 'd',
                    assignmentRate: linkAssignment.assignmentRate,
                    startDate: linkAssignment.startDate,
                    endDate: linkAssignment.endDate,
                })
            }
        })
        linkCounters.forEach((linkC) => {
            newlink_equipments.push({
                ...globalInfos,
                idMat: linkC.idPump,
                idMatAttachment: linkC.idCounter,
                mode: linkC.mode,
                startDate: linkC.startDate,
                endDate: linkC.endDate,
            })
        })
        this.props.onSave(newlink_equipments, link_pumps_maj, link_counters_maj)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    render() {
        const { open } = this.props
        const { panel, pump } = this.state
        return (
            <DialogMUI open={open}>
                <DialogTitleMUI><b>{this.getTitle()}</b></DialogTitleMUI>
                <Card className='overflowY' style={{ width: '100%', height: '100%' }}>
                    <div
                        id='cardModalEquipment'
                        className='overflowY'
                    >
                        {this.getContent()}
                    </div>
                </Card>
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        {panel === 'pumps' ? (
                            <>
                                <MainButton variant='contained' disabled>
                                    {i18n.reportIncident}
                                </MainButton>
                                <MainButton variant='contained' onClick={this.onSave}>
                                    {i18n.validate}
                                </MainButton>
                            </>
                        ) : (
                            <>
                                <Grid item xs={5}>
                                    <MainButton
                                        variant='contained'
                                        onClick={(e) => this.changePanel(e, panel === 'counters' ? 'pumps' : 'counters', pump)}
                                    >
                                        <Icon>keyboard_arrow_left</Icon>
                                    </MainButton>
                                </Grid>
                                <Grid item xs={5}>
                                    <MainButton variant='contained' onClick={this.onSave}>
                                        {i18n.validate}
                                    </MainButton>
                                </Grid>
                            </>
                        )}
                        <MainButton variant='contained' reverse onClick={this.onCancel}>
                            {i18n.cancel}
                        </MainButton>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalAddEquipements.propTypes = {
    open: PropTypes.bool,
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMatTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatType)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    classes: PropTypes.instanceOf(PropTypes.object),
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    logError: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        codesSandre: store.ReferencialReducer.codesSandre,
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        exploitation: store.AgriReducer.exploitation,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    logError: LogAction.logError,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalAddEquipements))
