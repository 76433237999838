/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { Modal, Card, InputLabel, Select, MenuItem } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { MainButton, RedButton } from '../../../components/styled/buttons'
import { FormControlRow, InputRow } from '../../../components/styled/inputs'
import DtoCity from '../../../referencials/city/dto/DtoCity'
import DtoContact from '../../../referencials/dto/DtoContact'
import ToastrAction from '../../../components/toasters/ToastrAction'
import { isValidEmail } from '../../../../../utils/FormUtils'
import DtoSandreCode from '../../../referencials/dto/DtoSandreCode'
import { Container, Item } from '../../../components/styled/grid'

class ModalContributor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmDelete: false,
            contributor: props.contributor,
            contributorType: props.contributorType,
            errors: [],
        }
    }

    checkForm = () => {
        const { contributor, contributorType } = this.state
        const newErrors = []
        if (isNaN(parseInt(contributorType))) {
            newErrors.push('contributorType')
        }
        // if (!contributor.phoneTel && !contributor.mobile) {
        //     ToastrAction.error('Veuillez renseigner au moins un numéro de téléphone')*
        //     newErrors.push('minOneTel')
        // }
        if (contributor.phoneTel && contributor.phoneTel.length !== 10) {
            newErrors.push('phoneTel')
        }
        if (contributor.mobile && contributor.mobile.length !== 10) {
            newErrors.push('mobile')
        }
        if (contributor.fax && contributor.fax.length !== 10) {
            newErrors.push('fax')
        }
        if (!contributor.name) {
            newErrors.push('name')
        }
        if (!contributor.postalCode) {
            newErrors.push('postalCode')
        }
        if (!contributor.cityCode) {
            newErrors.push('cityCode')
        }
        if (contributor.email && !isValidEmail(contributor.email)) {
            newErrors.push('email')
        }
        if (newErrors.length) {
            this.setState({ errors: newErrors })
            return false
        }
        return true
    }


    onChangeContributor = (key, value) => {
        this.setState(({ contributor }) => ({
            contributor: {
                ...contributor,
                [key]: value === '' ? undefined : value,
            }
        }))
    }

    onChangeContributorType = (contributorType) => {
        this.setState({ contributorType })
    }

    onChangeContributorCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(value || ''))))
        if (citiesFiltered.length === 1) {
            const city = citiesFiltered[0].code
            this.setState(({ contributor }) => ({
                contributor: {
                    ...contributor,
                    cityCode: city === '' ? undefined : city,
                    postalCode: value === '' ? undefined : value,
                }
            }))
        } else {
            this.onChangeContributor('postalCode', value)
        }
    }

    onChangeContributorCity = (value) => {
        const { citiesIndex } = this.props
        const city = citiesIndex[value] || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.setState(({ contributor }) => ({
                contributor: {
                    ...contributor,
                    postalCode: city.link_postalCode[0],
                    cityCode: value === '' ? undefined : value,
                }
            }))
        } else {
            this.onChangeContributor('cityCode', value)
        }
    }

    onCancelContributor = () => {
        const { contributor } = this.props
        this.setState({ contributor })
        this.props.onCancel()
    }

    onSaveContributor = () => {
        const { contributor, contributorType } = this.state
        if (this.checkForm()) {
            this.props.onSave(contributor, contributorType)
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    onDelete = (bool) => {
        this.setState({ confirmDelete: bool })
    }

    onDeleteConfirm = () => {
        this.props.onDelete()
    }

    getDeleteButton = () => {
        const { confirmDelete } = this.state
        if (confirmDelete) {
            return (
                <Container spacing={1}>
                    <Item xs={6}>
                        <RedButton variant='contained' onClick={this.onDeleteConfirm}>
                            {i18n.confirm}
                        </RedButton>
                    </Item>
                    <Item xs={6}>
                        <MainButton variant='contained' reverse onClick={() => this.onDelete(false)}>
                            {i18n.cancel}
                        </MainButton>
                    </Item>
                </Container>
            )
        }
        return (
            <RedButton variant='contained' onClick={() => this.onDelete(true)}>
                {i18n.delete}
            </RedButton>
        )
    }

    getSelectedItem = () => {
        const { contributor } = this.state
        const { citiesIndex } = this.props
        const city = citiesIndex[contributor.cityCode]
        return city || {}
    }

    render() {
        const { errors, contributor, contributorType } = this.state
        const { open, cities, showLegalRepresentative, codesSandre, contributor: contribProps } = this.props
        const typesContributors = sortBy(codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT'), 'code')
        const typesContributorsWithLegalRep = [{ code: -1, name: i18n.legalRepresentative }, ...typesContributors]
        const citiesFiltered = sortBy(cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(contributor.postalCode || '')))), 'name')
        return (
            <Modal
                open={open}
                className='modal'
                style={{ bottom: 'inherit', zIndex: '1100' }}
            >
                <Card className='overflowY' style={{ width: '100%', height: '100%' }}>
                    <Container
                        className='overflowY'
                        alignItems='flex-start'
                        style={{
                            marginBottom: !contributor.id && !contributor.idContributorTemp ? '105px' : '155px',
                            height: '-webkit-fill-available',
                            padding: '0',
                            display: 'block',
                        }}
                    >
                        <Item xs={12} style={{ padding: '0 16px' }}>
                            <h3>{i18n.changeInfos}</h3>
                            <FormControlRow
                                required
                                variant='outlined'
                                error={errors.find((e) => e === 'contributorType')}
                            >
                                <InputLabel>{i18n.type}</InputLabel>
                                <Select
                                    id='type'
                                    value={contributorType}
                                    label={i18n.type}
                                    onChange={(e) => this.onChangeContributorType(e.target.value)}
                                >
                                    {contributorType === -1 || showLegalRepresentative ? (
                                        typesContributorsWithLegalRep.map((c) => {
                                            return <MenuItem value={c.code}>{c.name}</MenuItem>
                                        })
                                    ) : (
                                        typesContributors.map((c) => {
                                            return <MenuItem value={c.code}>{c.name}</MenuItem>
                                        })
                                    )}
                                </Select>
                            </FormControlRow>
                            <InputRow
                                item
                                id='name'
                                label={i18n.name}
                                type='text'
                                value={contributor.name}
                                onChange={(e) => this.onChangeContributor('name', e.target.value)}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'name')}
                            />
                            <InputRow
                                item
                                id='address'
                                label={i18n.address}
                                type='text'
                                value={contributor.road}
                                onChange={(e) => this.onChangeContributor('road', e.target.value)}
                                variant='outlined'
                            />
                            <InputRow
                                item
                                id='addressComplement'
                                label={i18n.complement}
                                type='text'
                                value={contributor.addressComplement}
                                onChange={(e) => this.onChangeContributor('addressComplement', e.target.value)}
                                variant='outlined'
                            />
                            <InputRow
                                item
                                id='postalCode'
                                label={i18n.postalCode}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 99999 } }}
                                value={contributor.postalCode}
                                onChange={(e) => this.onChangeContributorCP(e.target.value)}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'postalCode')}
                            />
                            <Autocomplete
                                id='city'
                                options={citiesFiltered}
                                getOptionLabel={(option) =>
                                    option.name ? option.name : ''
                                }
                                value={this.getSelectedItem()}
                                onChange={(e, value) =>
                                    this.onChangeContributorCity(value ? value.code : '')
                                }
                                noOptionsText='Pas de correspondance'
                                renderInput={(params) => (
                                    <InputRow
                                        {...params}
                                        label={i18n.city}
                                        variant='outlined'
                                        error={errors.find((e) => e === 'cityCode')}
                                        required
                                    />
                                )}
                                style={{ width: '100%' }}
                            />
                            <InputRow
                                item
                                id='phoneTel'
                                label={i18n.phoneTel}
                                type='text'
                                value={contributor.phoneTel}
                                onChange={(e) => this.onChangeContributor('phoneTel', e.target.value)}
                                variant='outlined'
                                error={errors.find((e) => e === 'phoneTel')}
                                helperText={errors.find((e) => e === 'phoneTel') ? '10 chiffres obligatoires' : null}
                            />
                            <InputRow
                                item
                                id='mobile'
                                label={i18n.portable}
                                onChange={(e) => this.onChangeContributor('mobile', e.target.value)}
                                type='text'
                                value={contributor.mobile}
                                variant='outlined'
                                error={errors.find((e) => e === 'mobile')}
                                helperText={errors.find((e) => e === 'mobile') ? '10 chiffres obligatoires' : null}
                            />
                            <InputRow
                                item
                                id='fax'
                                label={i18n.fax}
                                type='text'
                                value={contributor.fax}
                                onChange={(e) => this.onChangeContributor('fax', e.target.value)}
                                variant='outlined'
                                error={errors.find((e) => e === 'fax')}
                                helperText={errors.find((e) => e === 'fax') ? '10 chiffres obligatoires' : null}
                            />
                            <InputRow
                                item
                                id='email'
                                label={i18n.email}
                                type='text'
                                value={contributor.email}
                                onChange={(e) => this.onChangeContributor('email', e.target.value)}
                                variant='outlined'
                                // required
                                error={errors.find((e) => e === 'email')}
                                helperText={errors.find((e) => e === 'email') ? 'Adresse non valide' : null}
                            />
                        </Item>
                    </Container>
                    <Container
                        justifyContent='space-between'
                        alignItems='center'
                        style={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            bottom: '0',
                            height: !contributor.id && !contributor.idContributorTemp ? '105px' : '150px',
                            padding: '4px 16px',
                        }}
                    >
                        <MainButton variant='contained' onClick={this.onSaveContributor}>
                            {i18n.validate}
                        </MainButton>
                        {!contribProps.id && !contribProps.idContributorTemp ? '' : (
                            this.getDeleteButton()
                        )}
                        <MainButton variant='contained' reverse onClick={this.onCancelContributor}>
                            {i18n.cancel}
                        </MainButton>
                    </Container>
                </Card>
            </Modal>
        )
    }
}

ModalContributor.propTypes = {
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    contributorType: PropTypes.number,
    showLegalRepresentative: PropTypes.bool,
    push: PropTypes.func,
    contributor: PropTypes.instanceOf(DtoContact).isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        codesSandre: store.ReferencialReducer.codesSandre,
    }
}

export default connect(mapStateToProps)(ModalContributor)
