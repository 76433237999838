/* eslint-disable indent */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import {
    Grid,
    Icon,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    Checkbox,
    FormHelperText,
} from '@mui/material'
import { orderBy, sortBy } from 'lodash'
import { connect } from 'react-redux'
import { MainButton, OrangeButton, RedButton } from '../../../components/styled/buttons'
import { HelpTooltip, SubTitleCard } from '../../../components/styled/texts'
import { FormControlRow, InputRow } from '../../../components/styled/inputs'
import DtoCulture from '../../../referencials/dto/DtoCulture'
import DtoCulturesFamily from '../../../referencials/dto/DtoCulturesFamily'
import DtoUsageDeclaration from '../../../agri/dto/enquete/DtoUsageDeclaration'
import ToastrAction from '../../../components/toasters/ToastrAction'
import DtoSandreCode from '../../../referencials/dto/DtoSandreCode'
import DtoInstallation from '../../../referencials/installations/dto/DtoInstallation'
import AgriAction from '../../../agri/actions/AgriAction'
import DtoSamplingPointDecla from '../../../agri/dto/exploitation/DtoSamplingPointDecla'
import DtoUsageCategory from '../../../referencials/dto/DtoUsageCategory'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import { hasValue, round } from '../../../../../utils/NumberUtil'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import DtoCMSEvent from '../../../cms/dto/DtoCMSEvent'
import { formatMilliers } from '../../../../../utils/StringUtil'
import { getSettingInt } from '../../../../../utils/FormUtils'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/dialog'

const RESUME_STEP = 0
const USAGE_STEP = 1
const CULTURE_VOLUME_STEP = 2
const IRRIG_PERIOD_STEP = 3
const GROUND_COMMENT_STEP = 4
const REQUEST_STEP = 5
const ADJUST_STEP = 6

class ModalUsage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: props.usage ? RESUME_STEP : USAGE_STEP,
            newUsage: !props.usage,
            errors: [],
            usage:
                props.usage ? new DtoUsageDeclaration(props.usage) :
                    new DtoUsageDeclaration({
                        idUsage: 7,
                        comment: '',
                        typeIrrigation: null,
                    }),
            usages: props.usages || [],
            openHelp: false,
            ajustEstim: false,
        }
    }

    previousStep = () => {
        const {
            usagesCategories,
            currentYear,
        } = this.props
        const { step, usage } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        if (currentYear && !usagesCategory.isCulture && step === 3) {
            this.setState({ step: step - 2, openHelp: false })
        } else {
            this.setState({ step: step - 1, openHelp: false })
        }
    }

    showVolumeInput = (usageCat) => {
        const { countersId } = this.props
        if (usageCat.volumeRealEntry) {
            if (!usageCat.showVolumeIfCounter || (usageCat.showVolumeIfCounter && countersId.length)) {
                return true
            }
            return false
        }
        return false
    }

    nextStep = () => {
        const {
            usagesCategories,
            installation,
            currentYear,
            surveys,
            declaration,
            usages,
            usage: usageInitial,
        } = this.props
        const { step, usage, newUsage } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        const enquete = surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
        if (this.checkStep(step, usage, usagesCategory.isCulture)) {
            if ((!newUsage && usageInitial.idUsage === usage.idUsage)
                || (usagesCategory.multipleEntry || usages.filter(({ idUsage }) => idUsage === usagesCategory.idUsage).length < 1)) {
                if (currentYear && !usagesCategory.isCulture && step === 1 && !this.showVolumeInput(usagesCategory)) {
                    this.setState({ step: step + 2 })
                } else if (step === 5 && !currentYear && usagesCategory.calculAuto) {
                    this.props
                        .calculVolumeUsage({
                            usage: {
                                ...usage,
                                area: round(usage.area, 4),
                                idInstallation: installation.id,
                                requestedYear: enquete.year,
                            },
                            uge: installation.subManagementCode || installation.managementCode,
                        })
                        .then((result) => {
                            const volume = isNaN(parseInt(result)) ? 0 : result
                            if (hasValue(usage.requestedYearVolume)) {
                                if (!usage.adjustedVolume && usage.requestedYearVolume === volume) {
                                    this.handleChange('requestedYearVolume', volume)
                                }
                                this.handleChange('adjustedVolume', usage.requestedYearVolume !== volume)
                            } else {
                                this.handleChange('requestedYearVolume', volume)
                                this.handleChange('adjustedVolume', false)
                            }
                            this.setState({ step: step + 1, volume, ajustEstim: false })
                        })
                } else {
                    this.setState({ step: step + 1 })
                }
                this.setState({ errors: [], openHelp: false })
            } else {
                ToastrAction.warning('Catégorie limitée à une saisie')
            }
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    checkStep = (step, usage, isCulture) => {
        const { cultures } = this.props
        const culture = cultures.find((c) => c.id === usage.idCulture)
        const idFamille = culture ? culture.idFamille : usage.idFamille
        const newErrors = []
        switch (step) {
            case USAGE_STEP:
                return true
            case CULTURE_VOLUME_STEP:
                if (isCulture) {
                    if (!idFamille) {
                        newErrors.push('idFamille')
                    }
                    if (!usage.idCulture) {
                        newErrors.push('idCulture')
                    }
                    if (newErrors.length) {
                        this.setState({ errors: newErrors })
                        return false
                    }
                    return true
                }
                if (!usage.requestedYearVolume || usage.requestedYearVolume <= 0) {
                    this.setState({ errors: ['requestedYearVolume'] })
                    return false
                }
                return true
            case IRRIG_PERIOD_STEP:
                if (isCulture) {
                    if (!usage.irrigationType) {
                        newErrors.push('irrigationType')
                    }
                    if (!usage.irrigationMode) {
                        newErrors.push('irrigationMode')
                    }
                    if (newErrors.length) {
                        this.setState({ errors: newErrors })
                        return false
                    }
                    return true
                }
                if (!usage.link_periodes.length) {
                    this.setState({ errors: ['link_periodes'] })
                    return false
                } else if (usage.link_periodes.find((p) => p.idPeriode === 2 && !p.idNbPeriode)) {
                    this.setState({ errors: ['link_periodes_checkboxes'] })
                    return false
                }
                return true
            case GROUND_COMMENT_STEP:
                if (!usage.area || usage.area <= 0) {
                    newErrors.push('area')
                }
                if (isNaN(parseInt(usage.groundType))) {
                    newErrors.push('groundType')
                }
                if (isNaN(parseInt(usage.groundDepth))) {
                    newErrors.push('groundDepth')
                }
                if (!usage.nbRotation) {
                    newErrors.push('nbRotation')
                }
                if (newErrors.length) {
                    this.setState({ errors: newErrors })
                    return false
                }
                return true
            case REQUEST_STEP:
                if (isNaN(parseInt(usage.requestPrelevement))) {
                    this.setState({ errors: ['requestPrelevement'] })
                    return false
                }
                return true
            default:
                return true
        }
    }

    handleChange = (key, value) => {
        const { usagesCategories } = this.props
        const { usage } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        const usageReset = usagesCategory.calculAuto ? {
            requestedYearVolume: null,
            lowWaterVolume: null,
            adjustedVolume: false,
        } : {}
        switch (key) {
            case 'requestedYearVolume': case 'adjustedVolume': case 'comment':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                    },
                    ajustEstim: false,
                })
                break
            case 'idUsage':
                this.setState({
                    usage: {
                        ...new DtoUsageDeclaration({}),
                        idProvisionalUsage: usage.idProvisionalUsage,
                        [key]: value,
                        ...usageReset,
                    },
                    ajustEstim: false,
                })
                break
            case 'idFamille':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        idCulture: null,
                        ...usageReset,
                        nbRotation: 1
                    },
                    ajustEstim: false,
                })
                break
            case 'idCulture':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        ...usageReset,
                        nbRotation: 1
                    },
                    ajustEstim: false,
                })
                break
            case 'irrigationType':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        irrigationMode: null,
                        ...usageReset,
                    },
                    ajustEstim: false,
                })
                break
            default:
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        ...usageReset,
                    },
                    ajustEstim: false,
                })
                break
        }
    }

    handleChangePeriode = (select, code, usage) => {
        const { point, declaration } = this.props
        let newUsagePeriodes = []
        if (select) {
            newUsagePeriodes.push({
                idInstallation: point.idInstallation,
                idSurvey: declaration.idSurvey,
                idUsage: usage.idProvisionalUsage || -1,
                idPeriode: code,
                idNbPeriode: null,
            })
        } else {
            if (usage.link_periodes.find((p) => p.idNbPeriode === code)) {
                newUsagePeriodes = [
                    ...usage.link_periodes,
                ]
                newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null && p.idNbPeriode !== code)
            } else {
                newUsagePeriodes = [
                    ...usage.link_periodes,
                    {
                        idInstallation: point.idInstallation,
                        idSurvey: declaration.idSurvey,
                        idUsage: usage.idProvisionalUsage || -1,
                        idPeriode: 2,
                        idNbPeriode: code,
                    }
                ]
                newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null)
            }
            if (!newUsagePeriodes.length) {
                newUsagePeriodes.push({
                    idInstallation: point.idInstallation,
                    idSurvey: declaration.idSurvey,
                    idUsage: usage.idProvisionalUsage || -1,
                    idPeriode: 2,
                    idNbPeriode: null,
                })
            }
        }
        this.handleChange('link_periodes', newUsagePeriodes)
    }

    getHeader = (usage) => {
        const { culturesFamilies, cultures, codesSandre, modesIrrigations, currentYear } = this.props
        const { step } = this.state

        const typesIrrig = codesSandre.filter((code) => code.field === 'IRRIGATION.CATEGORIES')
        const typesSols = codesSandre.filter((code) => code.field === 'USAGES.TYPES_SOLS')
        const profondeursSol = codesSandre.filter((code) => code.field === 'USAGES.PROFONDEUR_SOLS')
        const requestsPrelevement = codesSandre.filter((code) => code.field === 'USAGES.TYPES')

        const culture = cultures.find((c) => c.id === usage.idCulture)
        const culturesFamily = culturesFamilies.find((cF) => cF.id === culture.idFamille)
        const irrigationMode = modesIrrigations.find((iM) => iM.id === usage.irrigationMode)
        const irrigationType = typesIrrig.find((iT) => iT.code === usage.irrigationType)
        const groundType = typesSols.find((gT) => gT.code === usage.groundType)
        const groundDepth = profondeursSol.find((gD) => gD.code === usage.groundDepth)
        const prelevementRequest = requestsPrelevement.find(
            (pR) => pR.code === usage.requestPrelevement,
        )

        return (
            <b>
                <span>{`${i18n.agri} / ${i18n.irrigation}`}</span>
                <br />
                {culturesFamily && culture && (
                    <>
                        <span>
                            {culturesFamily.name} / {culture.name}
                        </span>
                        <br />
                    </>
                )}
                {irrigationType && irrigationMode && (
                    <>
                        <span>
                            {irrigationType.name} / {irrigationMode.name}
                        </span>
                        <br />
                    </>
                )}
                {hasValue(usage.area) && groundType && groundDepth && usage.nbRotation && (
                    <>
                        <span>
                            {`${usage.area} ha / ${groundType.name} / ${groundDepth.name} / ${usage.nbRotation} RC`}
                        </span>
                        <br />
                    </>
                )}
                {step === 5 && prelevementRequest && (
                    <>
                        <span>{prelevementRequest.name}</span>
                        <br />
                    </>
                )}
                {!currentYear && step === 0 && usage.requestedYearVolume && (
                    <p>
                        <b>{`${i18n.indicativeSimu} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                    </p>
                )}
            </b>
        )
    }

    getSelectedPeriod = (idPeriode, usagePeriodes) => {
        return !!usagePeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    getContent = (step, usage) => {
        const {
            culturesFamilies,
            cultures,
            codesSandre,
            modesIrrigations,
            usagesCategories,
            currentYear,
            applicationSettings,
        } = this.props
        const { errors, ajustEstim, volume } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        const culture = cultures.find((c) => c.id === usage.idCulture)
        const idFamille = culture ? culture.idFamille : usage.idFamille

        switch (step) {
            case RESUME_STEP:
                return (
                    <>
                        <h2 id='modal-title'>Usage</h2>
                        <p>
                            <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                        </p>
                        {(!currentYear || this.showVolumeInput(usagesCategory)) &&
                            <p>
                                <b>{`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3 (${i18n.etiage} : ${hasValue(usage.lowWaterVolume) ? usage.lowWaterVolume : i18n.unknown})`}</b>
                            </p>
                        }
                    </>
                )
            case USAGE_STEP:
                const agriUsagesCat = sortBy(usagesCategories.filter((uCat) => uCat.category === 1), 'order')
                const nonAgriUsagesCat = sortBy(usagesCategories.filter((uCat) => uCat.category === 2), 'order')
                return (
                    <RadioGroup
                        name='idUsage'
                        value={usage.idUsage}
                        onChange={(event) =>
                            this.handleChange('idUsage', parseInt(event.target.value))
                        }
                    >
                        <SubTitleCard bold left>
                            {i18n.agri}
                        </SubTitleCard>
                        {agriUsagesCat.map((uCat) => {
                            return (
                                <FormControlLabel
                                    value={uCat.idUsage}
                                    control={<Radio />}
                                    label={uCat.description}
                                />
                            )
                        })}
                        <SubTitleCard bold left>
                            {i18n.nonAgri}
                        </SubTitleCard>
                        {nonAgriUsagesCat.map((uCat) => {
                            return (
                                <FormControlLabel
                                    value={uCat.idUsage}
                                    control={<Radio />}
                                    label={uCat.description}
                                />
                            )
                        })}
                    </RadioGroup>
                )
            case CULTURE_VOLUME_STEP:
                if (usagesCategory.isCulture) {
                    return (
                        <>
                            <b>{usagesCategory.description}</b>
                            <br />
                            <div style={{ margin: '30px 0' }}>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.culturesFamily}</InputLabel>
                                    <Select
                                        id='familyCulture'
                                        label={i18n.culturesFamily}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'idFamille',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        value={idFamille}
                                        error={errors.find((e) => e === 'idFamille')}
                                    >
                                        {orderBy(culturesFamilies, 'name').map((cF) => {
                                            return (
                                                <MenuItem
                                                    value={cF.id}
                                                    style={{ maxWidth: '100%' }}
                                                >
                                                    {cF.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControlRow>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.culture}</InputLabel>
                                    <Select
                                        id='culture'
                                        label={i18n.culture}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'idCulture',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        value={usage.idCulture}
                                        error={errors.find((e) => e === 'idCulture')}
                                    >
                                        {orderBy(cultures.filter((c) => c.idFamille === idFamille && (c.status !== 2 || (usage.idCulture && usage.idCulture === c.id))), 'name')
                                            .map((c) => {
                                                return <MenuItem value={c.id}>{c.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControlRow>
                            </div>
                        </>
                    )
                }
                return (
                    <>
                        <p>
                            <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                        </p>
                        <InputRow
                            item
                            id='requestedYearVolume'
                            label={i18n.volume}
                            type='number'
                            value={usage.requestedYearVolume}
                            inputProps={{ min: 0 }}
                            onChange={(event) =>
                                this.handleChange(
                                    'requestedYearVolume',
                                    parseInt(event.target.value),
                                )
                            }
                            error={errors.find((e) => e === 'requestedYearVolume')}
                            variant='outlined'
                        />
                        <InputRow
                            item
                            id='lowWaterVolume'
                            label={i18n.lowWaterVolume}
                            type='number'
                            value={usage.lowWaterVolume}
                            inputProps={{ min: 0 }}
                            onChange={(event) =>
                                this.handleChange(
                                    'lowWaterVolume',
                                    parseInt(event.target.value),
                                )
                            }
                            variant='outlined'
                        />
                    </>
                )
            case IRRIG_PERIOD_STEP:
                if (usagesCategory.isCulture) {
                    const typesIrrig = codesSandre.filter((code) => code.field === 'IRRIGATION.CATEGORIES')
                    return (
                        <>
                            {this.getHeader(usage)}
                            <div style={{ margin: '30px 0' }}>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.typeIrrigation}</InputLabel>
                                    <Select
                                        id='irrigationType'
                                        label={i18n.typeIrrigation}
                                        value={usage.irrigationType}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'irrigationType',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        error={errors.find((e) => e === 'irrigationType')}
                                    >
                                        {orderBy(typesIrrig, 'name').map((type) => {
                                            return (
                                                <MenuItem
                                                    value={type.code}
                                                    style={{ maxWidth: '100%' }}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControlRow>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.modeIrrigation}</InputLabel>
                                    <Select
                                        id='irrigationMode'
                                        label={i18n.modeIrrigation}
                                        value={usage.irrigationMode}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'irrigationMode',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        error={errors.find((e) => e === 'irrigationMode')}
                                    >
                                        {orderBy(modesIrrigations.filter((mode) => mode.categoryId === usage.irrigationType), 'name')
                                            .map((mode) => {
                                                return (
                                                    <MenuItem value={mode.id}>{mode.name}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControlRow>
                            </div>
                        </>
                    )
                }
                const usagesPeriodes = codesSandre.filter((code) => code.field === 'USAGES.PERIODES')
                const usagesPeriodesDetails = codesSandre.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')
                const usageSaisonnal = usage.link_periodes.find((p) => p.idPeriode === 2)
                const usageAnnuel = usage.link_periodes.find((p) => p.idPeriode === 1)
                return (
                    <>
                        <p>
                            <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                        </p>
                        {!currentYear &&
                            <p>
                                <b>{`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                            </p>
                        }
                        <FormControlRow variant='outlined'>
                            <InputLabel>{i18n.usePeriod}</InputLabel>
                            <Select
                                id='usePeriod'
                                label={i18n.usePeriod}
                                value={usageSaisonnal ? 2 : usageAnnuel ? 1 : null}
                                onChange={(e) => this.handleChangePeriode(true, e.target.value, usage)}
                                error={errors.find((e) => e === 'link_periodes')}
                            >
                                {usagesPeriodes.map((p) => <MenuItem value={p.code}>{p.name}</MenuItem>)}
                            </Select>
                        </FormControlRow>
                        <FormGroup>
                            {usageSaisonnal && usagesPeriodesDetails.map((p) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={p.code}
                                            checked={this.getSelectedPeriod(p.code, usage.link_periodes)}
                                            onChange={(e) => this.handleChangePeriode(false, parseInt(e.target.value), usage)}
                                        />
                                    }
                                    label={p.name}
                                />
                            ))}
                            {errors.find((e) => e === 'link_periodes_checkboxes') && (
                                <FormHelperText style={{ color: 'red' }}>{i18n.noneOptionSelected}</FormHelperText>
                            )}
                        </FormGroup>
                    </>
                )
            case GROUND_COMMENT_STEP:
                if (usagesCategory.isCulture) {
                    const typesSols = codesSandre.filter((code) => code.field === 'USAGES.TYPES_SOLS')
                    const profondeursSol = codesSandre.filter((code) => code.field === 'USAGES.PROFONDEUR_SOLS')
                    const nbRotationsCultMax = getSettingInt(applicationSettings, 'nbRotationsCultMax') || 6
                    const optionRotationsCult = []
                    for (let i = 1; i <= nbRotationsCultMax; i++) {
                        optionRotationsCult.push(String(i))
                    }
                    return (
                        <>
                            {this.getHeader(usage)}
                            <div style={{ margin: '30px 0' }}>
                                <InputRow
                                    item
                                    id='area'
                                    label={i18n.surface}
                                    type='number'
                                    inputProps={{ min: 0 }}
                                    value={usage.area}
                                    onChange={(event) =>
                                        this.handleChange('area', event.target.value)
                                    }
                                    variant='outlined'
                                    error={errors.find((e) => e === 'area')}
                                />
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.typeSol}</InputLabel>
                                    <Select
                                        id='groundType'
                                        label={i18n.typeSol}
                                        value={usage.groundType}
                                        onChange={(event) =>
                                            this.handleChange('groundType', event.target.value)
                                        }
                                        error={errors.find((e) => e === 'groundType')}
                                    >
                                        {orderBy(typesSols, 'name').map((type) => {
                                            return (
                                                <MenuItem
                                                    value={type.code}
                                                    style={{ maxWidth: '100%' }}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControlRow>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.profondeurSol}</InputLabel>
                                    <Select
                                        id='groundDepth'
                                        label={i18n.profondeurSol}
                                        value={usage.groundDepth}
                                        onChange={(event) =>
                                            this.handleChange('groundDepth', event.target.value)
                                        }
                                        error={errors.find((e) => e === 'groundDepth')}
                                    >
                                        {orderBy(profondeursSol, 'name').map((type) => {
                                            return (
                                                <MenuItem
                                                    value={type.code}
                                                    style={{ maxWidth: '100%' }}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControlRow>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.nbRotationsCult}</InputLabel>
                                    <Select
                                        id='nbRotation'
                                        label={i18n.nbRotationsCult}
                                        defaultValue={1}
                                        value={usage.nbRotation}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'nbRotation',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        disabled={culture.limitCropRotations}
                                        error={errors.find((e) => e === 'nbRotation')}
                                    >
                                        {optionRotationsCult.map((v) => <MenuItem value={v}>{v}</MenuItem>)}
                                    </Select>
                                </FormControlRow>
                            </div>
                        </>
                    )
                }
                return (
                    <>
                        <div style={{ height: 'fit-content' }}>
                            <p>
                                <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                            </p>
                            {!currentYear &&
                                <p>
                                    <b>{`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                                </p>
                            }
                        </div>
                        <InputRow
                            item
                            id='comment'
                            type='text'
                            variant='outlined'
                            placeholder={usagesCategory.comment || 'Je laisse un commentaire si besoin.'}
                            value={usage.comment}
                            onChange={(event) => this.handleChange('comment', event.target.value)}
                            multiline
                            rows={10}
                            rowsMax={Infinity}
                            style={{ minHeight: '65%' }}
                            InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                        />
                    </>
                )
            case REQUEST_STEP:
                const requestsPrelevement = codesSandre.filter((code) => code.field === 'USAGES.TYPES')
                return (
                    <>
                        {this.getHeader(usage)}
                        <RadioGroup
                            name='requestPrelevement'
                            value={usage.requestPrelevement}
                            onChange={(event) =>
                                this.handleChange(
                                    'requestPrelevement',
                                    parseInt(event.target.value),
                                )
                            }
                            style={{ margin: '30px 0' }}
                            error={errors.find((e) => e === 'requestPrelevement')}
                        >
                            <SubTitleCard bold left>
                                {i18n.requestPrelevement}
                            </SubTitleCard>
                            {requestsPrelevement.map((type) => {
                                return (
                                    <FormControlLabel
                                        value={type.code}
                                        control={<Radio />}
                                        label={type.name}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </>
                )
            case ADJUST_STEP:
                return (
                    <>
                        <div style={{ height: 'fit-content' }}>
                            {this.getHeader(usage)}
                            {usagesCategory.calculAuto ? (
                                <>
                                    <p>
                                        {!currentYear ? (
                                            <>
                                                <b>{`${i18n.indicativeSimu} : ${formatMilliers((ajustEstim || usage.adjustedVolume) ? volume : usage.requestedYearVolume) || 0} m3`}</b>
                                                {!(ajustEstim || usage.adjustedVolume) ? (
                                                    <OrangeButton
                                                        variant='contained'
                                                        onClick={() => this.setState({ ajustEstim: true })}
                                                        margintop
                                                    >
                                                        {i18n.ajustEstim}
                                                    </OrangeButton>
                                                ) : (
                                                    <>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <MainButton
                                                                    variant='contained'
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            usage:
                                                                            {
                                                                                ...usage,
                                                                                requestedYearVolume: (usage.requestedYearVolume - Math.trunc(volume / 10)),
                                                                                adjustedVolume: (usage.requestedYearVolume - Math.trunc(volume / 10)) !== usage.requestedYearVolume,
                                                                            }
                                                                        })
                                                                    }}
                                                                    disabled={(usage.requestedYearVolume - Math.trunc(volume / 10)) < 0}
                                                                    margintop
                                                                >
                                                                    Enlever 10 %
                                                                </MainButton>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MainButton
                                                                    variant='contained'
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            usage:
                                                                            {
                                                                                ...usage,
                                                                                requestedYearVolume: (usage.requestedYearVolume + Math.trunc(volume / 10)),
                                                                                adjustedVolume: (usage.requestedYearVolume + Math.trunc(volume / 10)) !== usage.requestedYearVolume,
                                                                            }
                                                                        })
                                                                    }}
                                                                    margintop
                                                                >
                                                                    Ajouter 10 %
                                                                </MainButton>
                                                            </Grid>
                                                        </Grid>
                                                        <p style={{ color: 'red' }}>
                                                            <b>{`${i18n.ajustedSimu} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        ) : ''}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <InputRow
                                        item
                                        id='requestedYearVolume'
                                        label={i18n.volume}
                                        type='number'
                                        value={usage.requestedYearVolume}
                                        inputProps={{ min: 0 }}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'requestedYearVolume',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        error={errors.find((e) => e === 'requestedYearVolume')}
                                        variant='outlined'
                                    />
                                    <InputRow
                                        item
                                        id='lowWaterVolume'
                                        label={i18n.lowWaterVolume}
                                        type='number'
                                        value={usage.lowWaterVolume}
                                        inputProps={{ min: 0 }}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'lowWaterVolume',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        variant='outlined'
                                    />
                                </>
                            )}
                        </div>
                        <InputRow
                            item
                            id='comment'
                            type='text'
                            variant='outlined'
                            placeholder={!currentYear ? usagesCategory.comment : 'Je laisse un commentaire si besoin.'}
                            value={usage.comment}
                            onChange={(event) => this.handleChange('comment', event.target.value)}
                            multiline
                            rows={10}
                            rowsMax={Infinity}
                            InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                        />
                    </>
                )
            default:
                return ''
        }
    }

    onDelete = () => {
        const { usages, usage } = this.state
        const newUsages = usages.filter((u) => u.idProvisionalUsage !== usage.idProvisionalUsage)
        this.props.onDelete(newUsages)
    }

    onSave = () => {
        const { usages, usage } = this.state
        const { installation, exploitation, declaration, currentYear, surveys } = this.props
        const enquete = surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
        const formattedUsage = {
            ...usage,
            area: usage.idCulture ? round(usage.area, 4) : null,
            nbRotation: usage.idCulture ? usage.nbRotation : null,
            requestPrelevement: usage.idCulture ? usage.requestPrelevement : null,
            currentYear: usage.currentYear || currentYear,
            idExploitation: exploitation.idExploitation,
            idInstallation: installation.id,
            idSurvey: declaration.idSurvey,
            idProvisionalUsage: usage.idProvisionalUsage || null,
            requestedYear: enquete.year,
        }
        if (usage.idProvisionalUsage) {
            const newUsages = usages.filter(
                (u) => u.idProvisionalUsage !== usage.idProvisionalUsage,
            )
            this.props.onSave([
                ...newUsages,
                formattedUsage,
            ])
        } else {
            this.props.onSave([
                ...usages,
                formattedUsage,
            ])
        }
    }

    onClose = () => {
        this.props.onCancel()
    }

    toggleHelp = () => {
        const { openHelp } = this.state
        this.setState({ openHelp: !openHelp })
    }

    getContentHelp = (step, usage) => {
        const { cms, usagesCategories } = this.props
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        let cmsFind = {}
        switch (step) {
            case USAGE_STEP:
                cmsFind = cms.find((c) => c.id === 30)
                return cmsFind ? cmsFind.comment : ''
            case CULTURE_VOLUME_STEP:
                if (usagesCategory.isCulture) {
                    cmsFind = cms.find((c) => c.id === 31)
                    return cmsFind ? cmsFind.comment : ''
                }
                cmsFind = cms.find((c) => c.id === 36)
                return cmsFind ? cmsFind.comment : ''
            case IRRIG_PERIOD_STEP:
                if (usagesCategory.isCulture) {
                    cmsFind = cms.find((c) => c.id === 31)
                    return cmsFind ? cmsFind.comment : ''
                }
                cmsFind = cms.find((c) => c.id === 37)
                return cmsFind ? cmsFind.comment : ''
            case GROUND_COMMENT_STEP:
                if (usagesCategory.isCulture) {
                    cmsFind = cms.find((c) => c.id === 32)
                    return cmsFind ? cmsFind.comment : ''
                }
                cmsFind = cms.find((c) => c.id === 35)
                return cmsFind ? cmsFind.comment : ''
            case REQUEST_STEP:
                cmsFind = cms.find((c) => c.id === 33)
                return cmsFind ? cmsFind.comment : ''
            case ADJUST_STEP:
                cmsFind = cms.find((c) => c.id === 34)
                return cmsFind ? cmsFind.comment : ''
            default:
                return ''
        }
    }

    render() {
        const { step, usage, openHelp } = this.state
        const { open, usagesCategories } = this.props
        const usageCat = usagesCategories.find((uCat) => uCat.idUsage === usage.idUsage)

        return (
            <DialogMUI open={open}>
                <DialogTitleMUI><b>{i18n.newUse}</b></DialogTitleMUI>
                <DialogContentMUI>
                    {/* <Grid
                        container
                        className='overflowY'
                        direction='row'
                        alignItems='flex-start'
                        style={{
                            marginBottom: !step ? '155px' : '105px',
                            height: '-webkit-fill-available',
                            padding: '0',
                            display: 'block',
                        }}
                    >
                        <Grid item xs={12} style={{ padding: '10px 16px' }}> */}
                    {this.getContent(step, usage)}
                    {/* </Grid>
                    </Grid> */}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        {!step ? (
                            <>
                                <RedButton
                                    variant='contained'
                                    onClick={this.onDelete}
                                >
                                    {i18n.deleteUsage}
                                </RedButton>
                                <MainButton
                                    variant='contained'
                                    onClick={this.nextStep}
                                >
                                    {i18n.changeUsage}
                                </MainButton>
                                <MainButton
                                    reverse='true'
                                    variant='contained'
                                    onClick={this.onClose}
                                >
                                    {i18n.cancel}
                                </MainButton>
                            </>
                        ) : (
                            <>
                                <Grid item xs={4}>
                                    {step !== 1 && (
                                        <MainButton
                                            variant='contained'
                                            onClick={this.previousStep}
                                        >
                                            <Icon>keyboard_arrow_left</Icon>
                                            {i18n.previous}
                                        </MainButton>
                                    )}
                                </Grid>
                                <HelpTooltip
                                    title={(<div dangerouslySetInnerHTML={{ __html: this.getContentHelp(step, usage) }} />)}
                                    open={openHelp}
                                    placement='top'
                                    arrow
                                    disableHoverListener
                                >
                                    <Grid item xs={3}>
                                        <MainButton variant='contained' onClick={this.toggleHelp}>
                                            <Icon>help</Icon>
                                        </MainButton>
                                    </Grid>
                                </HelpTooltip>
                                <Grid item xs={4}>
                                    {(usageCat.isCulture && step !== 6) ||
                                        (!usageCat.isCulture && step !== 4) ? (
                                        <MainButton
                                            variant='contained'
                                            onClick={this.nextStep}
                                        >
                                            {i18n.next}
                                            <Icon>keyboard_arrow_right</Icon>
                                        </MainButton>
                                    ) : (
                                        <MainButton
                                            variant='contained'
                                            onClick={this.onSave}
                                        >
                                            {i18n.validate}
                                        </MainButton>
                                    )}
                                </Grid>
                                <MainButton
                                    variant='contained'
                                    reverse
                                    onClick={this.onClose}
                                    marginTop
                                >
                                    {i18n.cancel}
                                </MainButton>
                            </>
                        )}
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalUsage.propTypes = {
    usage: PropTypes.instanceOf(PropTypes.object),
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageDeclaration)),
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    calculVolumeUsage: PropTypes.func,
    open: PropTypes.bool,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    modesIrrigations: PropTypes.arrayOf(PropTypes.instanceOf()),
    usagesCategories: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageCategory)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    currentYear: PropTypes.bool,
    surveys: PropTypes.arrayOf(PropTypes.object),
    countersId: PropTypes.arrayOf(PropTypes.number),
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        codesSandre: store.ReferencialReducer.codesSandre,
        cultures: store.ReferencialReducer.cultures,
        culturesFamilies: store.ReferencialReducer.culturesFamilies,
        usagesCategories: store.ReferencialReducer.usagesCategories,
        modesIrrigations: store.AgriReducer.modesIrrigations,
        installation: store.InstallationsReducer.installation,
        installations: store.InstallationsReducer.installations,
        cms: store.CmsReducer.cms,
        surveys: store.AgriReducer.surveys,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    calculVolumeUsage: AgriAction.calculVolumeUsage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUsage)
