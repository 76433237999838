import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { AppBar, Dialog, Grid, Icon, LinearProgress, Tabs } from '@mui/material'
import { orderBy, compact } from 'lodash'
import { TabStyle } from '../components/styled/tabs'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoDeclarationWithStats from '../agri/dto/enquete/DtoDeclarationWithStats'
import DtoEnquete from '../agri/dto/enquete/DtoEnquete'
import { formatMilliers } from '../../../utils/StringUtil'
import { hasValue } from '../../../utils/NumberUtil'
import DtoUser from '../account/dto/DtoUser'
import EditableCard from '../components/cards/EditableCard'
import { getFileNatureLabel, getTypeSandreCode } from '../../../utils/FileUtils'
import { getSandreList } from '../../../utils/StoreUtils'
import DtoFile from '../referencials/documents/dto/DtoFile'
import DtoPicture from '../referencials/documents/dto/DtoPicture'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import CardFile from './CardFile'
import { DialogContentMUI, DialogTitleMUI } from '../components/styled/dialog'
import { MainButton } from '../components/styled/buttons'

const PANEL_DECLARATIONS = 1
const PANEL_DOCUMENTS = 2

class MyDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            panel: PANEL_DECLARATIONS,
            openModalExport: false,
            idDeclaration: null,
            year: null,
            title: null,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        this.props.setTitle([
            {
                title: i18n.myDocuments,
                href: 'documents',
            },
        ])
        if (exploitation.idExploitation) {
            this.getDeclarations(exploitation)
        } else {
            this.props.fetchExploitation().then(() => {
                const { exploitation: myExploitation } = this.props
                this.getDeclarations(myExploitation)
            })
        }
    }

    getDeclarations = (exploitation) => {
        this.props.fetchDeclarationsByExploitationId(exploitation.idExploitation).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    showDeclarationItem = (declarationsExploitation) => {
        const { surveys, accountUser } = this.props
        return compact(orderBy(declarationsExploitation, 'idSurvey', 'desc').map((d) => {
            const surveyDeclaration = surveys.find((s) => s.idSurvey === d.idSurvey) || {}
            if (surveyDeclaration.statusCode != 2 || accountUser.isAdmin === '1') {
                const volumes = d.link_volumes.length ? d.link_volumes : []
                const allocatedVolumeFiltered = volumes.filter((v) => hasValue(v.attributedVolume)).map((v) => v.attributedVolume)
                const allocatedVolume = allocatedVolumeFiltered.length ? Math.round(allocatedVolumeFiltered.reduce((a, b) => a + b)) : null
                const authorizedVolumeFiltered = volumes.filter((v) => hasValue(v.authorizedVolume)).map((v) => v.authorizedVolume)
                const authorizedVolume = authorizedVolumeFiltered.length ? Math.round(authorizedVolumeFiltered.reduce((a, b) => a + b)) : null
                const title = `${i18n.declaration} ${surveyDeclaration.year}`
                return (
                    <Grid key={d.idDeclaration} item xs={12} md={4}>
                        <EditableCard
                            title={title}
                            hideIcon
                            otherIcon='file_download'
                            onClickOtherIcon={() => this.setState({ openModalExport: true, title, idDeclaration: d.idDeclaration, year: surveyDeclaration.year })}
                        >
                            <Grid container>
                                <Grid item xs={6} className='textRight'>{`${i18n.pointsDeclared} : `}</Grid>
                                <Grid item xs={6} className='textRight valueLabel'><b>{d.nbPts}</b></Grid>
                                <Grid item xs={6} className='textRight'>{`${i18n.requestedVolume} : `}</Grid>
                                <Grid item xs={6} className='textRight valueLabel'><b>{hasValue(d.prevVolume) ? `${formatMilliers(d.prevVolume)} m3` : i18n.unknown}</b></Grid>
                                <Grid item xs={6} className='textRight'>{`${i18n.allocatedVolume} : `}</Grid>
                                <Grid item xs={6} className='textRight valueLabel'><b>{hasValue(allocatedVolume) ? `${formatMilliers(allocatedVolume)} m3` : i18n.unknown}</b></Grid>
                                <Grid item xs={6} className='textRight'>{`${i18n.authorizedVolume} : `}</Grid>
                                <Grid item xs={6} className='textRight valueLabel'><b>{hasValue(authorizedVolume) ? `${formatMilliers(authorizedVolume)} m3` : i18n.unknown}</b></Grid>
                            </Grid>
                        </EditableCard>
                    </Grid>
                )
            }
            return null
        }))
    }

    getPanel = (panel) => {
        switch (panel) {
            case PANEL_DECLARATIONS: default:
                const { declarationsExploitation } = this.props
                return (
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        className='padding-top-1'
                    >
                        {declarationsExploitation.length ? this.showDeclarationItem(declarationsExploitation) : (
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>
                                {i18n.noDocument}
                            </Grid>
                        )}
                    </Grid>
                )
            case PANEL_DOCUMENTS:
                const { pictures, files, codesSandre } = this.props
                const data = [...pictures, ...files].filter((d) => d.active === '1').map(fp => {
                    return { ...fp, type: getFileNatureLabel(fp.name, getSandreList(codesSandre, getTypeSandreCode(fp.fileType))) }
                })
                return (
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        className='padding-2'
                        spacing={1}
                    >
                        {data.length ? (
                            <>
                                {data.map((d) => (
                                    <Grid item xs={12} md={4}>
                                        <CardFile file={d} />
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>
                                {i18n.noDocument}
                            </Grid>
                        )}
                    </Grid>
                )
        }
    }

    getModalExport = () => {
        const { openModalExport, title, idDeclaration, year } = this.state
        const { exploitation } = this.props
        return (
            <Dialog open={openModalExport}>
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <b>{title}</b>
                    <Icon className='clickable' onClick={() => this.setState({ openModalExport: false, title: null, idDeclaration: null, year: null })}>close</Icon>
                </DialogTitleMUI>
                <DialogContentMUI>
                    <MainButton
                        onClick={() => this.props.exportDeclarationFull(idDeclaration)}
                    >
                        {'Exporter le PDF de synthèse'}
                        <Icon>file_download</Icon>
                    </MainButton>
                    <MainButton
                        onClick={() => this.props.getNotifEdition(exploitation.idExploitation, year)}
                    >
                        {'Exporter le PDF d\'attribution'}
                        <Icon>file_download</Icon>
                    </MainButton>
                </DialogContentMUI>
            </Dialog>
        )
    }

    render() {
        const { dataLoaded, panel, openModalExport } = this.state
        return (
            <>
                {dataLoaded ? (
                    <>
                        <AppBar position='static' style={{ width: '100vw', boxShadow: 'none' }}>
                            <Tabs
                                value={panel}
                                onChange={(e, v) => this.setState({ panel: v })}
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none',
                                    },
                                }}
                                style={{ backgroundColor: 'white' }}
                            >
                                <TabStyle icon={<Icon>description</Icon>} value={PANEL_DECLARATIONS} width='50vw' />
                                <TabStyle icon={<Icon>folder</Icon>} value={PANEL_DOCUMENTS} width='50vw' />
                            </Tabs>
                        </AppBar>
                        <Grid
                            container
                            direction='column'
                            justifyContent='space-around'
                        >
                            {this.getPanel(panel)}
                        </Grid>
                    </>
                ) : (
                    <LinearProgress />
                )}
                {openModalExport && this.getModalExport()}
            </>
        )
    }
}

MyDocuments.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    setTitle: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchDeclarationsByExploitationId: PropTypes.func,
    exportDeclarationFull: PropTypes.func,
    getNotifEdition: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declarationsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationWithStats)),
    surveys: PropTypes.arrayOf(PropTypes.instanceOf(DtoEnquete)),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    codesSandre: PropTypes.arrayOf(DtoSandreCode),
    push: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        applicationSettings: store.HomeReducer.applicationSettings,
        exploitation: store.AgriReducer.exploitation,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
        surveys: store.AgriReducer.surveys,
        codesSandre: store.ReferencialReducer.codesSandre,
        pictures: store.FileReducer.pictures,
        files: store.FileReducer.files,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationsByExploitationId: AgriAction.fetchDeclarationsByExploitationId,
    exportDeclarationFull: AgriAction.exportDeclarationFull,
    getNotifEdition: AgriAction.getNotifEdition,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDocuments)
