/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Modal, Card, Grid, Divider, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import { MainButton } from '../../../components/styled/buttons'


const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
    },
})

class ModalRemovePoint extends Component {
    constructor(props) {
        super(props)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    render() {
        const { classes, open, pointStatus } = this.props
        return (
            <Modal
                open={open}
                className={classes.paper}
                style={{ bottom: 'inherit', zIndex: '1100' }}
            >
                <Card className='overflowY' style={{ width: '100%', height: '100%' }}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='space-around'
                        alignItems='center'
                        style={{ height: '100%', padding: '10px' }}
                    >
                        <Grid item style={{ width: '100%' }}>
                            <MainButton
                                variant='contained'
                                onClick={this.props.onDeclareUnused}
                            >
                                <Icon style={{ marginRight: 8 }}>invert_colors_off</Icon>
                                {i18n.declareUnused}
                            </MainButton>
                            <p style={{ padding: '0 10px', textAlign: 'center' }}>Le point de prélèvement sera déclaré comme non utilisé pour la présente déclaration, mais il reste bien rattaché à votre dossier.<br />Si des usages ont été saisis, ils seront supprimés.</p>
                        </Grid>
                        <Divider className='margin-bottom-1' style={{ width: '100%' }} />
                        <Grid item style={{ width: '100%' }}>
                            <MainButton
                                variant='contained'
                                onClick={pointStatus === 3 ? this.props.onDeclareUsed : this.props.onDeclareAdjourn}
                            >
                                <Icon style={{ marginRight: 8 }}>{pointStatus === 3 ? 'invert_colors' : 'access_time'}</Icon>
                                {pointStatus === 3 ? i18n.declareUsed : i18n.declareAdjourn}
                            </MainButton>
                            <p style={{ padding: '0 10px', whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                                {pointStatus === 3 ? 'Le point de prélèvement sera déclaré comme utilisé pour la présente déclaration.'
                                    : ('Le point de prélèvement restera utilisé pour l\'année en cours, et passera en non utilisé pour l\'année prochaine. Il reste bien rattaché à votre dossier.\nSi des usages prévisionnels ont été saisis, ils seront supprimés.')}
                            </p>
                        </Grid>
                        <Divider className='margin-bottom-1' style={{ width: '100%' }} />
                        <Grid item style={{ width: '100%' }}>
                            <MainButton
                                variant='contained'
                                onClick={pointStatus === 2 ? this.props.onDeclareUsed : this.props.onRemovePoint}
                            >
                                <Icon style={{ marginRight: 8 }}>{pointStatus === 2 ? 'invert_colors' : 'delete'}</Icon>
                                {pointStatus === 2 ? i18n.declareUsed : i18n.closeSamplingPoint}
                            </MainButton>
                            <p style={{ padding: '0 10px', textAlign: 'center' }}>
                                {pointStatus === 2 ? 'Le point de prélèvement sera déclaré comme utilisé pour la présente déclaration.'
                                    : 'Le point de prélèvement sera clôturé dans votre dossier et ne sera donc plus accessible via votre compte.'}
                            </p>
                        </Grid>
                        <Divider className='margin-bottom-1' style={{ width: '100%' }} />
                        <MainButton
                            variant='contained'
                            reverse
                            onClick={this.onCancel}
                        >
                            {i18n.cancel}
                        </MainButton>
                    </Grid>
                </Card>
            </Modal>
        )
    }
}

ModalRemovePoint.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.object),
    pointStatus: PropTypes.number,
    onDeclareUsed: PropTypes.func,
    onDeclareUnused: PropTypes.func,
    onDeclareAdjourn: PropTypes.func,
    onRemovePoint: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    push: PropTypes.func,
}

export default withStyles(styles)(ModalRemovePoint)