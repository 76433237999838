import React from 'react'
import Tab from '@mui/material/Tab'
import { withStyles } from '@mui/styles'

export const TabStyle = withStyles((theme) => ({
    root: {
        width: (props) => (props.width || 'auto'),
        maxWidth: 'none',
        padding: '1rem 0',
        textTransform: 'none',
        backgroundColor: 'lightgrey',
        color: '#484848',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.2rem',
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Roboto'].join(','),
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            backgroundColor: 'AFABAB',
            color: 'white',
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    tab: {
        padding: '0.5rem',
        fontFamily: 'Open Sans',
        fontSize: '2rem',
        backgroundColor: 'grey',
        color: 'black',
        '&:hover': {
            opacity: 1,
        },
    },
    selected: {},
}))((props) => <Tab {...props} />)
