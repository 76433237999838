/* eslint-disable camelcase */
export default class DtoEnquete {
    constructor(object) {
        this.idSurvey = object.idSurvey // Long,
        this.year = object.year // Option[Int],
        this.statusCode = object.statusCode // Option[Int],
        this.startDate = object.startDate // Option[DateTime],
        this.comment = object.comment // Option[String],
        this.endDate = object.endDate // Option[DateTime],
        this.updateDate = object.updateDate // Option[DateTime],
        this.updateLogin = object.updateLogin // Option[String],
        this.cmsId = object.cmsId // Option[Int]
        this.adminEndDate = object.adminEndDate // Option[DateTime]
        this.name = object.name // Option[String],
        this.contributorId = object.contributorId // Option[Long],
        this.ddtLimitDate = object.ddtLimitDate // Option[DateTime],
        this.agencyLimitDate = object.agencyLimitDate // Option[DateTime],
        this.surveyType = object.surveyType // Option[Int],

        this.link_declarations = object.link_declarations ? object.link_declarations : [] // Option[Seq[Declaration]] = None
        this.link_codifications = object.link_codifications ? object.link_codifications : [] // Option[Seq[ExploitationCodification]] = None
        this.link_params = object.link_params ? object.link_params : [] // Option[Seq[SurveyParam]] = None
    }
}
