import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, Grid, Icon, Typography } from '@mui/material'
import { useMemo } from 'react'
import { getDocumentTypeIcon, getFileExtension, getFileName, getFileNatureLabel } from '../../../utils/FileUtils'
import { getDate } from '../../../utils/DateUtil'
import { SANDRE } from '../referencials/constants/ReferencialConstants'
import { DOCUMENT, PICTURE } from '../referencials/documents/constants/FileConstants'
import DtoFile from '../referencials/documents/dto/DtoFile'

const CardFile = ({
    file = {},
    fileType = PICTURE,
}) => {
    const {
        exploitation,
        codesSandre,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)
    const fileExtension = getFileExtension(file.name)
    const FILE_TYPE = {
        picture: {
            sandreCode: SANDRE.TYPE_PHOTO,
            fileTypeName: i18n.pictureType,
            name: PICTURE,
            content: <img src={file.url} style={{ objectFit: 'cover', maxWidth: '100%', height: '100%', width: 'auto' }} />,
            textAlign: 'left',
        },
        document: {
            sandreCode: SANDRE.TYPE_DOCUMENT,
            fileTypeName: i18n.documentType,
            name: DOCUMENT,
            content: <img src={getDocumentTypeIcon(fileExtension)} style={{ width: '50%', height: '100%' }} />,
            textAlign: 'center',
        },
    }
    const fileTypeConstant = FILE_TYPE[file.fileType || PICTURE]
    const fileNatures = useMemo(() => codesSandre.filter(c => c.field === fileTypeConstant.sandreCode))
    const displayName = file.isAllDataName && exploitation.codification === file.stationCode ? file.shortName : getFileName(file.name)
    const fileNature = getFileNatureLabel(file.name, fileNatures)
    return (
        <a className='valign-wrapper' href={file.url} target='_blank' data-fancybox={fileType == PICTURE ? 'images' : undefined} data-caption={displayName} style={{ width: '100%', textDecoration: 'none', position: 'relative' }}>
            <Card className='row valign-wrapper' style={{ height: 130, border: 'solid 1px grey', borderRadius: 15 }}>
                <Grid container direction='row' style={{ height: 130 }}>
                    <Grid item xs={4} className='padding-2' style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        {fileTypeConstant.content}
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            style={{
                                fontWeight: 'bold',
                                paddingTop: '5px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            variant='subtitle1'
                            color='text.secondary'
                            component='div'
                        >
                            {displayName}
                            <Icon className='padding-right-2'>file_download</Icon>
                        </Typography>
                        <Typography variant='subtitle1' color='text.secondary' component='div'>
                            {i18n.type} : {fileExtension}
                        </Typography>
                        <Typography variant='subtitle1' color='text.secondary' component='div'>
                            {i18n.nature} : {fileNature}
                        </Typography>
                        <Typography variant='subtitle1' color='text.secondary' component='div'>
                            {i18n.date} : {getDate(parseInt(file.date))}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </a>
    )
}

CardFile.propTypes = {
    file: PropTypes.instanceOf(DtoFile),
    name: PropTypes.string,
    content: PropTypes.string,
    onClickDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    editable: PropTypes.bool,
    fileType: PropTypes.string,
}

export default CardFile
