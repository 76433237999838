/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import {
    Card,
    Grid,
    Icon,
    Divider,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { groupBy, orderBy } from 'lodash'
import { MainButton } from '../components/styled/buttons'
import { InputRow } from '../components/styled/inputs'
import DtoSamplingPointDecla from '../agri/dto/exploitation/DtoSamplingPointDecla'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { HelpTooltip } from '../components/styled/texts'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import ToastrAction from '../components/toasters/ToastrAction'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import DtoVariousMatType from '../referencials/installations/dto/DtoVariousMatType'
import DtoMatChronicle from '../referencials/installations/dto/DtoMatChronicle'
import { formatDate, getDate } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
    },
    card: {
        padding: '8px',
        height: '100%',
    },
    selected: {
        padding: '8px',
        backgroundColor: 'green',
        color: 'white',
        height: '100%',
    },
    item: {
        padding: '4px !important'
    }
})

const STEP_MATERIEL = 0
const STEP_TYPE = 1
const STEP_CHRONICLE = 2

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

class ModalConso extends Component {
    constructor(props) {
        super(props)
        const allMaterialsIds = [...(props.pumpsIds || []), ...(props.countersIds || [])]
        this.state = {
            chronicles: props.chronicles || [],
            consoType: props.consoType,
            step: props.selectedMaterial ? STEP_CHRONICLE : STEP_MATERIEL,
            errors: [],
            openHelp: false,
            selectedMaterial: props.selectedMaterial || (allMaterialsIds.length === 1 ? allMaterialsIds[0] : null),
            measureDate: null,
            endDate: null,
            value: null,
        }
    }

    previousStep = () => {
        const { step } = this.state
        this.setState({ step: step - 1, openHelp: false })
    }

    nextStep = () => {
        const { step } = this.state
        if (this.checkStep(step)) {
            this.setState({ step: step + 1, openHelp: false })
        }
    }

    checkStep = (step) => {
        const { consoType, measureDate, endDate, value, selectedMaterial } = this.state
        const newErrors = []
        switch (step) {
            case STEP_MATERIEL:
                if (!selectedMaterial) {
                    ToastrAction.error('Veuillez sélectionner un équipement')
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            case STEP_TYPE:
                if (!hasValue(consoType)) {
                    ToastrAction.error('Veuillez sélectionner un type de saisie')
                    return false
                }
                if (consoType === TYPE_ESTIM) {
                    ToastrAction.warning('Attention, estimations de m3 à saisir sur les matériels EP-XXXX (pompes) uniquement svp. \nSaisies sur les matériels DC-XXXX (compteurs) ne concernant que les relevés d\'index des compteurs d\'eau (volumétriques).')
                }
                this.setState({ errors: newErrors })
                return true
            case STEP_CHRONICLE:
                if (!measureDate) {
                    newErrors.push('measureDate')
                }
                if (isNaN(parseInt(value))) {
                    newErrors.push('value')
                }
                if (consoType === 1 && !endDate) {
                    newErrors.push('endDate')
                }
                if (newErrors.length) {
                    this.setState({ errors: newErrors })
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            default:
                this.setState({ errors: newErrors })
                return true
        }
    }

    setSelectedMat = (materiel) => {
        this.setState({
            selectedMaterial: materiel.id,
            consoType: materiel.materielType === 10 ? TYPE_INDEX : TYPE_ESTIM,
            measureDate: null,
            endDate: null,
            value: null,
        })
    }

    getPumpCard = (pompeInfos) => {
        const { codesSandre, classes, variousMatTypes } = this.props
        const { selectedMaterial } = this.state
        const pumpNature = variousMatTypes.find((t) => t.materielType === pompeInfos.materielType)
        const pumpCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === pompeInfos.mobilityCode)
        const pumpType = codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === pompeInfos.pumpType)
        return (
            <Grid item xs={12} md={3} className='clickable' style={{ margin: '5px 0' }} onClick={() => this.setSelectedMat(pompeInfos)}>
                <Card className={selectedMaterial === pompeInfos.id ? classes.selected : classes.card}>
                    <Grid container>
                        <Grid item xs={12}>
                            <span><b>{i18n.id} : {pompeInfos.reference || ''}</b></span>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <span><b>{i18n.name} : {pompeInfos.name || ''}</b></span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.nature} : </b><span>{pumpNature ? pumpNature.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.categ} : </b><span>{pumpCategory ? pumpCategory.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.type} : </b><span>{pumpType ? pumpType.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.debitMax} : </b><span>{pompeInfos.maxFlow ? `${pompeInfos.maxFlow} m3/h` : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.debitFonctionnement} : </b><span>{pompeInfos.operatingFlow ? `${pompeInfos.operatingFlow} m3/h` : ''}</span>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getPumps = () => {
        const {
            variousMateriels,
            variousMatSituations,
            pumpsIds
        } = this.props
        return pumpsIds.map((pumpId) => {
            const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === pumpId)
            const pompeMat = variousMateriels.find((m) => m.id === pumpId) || {}
            const pompe = pompeMat.pump || {}
            const pompeInfos = {
                ...pompeSituation,
                ...pompeMat,
                ...pompe,
            }
            return this.getPumpCard(pompeInfos)
        })
    }

    getCounterCard = (compteurInfos) => {
        const { classes, variousMatTypes } = this.props
        const { selectedMaterial } = this.state
        const counterType = variousMatTypes.find((t) => t.materielType === compteurInfos.materielType)
        return (
            <Grid item xs={12} md={3} className='clickable' style={{ margin: '5px 0' }} onClick={() => this.setSelectedMat(compteurInfos)}>
                <Card className={selectedMaterial === compteurInfos.id ? classes.selected : classes.card}>
                    <Grid container>
                        <Grid item xs={12}>
                            <span><b>{i18n.id} : {compteurInfos.reference || ''}</b></span>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <span><b>{i18n.name} : {compteurInfos.name || ''}</b></span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.type} : </b><span>{counterType ? counterType.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.coefLecture} : </b><span>{compteurInfos.readingCoefficient || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.marque} : </b><span>{compteurInfos.brand || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.installationDate} : </b><span>{getDate(compteurInfos.installationDate) || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>{i18n.revisionDate} : </b><span>{getDate(compteurInfos.revisionDate) || ''}</span>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getCounters = () => {
        const {
            variousMateriels,
            countersIds,
        } = this.props
        return countersIds.map((counterId) => {
            const matFind = variousMateriels.find((c) => c.id === counterId) || {}
            const counter = matFind.counter || {}
            const compteurInfos = {
                ...matFind,
                ...counter,
            }
            return this.getCounterCard(compteurInfos)
        })
    }

    handleChange = (key, value) => {
        if (key === 'consoType') {
            this.setState({ measureDate: null, endDate: null, value: null })
        }
        this.setState({ [key]: value })
    }

    getChronicles = () => {
        const { classes } = this.props
        const { chronicles, consoType, selectedMaterial } = this.state
        const filteredChronicles = chronicles.filter((c) => c.idMat === selectedMaterial && c.mode !== 'd' && c.measureType === consoType)
        return (
            <Grid
                container
                direction='row'
                justifyContent='center'
                style={{ marginTop: '16px' }}
            >
                {orderBy(filteredChronicles, ['measureDate', 'endDate'], 'desc').map((chronicle) => {
                    return (
                        <>
                            <Grid item className={classes.item} xs={1}>
                                <Icon className='clickable' style={{ color: 'red' }} onClick={() => this.removeChronicle(chronicle.idChronicle)}>delete</Icon>
                            </Grid>
                            <Grid item className={classes.item} xs={4}>
                                {formatDate(chronicle.measureDate)}
                            </Grid>
                            <Grid item className={classes.item} xs={4}>
                                {chronicle.endDate ? formatDate(chronicle.endDate) : ''}
                            </Grid>
                            <Grid item className={classes.item} xs={3}>
                                {`${formatMilliers(chronicle.value) || 0}${consoType === TYPE_ESTIM ? ' m3' : ''}`}
                            </Grid>
                            <Grid item className={classes.item} xs={12}>
                                <Divider />
                            </Grid>
                        </>
                    )
                })}
            </Grid>
        )
    }

    removeChronicle = (idChronicle) => {
        const { chronicles } = this.state
        const newChronicles = [
            ...chronicles.filter((c) => c.idChronicle !== idChronicle),
            {
                ...chronicles.find((c) => c.idChronicle === idChronicle),
                mode: 'd',
            }
        ]
        this.setState({ chronicles: newChronicles })
    }

    addChronicle = () => {
        const {
            measureDate,
            endDate,
            value,
            chronicles,
            consoType,
            selectedMaterial,
        } = this.state
        if (this.checkStep(2)) {
            const id = chronicles.length ? Math.max(...chronicles.map(c => c.idChronicle)) + 1 : 0
            this.setState({
                chronicles: [
                    ...chronicles,
                    {
                        matType: 'divers',
                        idMat: selectedMaterial,
                        measureType: consoType,
                        measureDate: new Date(measureDate).getTime(),
                        value: value,
                        endDate: consoType === TYPE_ESTIM ? new Date(endDate).getTime() : null,
                        idChronicle: id,
                        new: true,
                    }
                ],
                measureDate: consoType === TYPE_ESTIM ? endDate : '',
                value: '',
                endDate: '',
            })
        }
    }

    getContent = (step) => {
        const { variousMateriels, classes } = this.props
        const { errors, consoType, measureDate, endDate, value, selectedMaterial } = this.state
        const matFind = variousMateriels.find((mat) => mat.id === selectedMaterial) || {}
        switch (step) {
            case STEP_MATERIEL:
                return (
                    <>
                        <h3>
                            {i18n.selectMaterial}
                        </h3>
                        <Grid container spacing={1} alignItems='stretch'>
                            {[...this.getPumps(), ...this.getCounters()]}
                        </Grid>
                    </>
                )
            case STEP_TYPE:
                return (
                    <>
                        <Grid container spacing={1} alignItems='stretch'>
                            {matFind.materielType !== 10 && (
                                <Grid item xs={12} md={3} className='clickable' style={{ margin: '5px 0' }} onClick={() => this.handleChange('consoType', TYPE_ESTIM)}>
                                    <Card className={consoType === TYPE_ESTIM ? classes.selected : classes.card} style={{ display: 'flex', alignItems: 'center' }}>
                                        Saisir une estimation en m3 (pas de moyen de comptage)
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12} md={3} className='clickable' style={{ margin: '5px 0' }} onClick={() => this.handleChange('consoType', TYPE_INDEX)}>
                                <Card className={consoType === TYPE_INDEX ? classes.selected : classes.card} style={{ display: 'flex', alignItems: 'center' }}>
                                    Saisir un index (compteur d'eau)
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )
            case STEP_CHRONICLE:
                return (
                    <>
                        <h3>
                            {consoType === TYPE_ESTIM ? i18n.seizureEstim : i18n.seizureIndex}<br />
                            {matFind.reference}<br />
                            {consoType === TYPE_INDEX ? `${i18n.coefLecture} : ${(matFind.counter || {}).readingCoefficient}` : ''}
                        </h3>
                        <InputRow
                            id='startDate'
                            label={consoType === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                            type='date'
                            required
                            variant='outlined'
                            value={measureDate}
                            inputProps={{
                                max: endDate || '9999-12-31'
                            }}
                            onChange={(e) => this.handleChange('measureDate', e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={errors.find((e) => e === 'measureDate')}
                        />
                        {consoType === TYPE_ESTIM && (
                            <InputRow
                                id='endDate'
                                label={i18n.endDate}
                                type='date'
                                inputProps={{
                                    min: measureDate,
                                    max: '9999-12-31'
                                }}
                                required
                                variant='outlined'
                                value={endDate}
                                onChange={(e) => this.handleChange('endDate', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={errors.find((e) => e === 'endDate')}
                            />
                        )}
                        <InputRow
                            id='value'
                            label={consoType === 1 ? i18n.estim : i18n.index}
                            type='number'
                            value={value}
                            onChange={(e) => this.handleChange('value', parseInt(e.target.value))}
                            variant='outlined'
                            required
                            error={errors.find((e) => e === 'value')}
                        />
                        <MainButton variant='contained' onClick={() => this.addChronicle()}>
                            {i18n.add}
                        </MainButton>
                        {this.getChronicles()}
                    </>
                )
            default:
                return ''
        }
    }

    formatListChronicles = (chronicles) => {
        return chronicles.map((c) => {
            if (c.new) {
                return {
                    ...c,
                    idChronicle: null,
                }
            }
            return c
        })
    }

    onSave = () => {
        const { chronicles } = this.state
        const groupedChronicles = groupBy(chronicles, 'idMat')
        this.props.onSave(this.formatListChronicles(chronicles))
        this.setState({
            chronicles: [],
            measureDate: '',
            value: '',
            endDate: '',
        })
        if (Object.keys(groupedChronicles).find((idMat) => groupedChronicles[idMat].filter((c) => c.measureType === 2 && c.mode !== 'd').length === 1)) {
            ToastrAction.warning('L\'un des matériels n\'a qu\'un seul index de saisie. Minimum 2 index doivent être renseignés pour pouvoir calculer votre consommation', null, 10000)
        }
    }

    // onSave = () => {
    //     const { chronicles, consoType } = this.state
    //     if (consoType === TYPE_INDEX) {
    //         // const filteredChronicles = chronicles.filter((c) => c.idMat === idCounterSelected)
    //         // if (filteredChronicles.length != 1) {
    //         const formattedChronicles = this.formatListChronicles(chronicles)
    //         this.props.onSave(formattedChronicles)
    //         // } else {
    //         //     ToastrAction.error('Veuillez renseigner au moins 2 index avant de valider.')
    //         // }
    //     } else {
    //         // const filteredChronicles = chronicles.filter((c) => c.idMat === idPumpSelected)
    //         // if (filteredChronicles.length >= 0) {
    //         const formattedChronicles = this.formatListChronicles(chronicles)
    //         this.props.onSave(formattedChronicles)
    //         // } else {
    //         //     ToastrAction.error('Veuillez renseigner au moins 1 estimation avant de valider.')
    //         // }
    //     }
    // }

    onCancel = () => {
        this.setState({
            chronicles: [],
            measureDate: '',
            value: '',
            endDate: '',
        })
        this.props.onCancel()
    }

    toggleHelp = () => {
        const { openHelp } = this.state
        this.setState({ openHelp: !openHelp })
    }

    getContentHelp = () => {
        const { cms } = this.props
        const cmsFind = cms.find((c) => c.id === 40) || {}
        return cmsFind.comment || ''
    }

    render() {
        const { step, openHelp } = this.state
        const { open } = this.props

        return (
            <DialogMUI open={open}>
                <DialogTitleMUI><b>{i18n.newConso}</b></DialogTitleMUI>
                <DialogContentMUI style={{ paddingTop: 8, paddingBottom: 16 }}>
                    {this.getContent(step)}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Grid item xs={4}>
                            {step !== STEP_MATERIEL && (
                                <MainButton
                                    variant='contained'
                                    onClick={this.previousStep}
                                >
                                    <Icon>keyboard_arrow_left</Icon>
                                    {i18n.previous}
                                </MainButton>
                            )}
                        </Grid>
                        <HelpTooltip
                            title={(<div dangerouslySetInnerHTML={{ __html: this.getContentHelp() }} />)}
                            open={openHelp}
                            placement='top'
                            arrow
                            disableHoverListener
                        >
                            <Grid item xs={3}>
                                <MainButton variant='contained' onClick={this.toggleHelp}>
                                    <Icon>help</Icon>
                                </MainButton>
                            </Grid>
                        </HelpTooltip>
                        <Grid item xs={4}>
                            {step !== STEP_CHRONICLE ? (
                                <MainButton
                                    variant='contained'
                                    onClick={this.nextStep}
                                >
                                    {i18n.next}
                                    <Icon>keyboard_arrow_right</Icon>
                                </MainButton>
                            ) : (
                                <MainButton
                                    variant='contained'
                                    onClick={this.onSave}
                                >
                                    {i18n.validate}
                                </MainButton>
                            )}
                        </Grid>
                        <MainButton variant='contained' reverse onClick={this.onCancel}>
                            {i18n.cancel}
                        </MainButton>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalConso.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.object),
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMatTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatType)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    chronicles: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatChronicle)),
    pumpsIds: PropTypes.arrayOf(PropTypes.number),
    countersIds: PropTypes.arrayOf(PropTypes.number),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    selectedMaterial: PropTypes.number,
    consoType: PropTypes.number,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
        cms: store.CmsReducer.cms,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalConso))
