/* eslint-disable indent */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { compact, uniqBy } from 'lodash'
import { Grid, Icon, LinearProgress } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import LocalizationCard from '../components/cards/LocalizationCard'
import EquipmentCard from '../components/cards/EquipmentCard'
import ComptageCard from '../components/cards/ComptageCard'
import AssociatedResourceCard from '../components/cards/AssociatedResourceCard'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import OuvrageCard from '../components/cards/OuvrageCard'
import RetenueCard from '../components/cards/RetenueCard'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import Accordeon from '../components/Accordeon'
import { MainButton } from '../components/styled/buttons'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import ContactAction from '../contact/actions/ContactAction'
import DtoContact from '../referencials/dto/DtoContact'
import ContactCard from '../components/cards/ContactCard'
import ToastrAction from '../components/toasters/ToastrAction'
import AgriAction from '../agri/actions/AgriAction'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import EditableCard from '../components/cards/EditableCard'
import { pointStatus } from '../referencials/installations/constants/InstallationsConstants'
import { Item } from '../components/styled/grid'
import ModalEditPoint from '../points/ModalEditPoint'
import LittleMap from '../points/LittleMap'
import { hasValue } from '../../../utils/NumberUtil'
import ModalEditCounter from '../referencials/materiels/components/ModalEditCounter'
import ModalEditPump from '../referencials/materiels/components/ModalEditPump'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'

class PointDetailsExploit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: {},
            pointLoaded: false,
            owner: new DtoIntervenant({}),
            editPoint: false,
        }
    }

    componentDidMount() {
        const { match } = this.props
        this.props.fetchInstallation(match.params.id).then(() => {
            this.setInstallation()
        })
    }

    setInstallation = () => {
        const { installation, contacts } = this.props
        if (!contacts.length) {
            this.props.fetchContacts().then(() => {
                this.setContributors(installation)
            })
        } else {
            this.setContributors(installation)
        }
        this.props.setTitle([
            {
                title: i18n.myFolder,
                href: '/exploitation',
            },
            {
                title: `Point ${installation.code || ''}${isLargeWindow() && installation.location ? ` - ${installation.location}` : ''}`,
                href: `/exploitation/point/${installation.id}`,
            },
        ])
        window.scrollTo(0, 0)
    }

    setContributors = (point) => {
        const { exploitation, contacts, declaration } = this.props
        if (!declaration || declaration.idExploitation !== exploitation.idExploitation) {
            this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then((find) => {
                if (find) {
                    ToastrAction.info(i18n.modifSurveyNotShow)
                }
            })
        }
        const linkPoint = exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === point.id) : null
        const owner = contacts.find((c) => c.id === point.ownerCode)
        this.setState({ point, linkPoint, pointLoaded: true, owner: owner || new DtoIntervenant({}) })
    }

    getAssociatedResources = () => {
        const {
            point,
            linkPoint,
        } = this.state
        let associatedResources = linkPoint ? linkPoint.link_samples[0] : {}
        associatedResources = {
            ...associatedResources,
            managementCode: point.managementCode,
            subManagementCode: point.subManagementCode,
        }
        return associatedResources
    }

    getTypePrel = (key) => {
        switch (key) {
            case 1:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESO'
            case 2:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESU'
            default:
                return 'PREL_AGRI.NATURE_PRELEVEMENT'
        }
    }

    getNature = () => {
        const associatedResources = this.getAssociatedResources()
        if (associatedResources) {
            const { codesSandre } = this.props
            const codeType = this.getTypePrel(associatedResources.sampleType)
            const codeFind = codesSandre.find((c) => c.field === codeType && c.code === associatedResources.sampleNature)
            return codeFind ? codeFind.name : ''
        }
        return ''
    }

    getStatus = () => {
        const { linkPoint } = this.state
        if (linkPoint) {
            const status = pointStatus.find(({ code }) => code === linkPoint.stateCode) || {}
            return status.label || ''
        }
        return ''
    }

    getOuvrages = () => {
        const { linkPoint } = this.state
        if (linkPoint.link_sampleCasings.length) {
            return (
                linkPoint.link_sampleCasings.map((ouvrage) => {
                    return (
                        <Grid item xs={12} style={{ margin: '5 0' }}>
                            <OuvrageCard
                                title={i18n.struct}
                                ouvrage={ouvrage}
                                hideIcon
                            />
                        </Grid>
                    )
                })
            )
        }
        return ''
    }

    getRetenues = () => {
        const { linkPoint } = this.state
        const length = linkPoint.link_sampleTanks.length
        const title = length > 1 ? `${i18n.waterRetentions} (${length} ${i18n.elements})` : `${i18n.waterRetention} (${length} ${i18n.element})`
        if (length) {
            return (
                <Grid item xs={12} style={{ margin: '5 0' }}>
                    <Accordeon title={title}>
                        <Grid container direction='column' className='padding-top-1 padding-bottom-1'>
                            {linkPoint.link_sampleTanks.map((retenue, index) => {
                                return (
                                    <Grid item>
                                        <RetenueCard
                                            title={`${i18n.waterRetention} ${index + 1}`}
                                            retenue={retenue}
                                            hideIcon
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Accordeon>
                </Grid>
            )
        }
        return ''
    }

    getEquipments = () => {
        const { point, editPump, editCounter } = this.state
        const { variousMatSituations, variousMateriels, exploitation } = this.props
        const data = uniqBy(variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === point.id), v => [v.idVarious, v.siteCode, v.siteCode, v.siteType, v.statusCode].join())
        const length = data.length
        const title = length > 1 ? `${i18n.materiels} (${length} ${i18n.elements})` : `${i18n.materiel} (${length} ${i18n.element})`
        if (length && variousMatSituations.length && variousMateriels.length) {
            return (
                <Grid item xs={12} className='margin-top-1'>
                    <Accordeon title={title}>
                        <Grid container className='padding-top-1 padding-bottom-1'>
                            {compact(data.map((d) => {
                                const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === d.idVarious)
                                const pompeMat = variousMateriels.find((m) => m.id === d.idVarious)
                                const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === parseInt(point.id) && m.idElement2 === d.idVarious) || {}
                                if (pompeSituation && pompeMat) {
                                    const pompeInfos = {
                                        ...pompeSituation,
                                        ...pompeMat,
                                        ...pompeMat.pump,
                                        assignmentRate: pumpRep.repartition,
                                    }
                                    const compteur = variousMateriels.find((c) => c.id === pompeSituation.idVarious && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode)) || {}
                                    const counterRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 2 && m.idElement1 === d.idVarious && m.idElement2 === compteur.id) || {}
                                    const compteurInfos = {
                                        ...compteur,
                                        ...compteur.counter,
                                        assignmentRate: counterRep.repartition,
                                    }
                                    return (
                                        <>
                                            <Item xs={12} md={6}>
                                                <EquipmentCard
                                                    title={`${i18n.equipementPompage} : ${pompeInfos.siteName}`}
                                                    data={pompeInfos}
                                                    toggleEditMode={() => this.setState({ editPump: !editPump, pump: pompeInfos })}
                                                />
                                            </Item>
                                            <Item xs={12} md={6}>
                                                <ComptageCard
                                                    title={`${i18n.countingDevice} : ${compteurInfos.reference}`}
                                                    data={compteurInfos}
                                                    colorTitle='white'
                                                    toggleEditMode={() => this.setState({ editCounter: !editCounter, counter: compteurInfos })}
                                                />
                                            </Item>
                                        </>
                                    )
                                }
                                return null
                            }))}
                        </Grid>
                    </Accordeon>
                </Grid>
            )
        }
        return ''
    }

    onChangePoint = (obj) => {
        this.setState(({ point }) => ({ point: { ...point, ...obj } }))
    }

    onSavePoint = () => {
        const { point } = this.state
        const { match } = this.props
        this.props.updateInstallation(point).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editPoint: false })
            })
        })
    }

    getModalEditPoint = () => {
        const { point, editPoint, linkPoint } = this.state
        if (editPoint) {
            return (
                <ModalEditPoint
                    open={editPoint}
                    toggleModal={() => this.setState({ editPoint: false })}
                    onChange={this.onChangePoint}
                    onSave={this.onSavePoint}
                    point={point}
                    nature={this.getNature()}
                    stateCode={linkPoint.stateCode}
                />
            )
        }
        return null
    }

    onChangePump = (obj) => {
        this.setState(({ pump }) => ({ pump: { ...pump, ...obj } }))
    }

    onSavePump = () => {
        const { pump } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(pump).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editPump: false, pump: null })
            })
        })
    }

    getModalEditPump = () => {
        const { pump, editPump } = this.state
        if (editPump) {
            return (
                <ModalEditPump
                    open={editPump}
                    toggleModal={() => this.setState({ editPump: false, pump: null })}
                    onChange={this.onChangePump}
                    onSave={this.onSavePump}
                    pump={pump}
                />
            )
        }
        return null
    }

    onChangeCounter = (obj) => {
        this.setState(({ counter }) => ({ counter: { ...counter, ...obj } }))
    }

    onSaveCounter = () => {
        const { counter } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(counter).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editCounter: false, counter: null })
            })
        })
    }

    getModalEditCounter = () => {
        const { counter, editCounter } = this.state
        if (editCounter) {
            return (
                <ModalEditCounter
                    open={editCounter}
                    toggleModal={() => this.setState({ editCounter: false, counter: null })}
                    onChange={this.onChangeCounter}
                    onSave={this.onSaveCounter}
                    counter={counter}
                />
            )
        }
        return null
    }

    render() {
        const {
            owner,
            point,
            linkPoint,
            pointLoaded,
            editPoint,
        } = this.state

        return (
            <Grid container alignItems='stretch' justifyContent='flex-start' className='padding-top-1'>
                {pointLoaded ? (
                    <>
                        <Item xs={12} md={4}>
                            <EditableCard
                                title={i18n.descriptive}
                                toggleEditMode={() => this.setState({ editPoint: !editPoint })}
                            >
                                <p>{i18n.name} : {point.name || ''}</p>
                                <p>{i18n.code} : {point.code || ''}</p>
                                <p>{i18n.naturePrel} : {this.getNature()}</p>
                                <p>{i18n.status} : {this.getStatus()}</p>
                                <p>{i18n.descriptive} : {point.descriptive || ''}</p>
                                <p>{i18n.comment} : {point.comments || ''}</p>
                            </EditableCard>
                        </Item>
                        <Item xs={12} md={4}>
                            <LocalizationCard
                                data={point}
                                title={i18n.localisation}
                                hideIcon
                                styleContainer={{ height: 'calc(100% - 12px)' }}
                                style={{ height: '100%' }}
                            />
                        </Item>
                        <Item xs={12} md={4} style={{ padding: '5 10' }}>
                            <LittleMap
                                card
                                point={point}
                                stateCode={linkPoint.stateCode}
                                height={isLargeWindow() ? 'auto' : 200}
                                styleContainer={{ padding: 0, height: 'calc(100% - 2px)' }}
                                styleCard={{ height: '100%' }}
                            />
                        </Item>
                        <Item xs={12} md={6}>
                            <ContactCard
                                contact={owner}
                                title={i18n.ownerOfPlot}
                                styleContainer={{ height: 'calc(100% - 12px)' }}
                                style={{ height: '100%' }}
                                hideIcon
                            />
                        </Item>
                        <Item xs={12} md={6}>
                            <AssociatedResourceCard
                                data={this.getAssociatedResources()}
                                title={i18n.associatedResource}
                                styleContainer={{ height: 'calc(100% - 12px)' }}
                                style={{ height: '100%' }}
                                hideIcon
                            />
                        </Item>
                        {this.getOuvrages()}
                        {this.getRetenues()}
                        {this.getEquipments()}
                        <Grid item xs={12} className='padding-left-2 padding-right-2 padding-top-2'>
                            <MainButton
                                reverse
                                onClick={() => this.props.push('/exploitation')}
                            >
                                <Icon>keyboard_arrow_left</Icon>
                                {i18n.myFolder}
                            </MainButton>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
                {this.getModalEditPoint()}
                {this.getModalEditPump()}
                {this.getModalEditCounter()}
            </Grid>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        codesSandre: store.ReferencialReducer.codesSandre,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchInstallation: InstallationsAction.fetchInstallation,
    updateInstallation: InstallationsAction.updateInstallation,
    fetchContacts: ContactAction.fetchContacts,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    updateVariousMateriel: MaterielAction.updateVariousMateriel,
    push,
}

PointDetailsExploit.propTypes = {
    installation: PropTypes.instanceOf(DtoInstallation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    setTitle: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateInstallation: PropTypes.func,
    updateVariousMateriel: PropTypes.func,
    push: PropTypes.func,
    match: PropTypes.instanceOf(PropTypes.object),
    location: PropTypes.instanceOf(PropTypes.object),
    fetchContacts: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointDetailsExploit)
