'use strict'
import { push } from 'react-router-redux'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    LOGIN_FAIL,
    DISCONNECTED,
    RECEIVE_CURRENT_LOCATION,
    RECEIVE_ALL_USERS,
    PATH,
    DEFAULT_PATH,
    RECEIVE_WATCHID,
    ACTION,
    MENU,
    STATION_RANGE,
    POPUP,
    FULL_RESET,
    TITLE,
    MENU_RESET,
    MEDEAU_TOKEN,
    MEDEAU_LOGIN,
    RECEIVE_ALL_CGU,
    RECEIVE_DATE_VALID_CGU,
    RECEIVE_PARTENAIRE_CMS,
    RECEIVE_CGU_CMS,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_CMS_HOME,
    RECEIVE_CMS_LOGO,
} from '../constants/HomeConstants'
import AppStore from '../../../store/AppStore'
import ApplicationConf from '../../../conf/ApplicationConf'
import {
    checkAuth,
    getAuthorization,
    removeToken,
    getJson,
    checkError,
    getAuthorizationLogin,
    checkAuthV2,
} from '../../../utils/ActionUtils'
import ToastrAction from '../../online/components/toasters/ToastrAction'
import DtoCMSEvent from '../../online/cms/dto/DtoCMSEvent'
import { isRunningApp } from '../../../utils/LocalStorageUtils'
import LogAction from '../../../utils/log/actions/LogAction'

const HomeAction = {
    loginFail(message) {
        return { type: LOGIN_FAIL, message: message }
    },
    watchId(id) {
        return { type: RECEIVE_WATCHID, watchId: id }
    },
    disconnected(message) {
        return { type: DISCONNECTED, message: message }
    },
    receiveCurrentLocation(position) {
        return { type: RECEIVE_CURRENT_LOCATION, position: position }
    },
    setDefaultPath() {
        return (dispatch) => {
            dispatch(HomeAction.setPath(DEFAULT_PATH))
        }
    },
    setPath(path) {
        return (dispatch) => {
            localStorage.setItem(PATH, path)
            removeToken()
            dispatch(HomeAction.fullReset())
        }
    },
    setStationRange(range) {
        localStorage.setItem(STATION_RANGE, range)
    },

    login(login, password) {
        const basicAuth = btoa(`${login}:${password}`)
        const platformId = window && window.cordova && window.cordova.platformId && String(window.cordova.platformId).toUpperCase()
        const platform = isRunningApp() ? platformId : 'WEB'
        return () => {
            return fetch(ApplicationConf.login(), {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${basicAuth}`,
                    Module: 'MEDEAU',
                    Platform: platform,
                },
                body: JSON.stringify({
                    module: 'MEDEAU',
                }),
            })
                .then(checkAuth)
                .then((json) => {
                    localStorage.setItem(MEDEAU_LOGIN, login)
                    localStorage.setItem(MEDEAU_TOKEN, json)
                })
                .catch((err) => {
                    ToastrAction.error(i18n.incorrectIdOrPassword, true)
                    LogAction.logError(`${i18n.unauthorizedLogin} : ${err}`)
                })
        }
    },

    logout() {
        if (window.navigator.onLine) {
            return (dispatch) => {
                return fetch(ApplicationConf.logout(), {
                    method: 'POST',
                    headers: getAuthorization(),
                }).then((response) => {
                    removeToken()
                    dispatch(HomeAction.fullReset())
                    dispatch(push('/login'))
                    if (response.status !== 200) {
                        throw response.statusText
                    }
                })
            }
        }
        return (dispatch) => {
            removeToken()
            dispatch(HomeAction.fullReset())
            dispatch(push('/login'))
        }
    },

    resetPassword(login, redirectLogin = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'POST',
                body: JSON.stringify({ login, module: 'MOBILE' }),
            })
                .then(checkError)
                .then((json) => {
                    if (json.status !== 200) {
                        ToastrAction.error(i18n.incorrectId, true)
                        return json
                    }
                    if (redirectLogin) {
                        dispatch(push('/login'))
                    }
                    return null
                })
                .catch((e) => {
                    LogAction.logError(`${i18n.fetchError + i18n.retrievePassword} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword, true)
                })
        }
    },

    updatePassword(password, token) {
        return () => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'PUT',
                body: JSON.stringify({ password: password, token: token })
            })
                .then(checkError)
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.newPassword} : ${e}`)
                })
        }
    },

    verifyResetCode(login, resetCode) {
        return () => {
            return fetch(ApplicationConf.verifyResetCode(), {
                method: 'POST',
                body: JSON.stringify({ login: login, resetCode: resetCode })
            })
                .then(response => checkAuthV2(response, {
                    404: () => {
                        ToastrAction.error(i18n.unableToResetPassword)
                        return false
                    },
                    429: () => {
                        ToastrAction.error(i18n.error429)
                        return false
                    },
                    200: () => {
                        ToastrAction.success(i18n.valideCode)
                        return true
                    }
                }))
                .then(response => {
                    return response
                })
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.retrievePassword} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword)
                })
        }
    },

    testPath(path) {
        return (dispatch) => {
            return fetch(ApplicationConf.ping(path)).then((res) => {
                if (res.status === 200) {
                    dispatch(HomeAction.setPath(path))
                    dispatch(push('/login'))
                }
            })
        }
    },
    receiveUsers(users) {
        return { type: RECEIVE_ALL_USERS, users }
    },

    promiseUsers() {
        return fetch(ApplicationConf.user.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },
    fetchUsers() {
        return (dispatch) => {
            return HomeAction.promiseUsers()
                .then(json => {
                    dispatch(HomeAction.receiveUsers(json))
                })
        }
    },

    setAction(action) {
        return { type: ACTION, action: action }
    },
    setMenu(menu) {
        return { type: MENU, menu: menu }
    },
    setPopup(popup) {
        return { type: POPUP, popup: popup }
    },
    setTitle(title) {
        const storeTitleHash = AppStore.getState()
            .HomeReducer.title.map((obj) => obj.title)
            .join()
        const titleHash = title.map((obj) => obj.title).join()
        if (storeTitleHash !== titleHash) {
            return { type: TITLE, title }
        }
        return { type: TITLE, title }
    },
    resetMenu(menu) {
        return { type: MENU_RESET, menu }
    },
    fullReset() {
        return (dispatch) => {
            dispatch({ type: FULL_RESET })
        }
    },
    getAllCGU() {
        return (dispatch) => {
            return fetch(ApplicationConf.cgu.getAll(), {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((json) => {
                    dispatch(HomeAction.receiveCGU(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    getDateValidCGU(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.cgu.getDateValidCGU(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveDateValidCGU(json.date))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    sendDateCgu(postDateCGU) {
        return () => {
            return fetch(ApplicationConf.cgu.sendDateCgu(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(postDateCGU),
            }).catch((err) => {
                LogAction.logError(`${i18n.createError} : ${err}`)
            })
        }
    },

    receiveCGU(cgu) {
        return { type: RECEIVE_ALL_CGU, cgu }
    },

    receiveDateValidCGU(date) {
        return { type: RECEIVE_DATE_VALID_CGU, date }
    },

    receiveCmsPartenaire(cms) {
        return { type: RECEIVE_PARTENAIRE_CMS, cms }
    },

    getCmsPartenaire() {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(2), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveCmsPartenaire(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    receiveCGUFromCms(cmsCGU) {
        return { type: RECEIVE_CGU_CMS, cmsCGU }
    },

    getCGUFromCms() {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(3), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveCGUFromCms(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    receiveApplicationSettings(applicationSettings) {
        return { type: RECEIVE_APPLICATION_SETTINGS, applicationSettings }
    },

    promiseApplicationSettings() {
        return fetch(ApplicationConf.user.applicationSettings(), {
            method: 'GET',
            headers: getAuthorizationLogin()
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchApplicationSettings() {
        return (dispatch) => {
            return HomeAction.promiseApplicationSettings()
                .then(json => {
                    dispatch(HomeAction.receiveApplicationSettings(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.settings} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.settings)
                })
        }
    },

    receiveCMSHome(cmsHome) {
        return { type: RECEIVE_CMS_HOME, cmsHome }
    },

    fetchCMSHome(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.get(id), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    dispatch(HomeAction.receiveCMSHome(json))
                    return new DtoCMSEvent(json)
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.article} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.article)
                })
        }
    },

    receiveCMSLogo(cmsLogo) {
        return { type: RECEIVE_CMS_LOGO, cmsLogo }
    },

    fetchCMSLogo(id = 39) {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.get(id), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    dispatch(HomeAction.receiveCMSLogo(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.article} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.article)
                })
        }
    },
}

export default HomeAction
