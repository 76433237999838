import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Card, Divider, Grid, Icon, Modal } from '@mui/material'
import { Fragment } from 'react'
import { MainButton } from '../components/styled/buttons'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import { formatDate } from '../../../utils/DateUtil'
import { isRunningApp } from '../../../utils/LocalStorageUtils'
import { CMS_PATH } from '../../../conf/basepath'

export default function ModalActualities({ open, toggleModal, cms = [] }) {
    const [actu, setActu] = React.useState(0)

    const handleClose = () => {
        toggleModal()
    }

    // const getIcon = (status) => {
    //     switch (status) {
    //         case 2:
    //             return 'create'
    //         case 3:
    //             return 'folderOpen'
    //         default:
    //             return 'create'
    //     }
    // }

    const openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    const openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    const getPanel = () => {
        const actuality = cms[actu] || {}
        return (
            <Grid item>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item className='padding-2'>
                        <span id='actu-title' style={{ fontSize: '1.5em' }}>{actuality.title}</span><br />
                        <span id='actu-description' className='italic' style={{ display: 'flex' }}>{formatDate(actuality.updateDate)}{actuality.status !== 1 && <Icon>{this.getIcon(actuality.status)}</Icon>}</span>
                    </Grid>
                    {!!actuality.document.length && (
                        <Icon
                            className='clickable'
                            onClick={() => openDocument(actuality.document[0])}
                            fontSize='large'
                        >
                            insert_drive_file
                        </Icon>
                    )}
                </Grid>
                <p id='actu-subtitle'>
                    {actuality.subtitle}
                </p>
                <div dangerouslySetInnerHTML={{ __html: actuality.comment || '' }} />
                <p id='actu-footer' className='italic'>{actuality.footerContent}</p>
                <p
                    className='blue-text'
                    onClick={() => openWebSite(actuality.link)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {actuality.link}
                    <Icon>launch</Icon>
                </p>
            </Grid>
        )
    }

    const prevActu = () => {
        setActu(actu - 1)
    }

    const nextActu = () => {
        setActu(actu + 1)
    }

    const body = (
        <Card className='overflowY' style={{ width: '100%', height: '100%' }}>
            <Grid
                container
                className='overflowY padding-2'
                direction='row'
                alignItems='flex-start'
                style={{
                    marginBottom: cms.length > 1 ? 105 : 60,
                    height: '-webkit-fill-available',
                    display: 'block',
                }}
            >
                <h2 id='title'>
                    {i18n.actualities}
                </h2>
                <Divider />
                {getPanel()}
            </Grid>
            <Grid
                className='borderTop-Grey'
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    bottom: 0,
                    height: cms.length > 1 ? 105 : 60,
                    padding: '4px 16px',
                }}
            >
                {cms.length > 1 && (
                    <>
                        <Grid item xs={5}>
                            {!!actu && (
                                <MainButton
                                    variant='contained'
                                    onClick={prevActu}
                                >
                                    <Icon>keyboard_arrow_left</Icon>
                                    {i18n.previous}
                                </MainButton>
                            )}
                        </Grid>
                        <Grid item xs={5}>
                            {actu !== cms.length - 1 && (
                                <MainButton
                                    variant='contained'
                                    onClick={nextActu}
                                >
                                    {i18n.next}
                                    <Icon>keyboard_arrow_right</Icon>
                                </MainButton>
                            )}
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <MainButton reverse variant='contained' onClick={handleClose}>
                        {i18n.close}
                    </MainButton>
                </Grid>
            </Grid>
        </Card>
    )

    return (
        <Modal
            open={open}
            className='modal'
            style={{ bottom: 'inherit', zIndex: '1100' }}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            {body}
        </Modal>
    )
}

ModalActualities.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
}
