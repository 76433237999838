import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { InputRow } from '../styled/inputs'
import { isEmpty } from '../../../../utils/ObjectUtils'
import DtoManagementUnit from '../../referencials/dto/DtoManagementUnit'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import EditableCard from './EditableCard'

class AssociatedResourceCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
        }
    }

    getContent = (editMode) => {
        const { managementUnits, codesSandre } = this.props
        const { data } = this.state
        const zre = codesSandre.find((c) => c.field === 'AGRI_PREL_ZRE' && c.code === data.codeZRE) || {}
        if (editMode) {
            return (
                <>
                    <InputRow
                        item
                        id='bdCarthage'
                        label={i18n.bdCarthage}
                        type='text'
                        variant='outlined'
                    />
                    <InputRow item id='bdLisa' label={i18n.bdLisa} type='text' variant='outlined' />
                    <InputRow item id='zre' label={i18n.zre} type='text' variant='outlined' />
                    <InputRow
                        item
                        id='miocene'
                        label={i18n.miocene}
                        type='text'
                        variant='outlined'
                    />
                    <InputRow item id='ug' label={i18n.ug} type='text' variant='outlined' />
                    <InputRow item id='sousUg' label={i18n.sousUg} type='text' variant='outlined' />
                </>
            )
        }
        const ug = managementUnits.find((u) => u.managementCode === data.managementCode) || {}
        const sug = managementUnits.find((u) => u.managementCode === data.subManagementCode) || {}
        return (
            isEmpty(data) ? (
                <p>{i18n.noInformation}</p>
            ) : (
                <>
                    {/* <p>{i18n.bdCarthage} : {data.carthageDbCode}</p>
                    <p>{i18n.bdLisa} : {data.lisaDbCode}</p> */}
                    <p>{i18n.zre} : {zre.name || ''}</p>
                    <p>{i18n.miocene} : {data.miocenezpr ? i18n.yes : i18n.no}</p>
                    <p>{`${i18n.ug} - ID : ${ug.id || ''}`}</p>
                    <p>{`${i18n.ug} : ${ug.name || ''}`}</p>
                    <p>{`S${i18n.ug} - ID : ${sug.id || ''}`}</p>
                    <p>{`S${i18n.ug} : ${sug.name || ''}`}</p>
                </>
            )
        )
    }

    render() {
        const { title, editMode, hideIcon, styleContainer, style } = this.props
        return (
            <EditableCard
                title={title}
                editMode={editMode}
                toggleEditMode={this.props.toggleEditMode}
                hideIcon={hideIcon}
                styleContainer={styleContainer}
                style={style}
            >
                {this.getContent(editMode)}
            </EditableCard>
        )
    }
}

AssociatedResourceCard.propTypes = {
    title: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    selectType: PropTypes.bool,
    hideIcon: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    data: PropTypes.instanceOf(PropTypes.object),
    style: PropTypes.instanceOf(PropTypes.object),
    styleContainer: PropTypes.instanceOf(PropTypes.object),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = (store) => {
    return {
        managementUnits: store.ReferencialReducer.managementUnits,
        codesSandre: store.ReferencialReducer.codesSandre,
    }
}

export default connect(mapStateToProps)(AssociatedResourceCard)
