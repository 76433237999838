import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { InputRow } from '../../../components/styled/inputs'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'

class Etape4 extends Component {
    onChange = (value) => {
        this.props.onChangeDeclaration('comment', value)
    }

    getContent = () => {
        const { declaration, readMode } = this.props
        return (
            <InputRow
                item
                id='comment'
                type='text'
                variant='outlined'
                placeholder='Commentaire'
                value={declaration.comment}
                onChange={(e) => this.onChange(e.target.value)}
                multiline
                rows={20}
                rowsMax={Infinity}
                style={{ minHeight: '65%' }}
                InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                disabled={readMode}
            />
        )
    }

    render() {
        return (
            <Grid container alignItems='center' style={{ padding: '5 10' }}>
                {this.getContent()}
            </Grid>
        )
    }
}

Etape4.propTypes = {
    toggleEdit: PropTypes.func,
    readMode: PropTypes.bool,
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    onChangeDeclaration: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    push,
}

export default connect(null, mapDispatchToProps)(Etape4)
