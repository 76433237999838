/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Avatar, Grid, Icon, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { indigo } from '@mui/material/colors'
import HomeAction from '../../offline/actions/HomeAction'
import { MainButton } from '../components/styled/buttons'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import AgriAction from '../agri/actions/AgriAction'
import DtoIntervenantDeclaration from '../agri/dto/enquete/DtoIntervenantDeclaration'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import ToastrAction from '../components/toasters/ToastrAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import EditableCard from '../components/cards/EditableCard'
import { getSetting } from '../../../utils/FormUtils'
import DtoEnquete from '../agri/dto/enquete/DtoEnquete'
import Etape5 from './components/steps/Etape5'
import Etape4 from './components/steps/Etape4'
import Etape3 from './components/steps/Etape3'
import Etape2 from './components/steps/Etape2'
import Etape1 from './components/steps/Etape1'

const surveyTypes = [{
    value: 1,
    label: 'annuelle',
}, {
    value: 2,
    label: 'intermédiaire',
}]

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 25,
        border: '1px solid grey',
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        backgroundColor: '#a5c7e6',
    },
}))(LinearProgress)

const styles = (theme) => ({
    blue: {
        color: theme.palette.getContrastText(indigo[500]),
        backgroundColor: indigo[500],
    },
    item: {
        margin: '16px 0',
    },
})

class Enquete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: props.location.step ? props.location.step : 0,
            declaration: props.location.declaration || {},
            progress: [0, 0, 0, 0, 0],
            dataLoaded: true,
            pointsLoaded: false,
            allContributors: [],
            readMode: false,
        }
        this.getPrevStep = this.getPrevStep.bind(this)
        this.getNextStep = this.getNextStep.bind(this)
    }

    componentDidMount() {
        const { exploitation } = this.props
        if (!exploitation.idExploitation) {
            this.props.fetchExploitation().then(() => {
                this.getDeclaration()
            })
        } else {
            this.getDeclaration()
        }
    }

    getDeclaration = () => {
        const { exploitation } = this.props
        this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
            this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then(() => {
                const { declaration } = this.props
                if (declaration.idDeclaration) {
                    this.props.fetchSurvey(declaration.idSurvey).then(() => {
                        const { survey } = this.props
                        this.setState({ declaration, pointsLoaded: true, readMode: declaration.statusCode >= 4 || survey.statusCode === 3 })
                        const title = `${i18n.enquete} ${survey.year || ''}`
                        this.props.setTitle([
                            {
                                title: title,
                                href: 'enquete',
                            },
                        ])
                        this.updateProgress()
                    })
                } else {
                    this.props.push('/home')
                }
            })
        })
    }

    updateProgress = () => {
        const { declaration, progress } = this.state
        const updateProgress = []
        if (declaration.lastStep) {
            const { lastStep } = declaration
            if (lastStep >= 1) {
                updateProgress.push(100)
            } else {
                updateProgress.push(0)
            }
            if (lastStep >= 2) {
                updateProgress.push(100)
            } else {
                updateProgress.push(0)
            }
            if (declaration.link_declarationInstallation.length) {
                const length = declaration.link_declarationInstallation.length
                let pointsHavingUsages = 0
                declaration.link_declarationInstallation.map((p) => {
                    if (p.link_usages.length) {
                        pointsHavingUsages = pointsHavingUsages + 1
                    }
                })
                const pourcentage = (pointsHavingUsages / length) * 90 + 10
                updateProgress.push(pourcentage)
            } else {
                updateProgress.push(0)
            }
            if (lastStep >= 4) {
                updateProgress.push(100)
            } else {
                updateProgress.push(0)
            }
            if (lastStep >= 5) {
                updateProgress.push(100)
            } else {
                updateProgress.push(0)
            }
            this.setState({ progress: updateProgress })
            window.scrollTo(0, 0)
        } else {
            this.setState({ progress })
            window.scrollTo(0, 0)
        }
    }

    onChangeDeclaration = (key, value) => {
        const { declaration } = this.state
        const updateDeclaration = {
            ...declaration,
            [key]: value,
        }
        this.setState({ declaration: updateDeclaration })
        if (key !== 'comment') {
            this.props.updateDeclaration(updateDeclaration)
        }
    }

    getPrevStep = (actualStep) => {
        const { survey: { link_params = [] } } = this.props
        if ((getSetting(link_params, `step${actualStep - 1}`) || '1') === '0') {
            return this.getPrevStep(actualStep - 1)
        }
        return actualStep - 1
    }

    getNextStep = (actualStep) => {
        const { survey: { link_params = [] } } = this.props
        if ((getSetting(link_params, `step${actualStep + 1}`) || '1') === '0') {
            return this.getNextStep(actualStep + 1)
        }
        return actualStep + 1
    }

    startDeclaration = () => {
        this.setState({ step: this.getNextStep(0) })
        window.scrollTo(0, 0)
    }

    previousStep = () => {
        const { step, declaration, readMode } = this.state
        if (readMode) {
            this.setState({ step: this.getPrevStep(step) })
        } else {
            this.setState({ dataLoaded: false, step: this.getPrevStep(step) })
            this.props.updateDeclaration(declaration).then(() => {
                if (step === 2 || step === 3) {
                    this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
                        this.setState({ dataLoaded: true })
                        this.updateProgress()
                    })
                } else {
                    this.setState({ dataLoaded: true })
                    this.updateProgress()
                }
            })
        }
    }

    nextStep = () => {
        const { step, declaration, allContributors, readMode } = this.state
        const { contributors, exploitation } = this.props
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode) || {}
        if (readMode) {
            this.setState({ step: this.getNextStep(step) })
        } else if (contributors.length) {
            if (step === 2 &&
                preleveur.structureType !== 1 && !allContributors.find((cT) => cT.contributorType === -1 && cT.idExploitation === exploitation.idExploitation && cT.mode !== 'd')) {
                ToastrAction.error('Veuillez renseigner un représentant légal')
            } else {
                this.setState({ dataLoaded: false, step: this.getNextStep(step) })
                const updateDeclaration = {
                    ...declaration,
                    lastStep: declaration.lastStep > step ? declaration.lastStep : step,
                    statusCode: declaration.statusCode === 3 ? 3 : 2,
                }
                this.props.updateDeclaration(updateDeclaration).then(() => {
                    if (step === 0 || step === 1) {
                        this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
                            this.setState({ dataLoaded: true })
                            this.updateProgress()
                        })
                    } else {
                        this.setState({ dataLoaded: true })
                        this.updateProgress()
                    }
                })
            }
        }
    }

    setContributors = (allContributors) => {
        this.setState({ allContributors })
    }

    getStep = (declaration) => {
        const { step, progress, pointsLoaded, readMode } = this.state
        const { classes, survey } = this.props
        switch (step) {
            case 0:
                return (
                    <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        style={{ height: 'fit-content' }}
                    >
                        <Grid item xs={12} style={{ padding: '0 10' }}>
                            <p>
                                L’enquête {(surveyTypes.find((t) => t.value === survey.surveyType) || {}).label} {survey.year} est maintenant ouverte. Vous pouvez effectuer votre
                                déclaration et la modifier jusqu’au {survey.endDate ? new Date(survey.endDate).toLocaleString() : ''}.
                            </p>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={11}
                        >
                            <EditableCard
                                title='Avancement de votre déclaration'
                                hideIcon
                            >
                                <Grid
                                    container
                                    direction='row'
                                    justifyContent='space-evenly'
                                    alignItems='center'
                                >
                                    {(getSetting(survey.link_params, 'step1') || '1') !== '0' && (
                                        <>
                                            <Grid item xs={2}>
                                                <BorderLinearProgress
                                                    variant='determinate'
                                                    value={progress[0] || 0}
                                                />
                                            </Grid>
                                            <Grid className={classes.item} item xs={9}>
                                                <span>Vérifier mes coordonnées</span>
                                            </Grid>
                                        </>
                                    )}
                                    {(getSetting(survey.link_params, 'step2') || '1') !== '0' && (
                                        <>
                                            <Grid item xs={2}>
                                                <BorderLinearProgress
                                                    variant='determinate'
                                                    value={progress[1] || 0}
                                                />
                                            </Grid>
                                            <Grid className={classes.item} item xs={9}>
                                                <span>Vérifier mon exploitation</span>
                                            </Grid>
                                        </>
                                    )}
                                    {(getSetting(survey.link_params, 'step3') || '1') !== '0' && (
                                        <>
                                            <Grid item xs={2}>
                                                <BorderLinearProgress
                                                    variant='determinate'
                                                    value={progress[2] || 0}
                                                />
                                            </Grid>
                                            <Grid className={classes.item} item xs={9}>
                                                <span>Déclarer mes usages</span>
                                            </Grid>
                                        </>
                                    )}
                                    {(getSetting(survey.link_params, 'step4') || '1') !== '0' && (
                                        <>
                                            <Grid item xs={2}>
                                                <BorderLinearProgress
                                                    variant='determinate'
                                                    value={progress[3] || 0}
                                                />
                                            </Grid>
                                            <Grid className={classes.item} item xs={9}>
                                                <span>Commentaires</span>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={2}>
                                        <BorderLinearProgress
                                            variant='determinate'
                                            value={progress[4] || 0}
                                        />
                                    </Grid>
                                    <Grid className={classes.item} item xs={9}>
                                        <span>Validation</span>
                                    </Grid>
                                </Grid>
                            </EditableCard>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0 10' }}>
                            <p>Pour tout renseignement veuillez contacter l'OUGC 84.</p>
                        </Grid>
                    </Grid>
                )
            default: case 1:
                return (
                    <Etape1
                        declaration={declaration}
                        onChangeDeclaration={this.onChangeDeclaration}
                        readMode={readMode}
                    />
                )
            case 2:
                return (
                    <Etape2
                        declaration={declaration}
                        onChangeDeclaration={this.onChangeDeclaration}
                        setContributors={this.setContributors}
                        readMode={readMode}
                    />
                )
            case 3:
                if (pointsLoaded) {
                    return (
                        <Etape3
                            declaration={declaration}
                            onChangeDeclaration={this.onChangeDeclaration}
                            readMode={readMode}
                        />
                    )
                }
                return (
                    <Grid container alignItems='center'>
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Etape4
                        declaration={declaration}
                        onChangeDeclaration={this.onChangeDeclaration}
                        readMode={readMode}
                    />
                )
            case 5:
                return <Etape5 declaration={declaration} onGetBack={(v) => this.setState({ step: v })} readMode={readMode} />
        }
    }

    getStepLibelle = () => {
        const { step } = this.state

        switch (step) {
            case 1: case 2:
                return 'Je vérifie mes informations'
            case 3:
                return 'Je sélectionne mes points de prélèvement'
            case 4:
                return 'Je laisse un commentaire si besoin ou je signale un incident particulier'
            case 5:
                return 'Je vérifie ma déclaration avant de la valider'
            default:
                return ''
        }
    }

    render() {
        const { step, declaration, dataLoaded, pointsLoaded, readMode } = this.state
        const { classes } = this.props

        return (
            <>
                {step !== 0 ? (
                    <Grid
                        className='borderBottom-Grey'
                        container
                        alignItems='center'
                        justifyContent='center'
                        style={{
                            padding: '0 10px',
                            backgroundColor: 'white',
                        }}
                    >
                        <Grid item xs={2} md={1}>
                            <Avatar className={classes.blue}>{step}/5</Avatar>
                        </Grid>
                        <Grid item xs={10}>
                            <h3 className='italic'>{this.getStepLibelle()}</h3>
                        </Grid>
                    </Grid>
                ) : (
                    ''
                )}
                <Grid className='overflowY' container alignItems='center'>
                    {dataLoaded && pointsLoaded ? (
                        this.getStep(declaration)
                    ) : (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    className={(step === 0 || step === 1 || step === 4 || readMode) && 'borderTop-Grey'}
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        height: '60px',
                        backgroundColor: 'white',
                        zIndex: '1000',
                        padding: '0 10px',
                    }}
                >
                    {step ? (
                        <>
                            <Grid item xs={5} md={3}>
                                <MainButton variant='contained' onClick={this.previousStep}>
                                    <Icon>keyboard_arrow_left</Icon>
                                    {i18n.previous}
                                </MainButton>
                            </Grid>
                            <Grid item xs={5} md={3}>
                                {step !== 5 && (
                                    <MainButton variant='contained' onClick={this.nextStep}>
                                        {i18n.next}
                                        <Icon>keyboard_arrow_right</Icon>
                                    </MainButton>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={11} md={5}>
                            {step !== 1 && (
                                <MainButton variant='contained' onClick={this.startDeclaration}>
                                    {readMode ? i18n.consultDeclaration : i18n.completeDeclaration}
                                </MainButton>
                            )}
                        </Grid>
                    )}
                </Grid>
            </>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchSurvey: AgriAction.fetchSurvey,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    push,
}

const mapStateToProps = (store) => {
    return {
        declaration: store.AgriReducer.declaration,
        survey: store.AgriReducer.survey,
        declarationContributors: store.AgriReducer.declarationContributors,
        exploitation: store.AgriReducer.exploitation,
        contributors: store.ReferencialReducer.contributors,
        installations: store.InstallationsReducer.installations,
    }
}

Enquete.propTypes = {
    setTitle: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchSurvey: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    updateDeclaration: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    push: PropTypes.func,
    declaration: PropTypes.instanceOf(DtoDeclaration),
    location: PropTypes.instanceOf(PropTypes.object),
    classes: PropTypes.instanceOf(PropTypes.object),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    fetchInstallationsByExploitationId: PropTypes.func,
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    survey: PropTypes.instanceOf(DtoEnquete),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Enquete))
