import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { compact, sortBy } from 'lodash'
import { CMS_PATH } from '../../conf/basepath'
import { MainButton } from '../online/components/styled/buttons'
import DtoCMSEvent from '../online/cms/dto/DtoCMSEvent'
import packageJson from '../../../package.json'
import AccountAction from '../online/account/actions/AccountAction'
import DtoUser from '../online/account/dto/DtoUser'
import ModalUpdatePassword from '../online/account/components/ModalUpdatePassword'
import ToastrAction from '../online/components/toasters/ToastrAction'
import { InputRow } from '../online/components/styled/inputs'
import { isRunningApp } from '../../utils/LocalStorageUtils'
import { managerBlue } from '../online/components/styled/theme'
import { MEDEAU_LOGIN, MEDEAU_TOKEN } from './constants/HomeConstants'
import ModalCGU from './components/ModalCGU'
import HomeAction from './actions/HomeAction'

const styles = () => ({
    gridTop: {
        backgroundSize: 'cover',
        height: '15%',
    },
    logoTop: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '100%',
        height: '15%',
    },
    img: {
        height: '100%',
        width: 'auto',
    },
})

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            login: localStorage.getItem(MEDEAU_LOGIN) || '',
            password: '',
            openModal: false,
            dataLoaded: false,
            loginError: false,
            passwordError: false,
            openModalPassword: false,
            images: [],
            cmsCGUDate: 0,
            newMdp: '',
            mdpConfirmation: '',
            settingsLoaded: false,
            backgroundUrl: '',
            loading: false,
        }
    }

    componentDidMount() {
        this.props.fetchApplicationSettings().then(() => {
            const { applicationSettings } = this.props
            const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
            if (applicationName.value) {
                document.title = applicationName.value
            }
            this.setState({ settingsLoaded: true })
        })
        this.props.fetchCMSHome(3).then((cms) => {
            const backgroundUrl = cms && cms.document && cms.document.length ? CMS_PATH + cms.document[0].name : ''
            this.setState({ backgroundUrl: backgroundUrl })
        })
        this.props.fetchCMSLogo(39).then(() => {
            const { cmsLogo } = this.props
            const logoUrl = cmsLogo && cmsLogo.document && cmsLogo.document.length ? CMS_PATH + cmsLogo.document[0].name : ''
            this.setState({ logoUrl: logoUrl })
        })
        this.getCGU()
        this.getImages()
    }

    getCGU = () => {
        this.props.getCGUFromCms().then(() => {
            const { cmsCGU } = this.props
            const cmsCGUDate = Math.max(cmsCGU.map(({ updateDate }) => updateDate), 0)
            this.setState({
                cmsCGUDate,
                dataLoaded: true,
            })
        })
    }

    handleChangeValue = (value) => {
        this.setState(value)
    }

    toggleModalPassword = () => {
        const { openModalPassword } = this.state
        this.setState({ openModalPassword: !openModalPassword })
    }

    toggleModalCGU = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, login } = this.state
        const { accountUser, applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    if (newMdp !== login) {
                        this.props.updatePassword(newMdp, accountUser.resetPassword).then(() => {
                            this.props.login(login, newMdp).then(() => {
                                this.toggleModalPassword()
                                this.checkCGU()
                            })
                        })
                    } else {
                        ToastrAction.error('Le mot de passe doit être différent de celui qui vous a été fourni', true)
                    }
                } else {
                    ToastrAction.error(regexHelp, true)
                }
            } else {
                ToastrAction.error('Les mots de passe ne sont pas identiques', true)
            }
        }
    }

    getImages = () => {
        this.props.getCmsPartenaire().then(() => {
            const { cms } = this.props
            const list = compact(sortBy(cms, 'subtitle').map((c) => {
                if (c.cmsDocument[0]) {
                    return {
                        link: c.link,
                        docName: c.cmsDocument[0].name,
                    }
                }
                return null
            }))
            this.setState({
                images: list,
            })
        })
    }

    onSubmit = () => {
        const { login, password, loading } = this.state
        if (!login) {
            this.setState({ loginError: true })
        }
        if (!password) {
            this.setState({ passwordError: true })
        }
        if (password && login && !loading) {
            this.setState({ loading: true })
            this.props.login(login, password).then(() => {
                this.setState({ loading: false })
                this.props.fetchUser(login).then(() => {
                    const { accountUser } = this.props
                    if (!accountUser.resetPassword) {
                        this.checkCGU()
                    } else if (accountUser.resetPassword.length > 4) {
                        this.setState({ openModalPassword: true })
                    } else {
                        this.props.logout()
                        ToastrAction.warning('Une demande de changement de mot de passe est en cours sur ce compte. Si vous n\'en êtes pas à l\'origine, veuillez contacter votre administrateur.', true)
                    }
                })
            })
        }
    }

    checkCGU = () => {
        const { login } = this.state
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu, accountUser } = this.props
            const { cmsCGUDate } = this.state
            const dateUser = new Date(dateValidCgu)
            const dateCGU = new Date(cmsCGUDate)
            if (localStorage.getItem(MEDEAU_TOKEN)) {
                if (dateUser.getTime() < dateCGU.getTime()) {
                    this.toggleModalCGU()
                } else if (!dateUser.getTime()) {
                    this.toggleModalCGU()
                } else if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
                    this.props.push('/admin')
                } else {
                    this.props.push('/home')
                }
            }
        })
    }

    sendDateValidCGU = () => {
        const { login } = this.state
        const { accountUser } = this.props
        const eventType = 'CGU'
        const module = 'CGU'
        const date = new Date()
        const postDateCGU = { login, eventType, module, version: date }
        this.props.sendDateCgu(postDateCGU).then(() => {
            this.toggleModalCGU()
            if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
                this.props.push('/admin')
            } else {
                this.props.push('/home')
            }
        })
    }

    onRefuseCGU = () => {
        this.props.logout()
        this.toggleModalCGU()
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    getLogoPartenaires = () => {
        const { classes } = this.props
        const { images } = this.state
        // const size = images.length % 2 === 0 ? 6 : 4
        return images.map((i, index) => {
            const img = (
                <img
                    src={`${CMS_PATH}${i.docName}`}
                    alt=''
                    className={`${classes.img} clickable`}
                    onClick={() => this.openWebSite(i.link)}
                    style={{
                        height: '100%',
                        width: 'auto',
                        maxWidth: '100%',
                    }}
                />
            )
            return (
                <Grid item key={index} style={{ height: '10vw', minHeight: '40%' }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        style={{ height: '100%' }}
                    >
                        {img}
                    </Grid>
                </Grid>
            )
        })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }

    render() {
        const { classes, cmsCGU } = this.props
        const { login,
            password,
            openModal,
            dataLoaded,
            settingsLoaded,
            passwordError,
            loginError,
            openModalPassword,
            backgroundUrl,
            logoUrl,
            loading,
        } = this.state
        return (
            <div
                style={{
                    height: '100%',
                    margin: '-70px 0',
                    backgroundColor: 'white',
                }}
            >
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='flex-end'
                    className={classes.gridTop}
                    style={{ backgroundImage: `url(${backgroundUrl})` }}
                />
                <Grid className={classes.logoTop}>
                    <img src={logoUrl} alt='' width='190px' />
                    <br />
                    <span>v{packageJson.version}</span>
                </Grid>
                <Grid
                    container
                    className='overflowY'
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    style={{ height: '40%', padding: '0 20px' }}
                >
                    <Grid item xs={12} md={3}>
                        <InputRow
                            id='login'
                            label={i18n.id}
                            type='text'
                            value={login}
                            onChange={(e) => this.handleChangeValue({ login: e.target.value, loginError: false })}
                            variant='outlined'
                            error={loginError}
                            helperText={loginError && i18n.fillField}
                            onKeyDown={(e) => this.onKeyDown(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputRow
                            id='login'
                            label={i18n.password}
                            type='password'
                            value={password}
                            onChange={(e) => this.handleChangeValue({ password: e.target.value, passwordError: false })}
                            variant='outlined'
                            error={passwordError}
                            helperText={passwordError && i18n.fillField}
                            onKeyDown={(e) => this.onKeyDown(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {!loading ? (
                            <MainButton variant='contained' onClick={this.onSubmit}>
                                {i18n.connect}
                            </MainButton>
                        ) : (
                            <LinearProgress />
                        )}
                    </Grid>
                    <Grid item xs={12} container justifyContent='center'>
                        <span onClick={() => this.props.push('/reset')} style={{ textAlign: 'center', color: managerBlue }}>
                            {i18n.forgetPassword}
                        </span>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    style={{ height: '30%', padding: '0 8%', overflowY: 'auto' }}
                >
                    {this.getLogoPartenaires()}
                </Grid>
                {dataLoaded && (
                    <ModalCGU
                        open={openModal}
                        cgu={cmsCGU}
                        onRefuse={this.onRefuseCGU}
                        onValidate={this.sendDateValidCGU}
                    />
                )}
                {settingsLoaded && openModalPassword && (
                    <ModalUpdatePassword
                        open={openModalPassword}
                        toggleModal={this.toggleModalPassword}
                        handleChangeNewMdp={(e) => this.handleChangeValue({ newMdp: e.target.value })}
                        handleChangeConfirmation={(e) => this.handleChangeValue({ mdpConfirmation: e.target.value })}
                        onSavePassword={this.onUpdatePassword}
                        firstLogin
                    />
                )}
            </div>
        )
    }
}

const mapDispatchToProps = {
    login: HomeAction.login,
    logout: HomeAction.logout,
    setDefaultPath: HomeAction.setDefaultPath,
    getDateValidCGU: HomeAction.getDateValidCGU,
    sendDateCgu: HomeAction.sendDateCgu,
    getCmsPartenaire: HomeAction.getCmsPartenaire,
    getCGUFromCms: HomeAction.getCGUFromCms,
    updatePassword: HomeAction.updatePassword,
    fetchUser: AccountAction.fetchUser,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    fetchCMSHome: HomeAction.fetchCMSHome,
    fetchCMSLogo: HomeAction.fetchCMSLogo,
    push,
}

const mapStateToProps = (store) => {
    return {
        dateValidCgu: store.HomeReducer.dateValidCgu,
        cms: store.HomeReducer.cms,
        cmsCGU: store.HomeReducer.cmsCGU,
        cmsLogo: store.HomeReducer.cmsLogo,
        accountUser: store.AccountReducer.accountUser,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

Login.propTypes = {
    push: PropTypes.func,
    login: PropTypes.func,
    logout: PropTypes.func,
    setDefaultPath: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.object),
    getDateValidCGU: PropTypes.func,
    sendDateCgu: PropTypes.func,
    dateValidCgu: PropTypes.string,
    getCmsPartenaire: PropTypes.func,
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    getCGUFromCms: PropTypes.func,
    fetchCMSHome: PropTypes.func,
    fetchCMSLogo: PropTypes.func,
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    fetchUser: PropTypes.func,
    updatePassword: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
    accountUser: PropTypes.instanceOf(DtoUser),
    cmsCGU: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
