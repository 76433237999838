/* eslint-disable indent */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Card, Container, Grid, Icon, LinearProgress, Modal } from '@mui/material'
import { groupBy, range } from 'lodash'
import HomeAction from '../../offline/actions/HomeAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import { MainButton } from '../components/styled/buttons'
import ContactAction from '../contact/actions/ContactAction'
import DtoContact from '../referencials/dto/DtoContact'
import AgriAction from '../agri/actions/AgriAction'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import EditableCard from '../components/cards/EditableCard'
import { pointStatus } from '../referencials/installations/constants/InstallationsConstants'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import { getDayDisplay, getTextSlot } from '../../../utils/AgriUtils'
import { getWeek } from '../../../utils/DateUtil'
import { Item } from '../components/styled/grid'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'

class PointWaterTurns extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: {},
            pointLoaded: false,
        }
    }

    componentDidMount() {
        const { match } = this.props
        this.props.fetchInstallation(match.params.id).then(() => {
            this.setInstallation()
        })
    }

    setInstallation = () => {
        const { installation, contacts } = this.props
        if (!contacts.length) {
            this.props.fetchContacts().then(() => {
                this.setContributors(installation)
            })
        } else {
            this.setContributors(installation)
        }
        this.props.setTitle([
            {
                title: i18n.myWaterTurns,
                href: '/waterTurns',
            },
            {
                title: `Point ${installation.code || ''}${isLargeWindow() && installation.location ? ` - ${installation.location}` : ''}`,
                href: `/waterTurns/point/${installation.id}`,
            },
        ])
        window.scrollTo(0, 0)
    }

    setContributors = (point) => {
        const { exploitation } = this.props
        const linkPoint = exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === point.id) : null
        this.setState({ point, linkPoint, pointLoaded: true })
    }

    getAssociatedResources = () => {
        const {
            point,
            linkPoint,
        } = this.state
        let associatedResources = linkPoint ? linkPoint.link_samples[0] : {}
        associatedResources = {
            ...associatedResources,
            managementCode: point.managementCode,
            subManagementCode: point.subManagementCode,
        }
        return associatedResources
    }

    getNature = () => {
        const associatedResources = this.getAssociatedResources()
        if (associatedResources) {
            const { codesSandre } = this.props
            const codeFind = codesSandre.find((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT' && c.code === associatedResources.sampleType) || {}
            return codeFind.name || ''
        }
        return ''
    }

    getStatus = () => {
        const { linkPoint } = this.state
        if (linkPoint) {
            const status = pointStatus.find(({ code }) => code === linkPoint.stateCode) || {}
            return status.label || ''
        }
        return ''
    }

    getCity = (point) => {
        const { citiesIndex } = this.props
        const city = citiesIndex[point.townCode]
        return city || {}
    }

    getModalRestrictions = (openModalRestrictions) => {
        const { waterTurnsRestrictions } = this.props
        return (
            <Modal
                open={openModalRestrictions}
                className='modal'
                style={{ bottom: 'inherit', zIndex: 1100, overflowY: 'hidden' }}
            >
                <Card style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
                    <Container
                        className='overflowY'
                        style={{
                            marginBottom: 60,
                            height: 'calc(100% - 60px)'
                        }}
                    >
                        <Item xs={12} style={{ padding: '0 16px' }}>
                            <h3>{i18n.waterTurnsRestrictions}</h3>
                        </Item>
                        {waterTurnsRestrictions.map((r) => {
                            return (
                                <Item xs={12} style={{ padding: '8 0' }}>
                                    <fieldset>
                                        <legend>
                                            <div style={{ backgroundColor: r.color || 'grey', display: 'inline-block', width: '20px', border: '1px solid black' }}>&nbsp;</div>&nbsp;
                                            <b>{r.label}</b>{` (${i18n.level} ${r.level})`}</legend>
                                        <p>{r.description}</p>
                                        <p>{r.comment}</p>
                                    </fieldset>
                                </Item>
                            )
                        })}
                    </Container>
                    <Container
                        justifyContent='space-between'
                        alignItems='center'
                        style={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            bottom: 0,
                            height: 60,
                            padding: '4px 16px',
                        }}
                    >
                        <MainButton reverse variant='contained' onClick={() => this.setState({ openModalRestrictions: false })}>
                            {i18n.close}
                        </MainButton>
                    </Container>
                </Card>
            </Modal>
        )
    }

    getContent = () => {
        const { exploitationWaterTurns, exploitation, waterTurnsRestrictions, waterTurnsSlots } = this.props
        const { point, openModalRestrictions } = this.state
        const data = exploitationWaterTurns.filter((w) => w.idExploitation === exploitation.idExploitation && w.idInstallation === point.id)
        const groupedSlotData = groupBy(data, 'idSlot')

        const city = this.getCity(point)
        const codeParcelle = point.link_geo[0] ? `${point.link_geo[0].parcel} ${point.link_geo[0].section}` : ''
        return (
            <>
                <Grid item xs={12}>
                    <EditableCard
                        title={i18n.descriptive}
                        hideIcon
                    >
                        <p>{i18n.name} : {point.name || ''}</p>
                        <p>{i18n.code} : {point.code || ''}</p>
                        <p>{i18n.naturePrel} : {this.getNature()}</p>
                        <p>{i18n.status} : {this.getStatus()}</p>
                        <p>{`${i18n.city} : ${city && city.name ? city.name : ''}`}</p>
                        <p>{i18n.codeParcelle} : {codeParcelle}</p>
                        <p>{i18n.lieuDit} : {point.location}</p>
                    </EditableCard>
                </Grid>
                <Grid item xs={12}>
                    <EditableCard
                        title={i18n.waterTurns}
                        hideIcon
                        otherIcon='help'
                        onClickOtherIcon={() => this.setState({ openModalRestrictions: true })}
                    >
                        {Object.keys(groupedSlotData).map(idSlotKey => {
                            const slot = waterTurnsSlots.find(wts => wts.id === parseInt(idSlotKey)) || { nbSlots: 1, id: -1 }
                            return (
                                <>
                                    <p><b>{i18n.ranges} : {getTextSlot(slot)}</b></p>
                                    {groupedSlotData[idSlotKey].map(d => {
                                        const slotsDisplay = range(slot.nbSlots || 1)
                                        return (
                                            <>
                                                <div style={{ display: isLargeWindow() ? 'flex' : '', overflowX: 'auto', paddingBottom: 8 }}>
                                                    {data ? getWeek().map((day) => (
                                                        getDayDisplay(d[day.value], day.label, slotsDisplay, waterTurnsRestrictions)
                                                    )) : i18n.noInformation}
                                                </div>
                                            </>
                                        )
                                    })}
                                </>
                            )
                        }).flat()}
                    </EditableCard>
                </Grid>
                <Grid item xs={12} className='padding-left-2 padding-right-2 padding-top-2'>
                    <MainButton
                        reverse
                        onClick={() => this.props.push('/waterTurns')}
                        margintop
                    >
                        <Icon>keyboard_arrow_left</Icon>
                        {i18n.myWaterTurns}
                    </MainButton>
                </Grid>
                {this.getModalRestrictions(openModalRestrictions)}
            </>
        )
    }

    render() {
        const { pointLoaded } = this.state
        return (
            <Grid container alignItems='center' justifyContent='center'>
                {pointLoaded ? this.getContent() : (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        installation: store.InstallationsReducer.installation,
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        codesSandre: store.ReferencialReducer.codesSandre,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        applicationSettings: store.HomeReducer.applicationSettings,
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchInstallation: InstallationsAction.fetchInstallation,
    updateInstallation: InstallationsAction.updateInstallation,
    fetchContacts: ContactAction.fetchContacts,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    push,
}

PointWaterTurns.propTypes = {
    installation: PropTypes.instanceOf(DtoInstallation),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    setTitle: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateInstallation: PropTypes.func,
    push: PropTypes.func,
    match: PropTypes.instanceOf(PropTypes.object),
    location: PropTypes.instanceOf(PropTypes.object),
    fetchContacts: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointWaterTurns)
