import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Fragment } from 'react'
import { Grid, LinearProgress, styled } from '@mui/material'
import { withStyles } from '@mui/styles'
import HomeAction from '../../offline/actions/HomeAction'
import PointsCard from '../components/cards/PointsCard'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import { orderPointsList } from '../../../utils/ObjectUtils'
import DtoCity from '../referencials/city/dto/DtoCity'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import { formatDate } from '../../../utils/DateUtil'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoDeclarationWithStats from '../agri/dto/enquete/DtoDeclarationWithStats'
import { hasValue } from '../../../utils/NumberUtil'

const styles = () => ({
    chronicleItem: {
        paddingTop: '8px',
        fontStyle: 'italic',
    },
})

const GridItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
    alignSelf: 'center',
})

class MyConsumption extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            pointsLoaded: false,
        }
    }

    componentDidMount() {
        this.props.setTitle([{
            title: i18n.myConso,
            href: 'consommation',
        }])
        this.getExploitation()
    }

    getExploitation = () => {
        this.props.fetchExploitation().then(() => {
            const { exploitation, installations } = this.props
            this.props.fetchLastDeclarationByExploitationId(exploitation.idExploitation).then(() => {
                this.setState({ dataLoaded: true })
            })
            if (!installations.length) {
                this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                    this.setState({ pointsLoaded: true })
                })
            } else {
                this.setState({ pointsLoaded: true })
            }
        })
    }

    getMateriels = (pointId) => {
        const { variousMatSituations, variousMateriels, exploitation } = this.props
        const pointPumps = variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === pointId) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((m) => [...pumpsIds, ...countersIds].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    getLastChronicle = (point) => {
        const { exploitation } = this.props
        const { materiels } = this.getMateriels(point.id)
        const pointChronicles = exploitation.link_chronicles.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!point.startDate || measureDate >= point.startDate) &&
            (!point.endDate || measureDate < point.endDate)
        )
        const lastChronicle = pointChronicles.length ? pointChronicles.reduce((max, chronique) => (max.measureDate > chronique.measureDate) ? max : chronique) : null
        if (lastChronicle) {
            const matFound = materiels.find((m) => m.id === lastChronicle.idMat) || {}
            return (
                <>
                    {lastChronicle.measureType === 1 ? (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} -> ${formatDate(lastChronicle.endDate)} : ${formatMilliers(lastChronicle.value) || 0} m3`}</span>
                        </>
                    ) : (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} : ${formatMilliers(lastChronicle.value) || 0}`}</span>
                        </>
                    )}
                </>
            )
        }
        return <span>{i18n.noConsumptionIndicated}</span>
    }

    onClickPoint = (point) => {
        this.props.push(`consommation/point/${point.id}`)
    }

    getPoints = () => {
        const { exploitation, installations, citiesIndex, classes } = this.props
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        exploitation.link_samplings.forEach((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                const pointFormatted = {
                    ...point,
                    cityName: city.name,
                    footerContent: (
                        <div className={classes.chronicleItem}>
                            {this.getLastChronicle(point)}
                        </div>
                    )
                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                    default:
                        pointsNotUsed.push(pointFormatted)
                }
            }
        })
        return {
            pointsUsed: orderPointsList(pointsUsed),
            pointsNotUsed: orderPointsList(pointsNotUsed),
            pointsDeleted: orderPointsList(pointsDeleted),
        }
    }

    render() {
        const { variousMatSituations, lastDeclaration } = this.props
        const { pointsLoaded, dataLoaded } = this.state
        if (pointsLoaded && variousMatSituations.length && dataLoaded) {
            const volumes = lastDeclaration && lastDeclaration.link_volumes && lastDeclaration.link_volumes.length ? lastDeclaration.link_volumes : []
            const allocatedVolume = Math.round(volumes.map((v) => v.attributedVolume).reduce((a, b) => a + b, 0))
            const authorizedVolume = Math.round(volumes.map((v) => v.authorizedVolume).reduce((a, b) => a + b, 0))
            const {
                pointsUsed,
                pointsNotUsed,
                pointsDeleted,
            } = this.getPoints()
            return (
                <>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                    >
                        {/* <GridItem item xs={6} className='textRight'>
                            {i18n.realVolume}
                        </GridItem>
                        <GridItem item xs={6} className='textRight valueLabel'>
                            {(formatMilliers(lastDeclaration.realVolume) || 0) + ' m3'}
                        </GridItem> */}
                        <GridItem item xs={6} className='textRight'>
                            {i18n.requestedVolume}
                        </GridItem>
                        <GridItem item xs={6} className='textRight valueLabel'>
                            {`${formatMilliers(lastDeclaration.prevVolume) || 0} m3`}
                        </GridItem>
                        <GridItem item xs={6} className='textRight'>
                            {i18n.allocatedVolume}
                        </GridItem>
                        <GridItem item xs={6} className='textRight valueLabel'>
                            {volumes.length ? `${formatMilliers(allocatedVolume)} m3` : '-'}
                        </GridItem>
                        <GridItem item xs={6} className='textRight'>
                            {i18n.authorizedVolume}
                        </GridItem>
                        <GridItem item xs={6} className='textRight valueLabel'>
                            {volumes.length ? `${formatMilliers(authorizedVolume)} m3` : '-'}
                        </GridItem>
                    </Grid>
                    <Grid
                        container
                        alignItems='stretch'
                        style={{ backgroundColor: 'white' }}
                    >
                        <PointsCard
                            pointsUsed={pointsUsed}
                            pointsNotUsed={pointsNotUsed}
                            pointsDeleted={pointsDeleted}
                            openUsedPoints
                            openNoUsedPoints
                            openDeletePoints
                            onClickPoint={this.onClickPoint}
                        />
                    </Grid>
                </>
            )
        }
        return (
            <Grid
                container
                alignItems='stretch'
                style={{ backgroundColor: 'white' }}
            >
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            </Grid>
        )
    }
}

MyConsumption.propTypes = {
    setTitle: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchLastDeclarationByExploitationId: PropTypes.func,
    push: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    classes: PropTypes.instanceOf(PropTypes.object),
    lastDeclaration: PropTypes.instanceOf(DtoDeclarationWithStats),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        codesSandre: store.ReferencialReducer.codesSandre,
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        lastDeclaration: store.AgriReducer.lastDeclaration,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchLastDeclarationByExploitationId: AgriAction.fetchLastDeclarationByExploitationId,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyConsumption))
