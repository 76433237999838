import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, Icon } from '@mui/material'
import { LinearProgress } from '@mui/material'
import Paper from '@mui/material/Paper'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { orderBy } from 'lodash'
import ToastrAction from '../components/toasters/ToastrAction'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import DtoContact from '../referencials/dto/DtoContact'
import DtoUser from '../account/dto/DtoUser'
import { formatPhone } from '../../../utils/StringUtil'
import { getLogin } from '../../../utils/UserUtils'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from './actions/ContactAction'

class MessagesApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messages: [],
            dataLoaded: false,
            message: '',
        }
    }

    componentDidMount() {
        const { cmsEvents = [], contacts, users } = this.props
        const homeCms = cmsEvents.find((cms) => cms.id === 3)
        if (!contacts.length) {
            this.props.fetchContacts()
        }
        if (!users.length) {
            this.props.fetchUsers()
        }
        this.props.setTitle([
            {
                title: `${i18n.contactOUGC} ${homeCms && homeCms.subtitle ? `l'${homeCms.subtitle}` : ''}`,
                href: 'contact',
            },
        ])
        this.getAllMessages()
    }

    componentDidUpdate() {
        window.scrollTo(0, document.body.scrollHeight)
    }

    getAllMessages = () => {
        this.props.getMessagesOf(getLogin()).then(() => {
            const { listOfMessages } = this.props
            const newMessages = !listOfMessages.length ? [] : listOfMessages
            this.setState({
                messages: newMessages,
                dataLoaded: true,
            })
        })
    }

    handleChangeMessage = (e) => {
        const value = e.target.value
        this.setState({ message: value })
    }

    sendMessage = () => {
        const { message } = this.state
        if (message) {
            const login = getLogin()
            const userMessage = { login, message }
            this.props.sendMessage(userMessage).then(() => {
                this.getAllMessages()
                this.setState({
                    message: '',
                })
            })
        } else {
            ToastrAction.warning('Pas de message à envoyer')
        }
    }

    showMessages = () => {
        const { messages } = this.state
        const { contacts, users } = this.props

        return orderBy(messages, 'updateDate').map((m) => {
            const user = users.find((u) => u.login === m.updateLogin) || {}
            const contact = contacts.find((c) => c.id === user.contactCode) || {}
            const phone = contact.phoneTel ? `${formatPhone(contact.phoneTel)} - ` : ''
            const mobile = contact.mobile ? `${formatPhone(contact.mobile)}` : ''
            const dateString = new Date(m.updateDate).toLocaleString()
            return (
                <Grid container direction='column'>
                    <Grid
                        container
                        direction='column'
                        alignItems={m.updateLogin === m.login ? 'flex-end' : 'flex-start'}
                    >
                        <Grid
                            container
                            style={{
                                backgroundColor: m.updateLogin === m.login ? '#f0f0f0' : 'white',
                                width: '90%',
                                padding: '10px',
                                margin: '10px 0 10px 0',
                                borderRadius: '10px',
                                border: 'solid 1px grey',
                            }}
                            direction='column'
                            alignItems='flex-start'
                        >
                            <span className='bold'>{`${m.updateLogin} (${contact.name || ''})`}</span>
                            {contact.phoneTel || contact.mobile ? <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{phone} {mobile}</span> : null}
                            {contact.email ? <span style={{ fontSize: '14px', fontStyle: 'italic', color: '#5691be' }}>{contact.email}</span> : null}
                            <Paper
                                style={{
                                    background: 'none',
                                    boxShadow: 'none',
                                    fontStyle: 'italic',
                                }}
                            >
                                {dateString}
                            </Paper>
                            <Paper style={{ background: 'none', boxShadow: 'none', whiteSpace: 'pre-wrap', borderTop: '1px solid lightgrey', width: '100%', marginTop: 8, paddingTop: 8 }}>
                                {m.message}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            )
        })
    }

    showInput = () => {
        return (
            <FormControl
                style={{
                    textfield: '25ch',
                    width: '100%',
                    marginTop: '20px',
                }}
                variant='outlined'
            >
                <InputLabel htmlFor='renseignement'>{i18n.anyInformation}</InputLabel>
                <OutlinedInput
                    id='renseignement'
                    type='text'
                    label={i18n.anyInformation}
                    value={this.state.message}
                    onChange={this.handleChangeMessage}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={this.sendMessage}
                                style={{
                                    backgroundColor: '#00beff',
                                    borderRadius: '100%',
                                    height: '40px',
                                    minWidth: '0',
                                    width: '40px',
                                }}
                            >
                                <Icon
                                    style={{
                                        height: '25px',
                                        width: '25px',
                                        color: 'white',
                                    }}
                                >
                                    send
                                </Icon>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )
    }

    render() {
        const { dataLoaded } = this.state

        return (
            <Grid style={{ padding: '15px' }}>
                {dataLoaded ? this.showMessages() : <LinearProgress />}
                {this.showInput()}
            </Grid>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    getMessagesOf: ContactAction.getMessagesOf,
    sendMessage: ContactAction.sendMessage,
    fetchContacts: ContactAction.fetchContacts,
    fetchUsers: HomeAction.fetchUsers,
    push,
}

const mapStateToProps = (store) => {
    return {
        listOfMessages: store.ContactReducer.listOfMessages,
        contacts: store.ContactReducer.contacts,
        cmsEvents: store.CmsReducer.cmsEvents,
        users: store.HomeReducer.users,
    }
}

MessagesApp.propTypes = {
    setTitle: PropTypes.func,
    getMessagesOf: PropTypes.func,
    sendMessage: PropTypes.func,
    fetchContacts: PropTypes.func,
    fetchUsers: PropTypes.func,
    listOfMessages: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.string)),
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(DtoUser)),
    push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesApp)
