module.exports = {
    RECEIVE_EXPLOITATION: 'RECEIVE_EXPLOITATION',
    RECEIVE_EXPLOITATIONS_DATA: 'RECEIVE_EXPLOITATIONS_DATA',
    RECEIVE_EXPLOITATION_VOLUMES: 'RECEIVE_EXPLOITATION_VOLUMES',
    RECEIVE_EXPLOITATIONS: 'RECEIVE_EXPLOITATIONS',
    RECEIVE_DECLARATION: 'RECEIVE_DECLARATION',
    RECEIVE_LAST_DECLARATION: 'RECEIVE_LAST_DECLARATION',
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION: 'RECEIVE_DECLARATIONS_STATS_EXPLOITATION',
    RECEIVE_DECLARATIONS: 'RECEIVE_DECLARATIONS',
    RECEIVE_DECLARATION_CONTRIBUTORS: 'RECEIVE_DECLARATION_CONTRIBUTORS',
    RECEIVE_ENQUETE: 'RECEIVE_ENQUETE',
    RECEIVE_ENQUETES: 'RECEIVE_ENQUETES',
    RECEIVE_OPERATOR: 'RECEIVE_OPERATOR',
    RECEIVE_OPERATORS: 'RECEIVE_OPERATORS',
    RECEIVE_MODES_IRRIGATIONS: 'RECEIVE_MODES_IRRIGATIONS',
    RECEIVE_CALCULATED_VOLUME: 'RECEIVE_CALCULATED_VOLUME',
    RECEIVE_SURVEY: 'RECEIVE_SURVEY',
    RECEIVE_SURVEYS: 'RECEIVE_SURVEYS',
    RECEIVE_WATERTURNS_RESTRICTIONS: 'RECEIVE_WATERTURNS_RESTRICTIONS',
    RECEIVE_WATERTURNS_EXPLOITATION: 'RECEIVE_WATERTURNS_EXPLOITATION',
    RECEIVE_WATERTURNS: 'RECEIVE_WATERTURNS',
    RECEIVE_WATERTURNS_SLOTS: 'RECEIVE_WATERTURNS_SLOTS',
    RECEIVE_TANKS_TYPES: 'RECEIVE_TANKS_TYPES'
}
