/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { compact, groupBy, orderBy, uniq } from 'lodash'
import { styled, Grid, LinearProgress, Divider, Icon } from '@mui/material'
import moment from 'moment'
import { MainButton, OrangeButton } from '../components/styled/buttons'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import CmsAction from '../cms/actions/CmsAction'
import { getMiniDate, getMiniDateNoYear } from '../../../utils/DateUtil'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import { hasValue } from '../../../utils/NumberUtil'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import DtoMatEventsType from '../referencials/materiels/dto/DtoMatEventsType'
import { HelpTooltip } from '../components/styled/texts'
import EditableCard from '../components/cards/EditableCard'
import ModalMatEvent from './ModalMatEvent'
import ModalConso from './ModalConso'

const GridItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
    alignSelf: 'center',
})

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

class PointConso extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            idInstallation: parseInt(props.match.params.id),
            point: {},
            installation: {},
            openModalIndex: false,
            openModalEvent: false,
            chroniclePumpId: null,
            chronicleCounterId: null,
            numberOfLines: 5,
            year: new Date().getFullYear(),
            realConso: null,
            selectedEvent: null,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        const { idInstallation, year } = this.state
        this.props.fetchCmsByCategory(5)
        this.props.fetchInstallation(idInstallation).then(() => {
            this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
                const { installation } = this.props
                if (!exploitation.idExploitation) {
                    this.props.fetchExploitation().then(() => this.setState({ realConso: res?.total, dataLoaded: true }))
                } else {
                    this.setState({ realConso: res?.total, dataLoaded: true })
                }
                this.props.setTitle([
                    {
                        title: i18n.myConso,
                        href: '/consommation',
                    },
                    {
                        title: `Point ${installation.code || ''}${isLargeWindow() && installation.location ? ` - ${installation.location}` : ''}`,
                        href: `/consommation/point/${installation.id}`,
                    },
                ])
            })
        })
        window.scrollTo(0, 0)
    }

    getMateriels = () => {
        const { variousMatSituations, variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const pointPumps = variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((c) => [...pumpsIds, ...countersIds].includes(c.id) && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    editModalIndex = (type, id) => {
        this.setState({ selectedMaterial: id, consoType: type, openModalIndex: true })
    }

    getColorRealConso = (authorizedVolume, realConso) => {
        if (!hasValue(authorizedVolume) || !hasValue(realConso)) {
            return 'black'
        }
        if (realConso > authorizedVolume) {
            return 'red'
        }
        if (((realConso / authorizedVolume) * 100) >= 80) {
            return 'orange'
        }
        return 'green'
    }

    getEvolValue = (type, chronicles, chronicle, index) => {
        if (type === TYPE_INDEX) {
            return chronicle.value - chronicles[index + 1].value
        }
        return Math.round(chronicles.slice(index).reduce((acc, d) => acc + d.value, 0))
    }

    getChroniclesByType = (chroniclesByType, type, materiel, readingCoefficient = 1) => {
        if (chroniclesByType.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const { numberOfLines, year } = this.state
            const { matEventsTypes } = this.props
            const props = {
                onClick: () => this.editModalIndex(type, materiel.id),
            }
            return (
                <>
                    <GridItem item className='italic' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                    </GridItem>
                    <GridItem item className='italic' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.endDate : ''}
                    </GridItem>
                    <GridItem item className='italic' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                    </GridItem>
                    <GridItem item className='italic-label' xs={3}>
                        {`${type === TYPE_ESTIM ? 'Cumul' : i18n.evolution} en m3`}
                    </GridItem>
                    {orderedChronicles.slice(0, numberOfLines).map((chronicle, i) => {
                        if (chronicle.event) {
                            const onClickEvent = {
                                onClick: () => {
                                    this.setState({ openModalEvent: true, selectedEvent: chronicle })
                                },
                            }
                            return (
                                <>
                                    <GridItem item xs={3} {...onClickEvent}>
                                        {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                    </GridItem>
                                    <HelpTooltip title={chronicle.comment} arrow>
                                        <GridItem item xs={3} {...onClickEvent}><Icon>warning</Icon></GridItem>
                                    </HelpTooltip>
                                    <GridItem item xs={3} {...onClickEvent}>
                                        {(matEventsTypes.find(({ id }) => id === chronicle.idType) || {}).name || ''}
                                    </GridItem>
                                    <GridItem item xs={3} />
                                </>
                            )
                        }
                        const valueEvol = i !== orderedChronicles.length - 1 ? this.getEvolValue(type, orderedChronicles, chronicle, i) : null
                        return (
                            <>
                                <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props}>
                                    {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                </GridItem>
                                <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props}>
                                    {chronicle.endDate ? (new Date(chronicle.endDate).getFullYear() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                </GridItem>
                                <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props}>
                                    {formatMilliers(chronicle.value) || 0}
                                </GridItem>
                                <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={valueEvol < 0 ? { color: 'orange' } : {}}>
                                    {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                </GridItem>
                            </>
                        )
                    })}
                    <GridItem item xs={11}>
                        <Divider />
                    </GridItem>
                </>
            )
        }
        return null
    }

    getChronicles = (groupedChronicles) => {
        const { variousMateriels, exploitation } = this.props
        return compact(groupedChronicles.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === TYPE_INDEX || c.event)
            const estimateChronicles = chronicles.filter((c) => c.measureType === TYPE_ESTIM)
            const readingCoefficient = variousMat && variousMat.counter && variousMat.counter.readingCoefficient
            return (
                <Grid
                    item
                    container
                    direction='row'
                    justifyContent='center'
                    className='clickable'
                >
                    <GridItem item xs={11} className='bold' style={{ textAlign: 'start' }}>
                        {variousMat.reference}
                    </GridItem>
                    {this.getChroniclesByType(indexChronicles, TYPE_INDEX, variousMat, readingCoefficient)}
                    {this.getChroniclesByType(estimateChronicles, TYPE_ESTIM, variousMat)}
                </Grid>
            )
        }))
    }

    getVolumes = (pointVolumes, estimConso, realConso) => (
        <Grid
            item
            xs={12}
            container
            direction='row'
            alignItems='center'
        >
            <GridItem item xs={7} className='textRight'>
                {i18n.requestedVolume}
            </GridItem>
            <GridItem item xs={5} className='textRight valueLabel'>
                {hasValue(pointVolumes.askedVolume) ? `${formatMilliers(pointVolumes.askedVolume)} m3` : '-'}
            </GridItem>
            <GridItem item xs={7} className='textRight'>
                {i18n.allocatedVolume}
            </GridItem>
            <GridItem item xs={5} className='textRight valueLabel'>
                {hasValue(pointVolumes.attributedVolume) ? `${formatMilliers(pointVolumes.attributedVolume)} m3` : '-'}
            </GridItem>
            <GridItem item xs={7} className='textRight'>
                {i18n.authorizedVolume}
            </GridItem>
            <GridItem item xs={5} className='textRight valueLabel'>
                {hasValue(pointVolumes.authorizedVolume) ? `${formatMilliers(pointVolumes.authorizedVolume)} m3` : '-'}
            </GridItem>
            <GridItem item xs={7} className='textRight'>
                {i18n.estimConsumption}
            </GridItem>
            <GridItem item xs={5} className='textRight valueLabel'>
                {hasValue(estimConso) ? `${formatMilliers(estimConso)} m3` : '-'}
            </GridItem>
            <GridItem item xs={7} className='textRight'>
                {i18n.realConsumption}
            </GridItem>
            <GridItem item xs={5} className='textRight valueLabel' style={{ color: this.getColorRealConso(pointVolumes.authorizedVolume, realConso) }}>
                {hasValue(realConso) ? `${formatMilliers(realConso)} m3` : '-'}
            </GridItem>
        </Grid>
    )

    getEstimConso = (groupedChronicles) => {
        return groupedChronicles.map((chroniques) => {
            return chroniques.filter((c) => c.measureType === 1).reduce((acc, c) => acc + c.value, 0) || 0
        }).reduce((acc, c) => acc + c, null)
    }

    onChangeYear = (year) => {
        const { idInstallation } = this.state
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            this.setState({ year, numberOfLines: 5, realConso: res?.total })
        })
    }

    getContent = (pumpsIds, countersIds) => {
        const { exploitation, exploitationVolumes, matEventsExploitation } = this.props
        const { idInstallation, year, realConso } = this.state
        const pointVolumes = exploitationVolumes.find((v) => v.idInstallation === idInstallation && v.askedYear === year) || {}
        const chroniclesMat = exploitation.link_chronicles.filter(({ idMat, mode }) => [...pumpsIds, ...countersIds].includes(idMat) && mode !== 'd')
        const pointChronicles = chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year)
        const pointChroniclePrevYear = orderBy(chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year - 1), ['measureDate', 'endDate'], 'desc')[0]
        const chroniclesToShow = pointChroniclePrevYear ? [...pointChronicles, pointChroniclePrevYear] : [...pointChronicles]
        // const lastChronicle = pointChronicles.length ? pointChronicles.reduce((max, chronique) => (max.measureDate > chronique.measureDate) ? max : chronique) : null
        const eventsMat = matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel))
        const pointEvents = eventsMat.filter(({ eventDate }) => new Date(eventDate).getFullYear() === year)
        const pointEventsFormatted = pointEvents.map((e) => {
            return {
                ...e,
                idMat: e.idMateriel,
                measureDate: e.eventDate,
                event: true,
            }
        })

        const groupedByMat = Object.values(groupBy([...chroniclesToShow, ...pointEventsFormatted], 'idMat'))
        const estimConso = groupedByMat.length ? this.getEstimConso(groupedByMat) : null
        return (
            <>
                <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{ padding: '10 10 0 10' }}
                >
                    <Grid item>
                        <MainButton
                            reverse
                            variant='contained'
                            onClick={() => this.onChangeYear(year - 1)}
                        >
                            <Icon>keyboard_arrow_left</Icon>
                            {year - 1}
                        </MainButton>
                    </Grid>
                    <Grid item>
                        <b style={{ fontSize: '1.5em' }}>{year}</b>
                    </Grid>
                    <Grid item>
                        <MainButton
                            reverse
                            variant='contained'
                            onClick={() => this.onChangeYear(year + 1)}
                            style={year === new Date().getFullYear() ? { visibility: 'hidden' } : {}}
                        >
                            {year + 1}
                            <Icon>keyboard_arrow_right</Icon>
                        </MainButton>
                    </Grid>
                    {/* {lastChronicle ? (
                        <span style={{ textAlign: 'center' }}>
                            {i18n.lastValueInDate}
                            {lastChronicle.measureType === 1 ? (
                                <h2 style={{ whiteSpace: 'pre-line' }}>{`${getMiniDate(lastChronicle.measureDate)} -> ${getMiniDate(lastChronicle.endDate)}\n${formatMilliers(lastChronicle.value) || 0} m3`}</h2>
                            ) : (
                                <h2>{`${getMiniDate(lastChronicle.measureDate)} - ${formatMilliers(lastChronicle.value) || 0}`}</h2>
                            )}
                        </span>
                    ) : (
                        <h2>Aucune donnée</h2>
                    )} */}
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                >
                    <Grid item xs={12}>
                        <EditableCard
                            title='Volumes'
                            hideIcon
                        >
                            {this.getVolumes(pointVolumes, estimConso, realConso)}
                        </EditableCard>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCard
                            title='Details du prélèvement'
                            hideIcon
                        >
                            <>
                                {this.getChronicles(groupedByMat)}
                                <MainButton
                                    variant='contained'
                                    reverse
                                    onClick={this.showMore}
                                >
                                    {i18n.showMoreHistorical}
                                </MainButton>
                            </>
                        </EditableCard>
                    </Grid>
                </Grid>
            </>
        )
    }

    onCancelIndex = () => {
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
        })
    }

    onSaveChronicles = (link_chronicles) => {
        const { exploitation } = this.props
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        this.setState({
            selectedMaterial: null,
            consoType: null,
        })
        this.props.updateExploitation(updateExploitation).then(() => {
            this.props.fetchExploitation().then(() => {
                this.toggleState('openModalIndex')
            })
        })
    }

    showMore = () => {
        this.setState(({ numberOfLines }) => ({
            numberOfLines: numberOfLines + 5,
        }))
    }

    onDeleteEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        this.props.deleteMatEvent(event.id, () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    onValidateEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        if (event.id) {
            this.props.updateMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            ).then(() => {
                this.setState({ dataLoaded: true })
            })
        } else {
            this.props.createMatEvent(event).then(() => {
                this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
                    this.setState({ dataLoaded: true })
                })
            })
        }
    }

    render() {
        const {
            openModalIndex,
            point,
            selectedMaterial,
            consoType,
            openModalEvent,
            dataLoaded,
            selectedEvent,
        } = this.state
        const { variousMateriels, variousMatSituations, exploitation } = this.props
        const { pumpsIds, countersIds, materiels } = this.getMateriels()
        const exploitationMatIds = materiels.map(({ id }) => id)
        return (
            <>
                <Grid container alignItems='center' style={{ paddingBottom: 120 }}>
                    {variousMatSituations.length && variousMateriels.length && dataLoaded ? (
                        this.getContent(pumpsIds, countersIds)
                    ) :
                        <Grid item xs={12} style={{ margin: '16px 0' }}>
                            <LinearProgress />
                        </Grid>
                    }
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        height: 160,
                        backgroundColor: 'white',
                        zIndex: '1000',
                        padding: '5px 10px',
                    }}
                >
                    <Grid item xs={12} md={9}>
                        <MainButton
                            variant='contained'
                            onClick={() => this.toggleState('openModalIndex')}
                        >
                            {i18n.addConso}
                        </MainButton>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <OrangeButton
                            variant='contained'
                            onClick={() => this.toggleState('openModalEvent')}
                        >
                            <Icon style={{ marginRight: 8 }}>warning</Icon>
                            {i18n.reportIncident}
                        </OrangeButton>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <MainButton
                            reverse
                            variant='contained'
                            onClick={() => this.props.push('/consommation')}
                        >
                            <Icon>keyboard_arrow_left</Icon>
                            {i18n.myConso}
                        </MainButton>
                    </Grid>
                </Grid>
                {openModalIndex && (
                    <ModalConso
                        open={openModalIndex}
                        point={point}
                        pumpsIds={uniq(pumpsIds.filter((id) => exploitationMatIds.includes(id)))}
                        countersIds={uniq(countersIds.filter((id) => exploitationMatIds.includes(id)))}
                        selectedMaterial={selectedMaterial}
                        consoType={consoType}
                        onSave={this.onSaveChronicles}
                        onCancel={this.onCancelIndex}
                        chronicles={exploitation.link_chronicles}
                    />
                )}
                {openModalEvent && (
                    <ModalMatEvent
                        open={openModalEvent}
                        event={selectedEvent}
                        materiels={materiels}
                        onCancel={() => this.setState({ openModalEvent: false, selectedEvent: null })}
                        onDelete={this.onDeleteEvent}
                        onValidate={this.onValidateEvent}
                    />
                )}
            </>
        )
    }
}

PointConso.propTypes = {
    match: PropTypes.instanceOf(PropTypes.object),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchInstallation: PropTypes.func,
    fetchExploitation: PropTypes.func,
    updateExploitation: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    createMatEvent: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    calculPointVolumeReal: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    updateMatEvent: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
}

const mapStateToProps = (store) => {
    return {
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        cms: store.CmsReducer.cms,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallation: InstallationsAction.fetchInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    createMatEvent: MaterielAction.createMatEvent,
    updateMatEvent: MaterielAction.updateMatEvent,
    deleteMatEvent: MaterielAction.deleteMatEvent,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    calculPointVolumeReal: AgriAction.calculPointVolumeReal,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointConso)
