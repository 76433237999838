import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid, Card, Icon, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { withStyles } from '@mui/styles'
import DtoInstallation from '../../referencials/installations/dto/DtoInstallation'
import DtoCity from '../../referencials/city/dto/DtoCity'
import { Container, Item } from '../styled/grid'

const styles = () => ({
    title: {
        backgroundColor: '#ccc',
        fontWeight: 'bold',
        color: 'black',
        borderTop: '1px solid grey',
        borderBottom: '1px solid grey',
    },
    paper: {
        backgroundColor: '#dfdfdf',
        borderBottom: '1px solid grey',
    },
    listItem: {
        borderBottom: '1px solid grey',
        padding: '8px 16px',
        cursor: 'pointer',
    },
})

class PointsCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openUsedPoints: props.openUsedPoints || true,
            openNoUsedPoints: props.openNoUsedPoints || (props.pointsNotUsed.length && !props.pointsUsed.length),
            openDeletePoints: props.openDeletePoints || (props.pointsDeleted.length && !props.pointsNotUsed.length && !props.pointsUsed.length),
        }
    }

    toggleAccordeon = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    goToDetailsPoint = (id) => {
        this.props.push(`/exploitation/point/${id}`)
    }

    getAccordeon = (points, title, keyState) => {
        const { classes, citiesIndex } = this.props

        return (
            <>
                <Accordion
                    defaultExpanded={this.state[keyState]}
                    TransitionProps={{ unmountOnExit: true, timeout: 250 }}
                >
                    <AccordionSummary
                        expandIcon={<Icon style={{ border: '1px solid', borderRadius: '50%' }}>expand_more</Icon>}
                        aria-label='Expand'
                        aria-controls='additional-actions1-content'
                        id='additional-actions1-header'
                        className={`${classes.paper} clickable`}
                    >
                        <Item>
                            <span style={{ fontSize: '1.7rem' }}>{points.length}</span>
                            {title}
                        </Item>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                        <Grid container>
                            {points.map((p) => {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        key={p.id}
                                        className={`${classes.listItem} clickable`}
                                        onClick={this.props.onClickPoint ? () => this.props.onClickPoint(p) : () => this.goToDetailsPoint(p.id)}
                                    >
                                        <Grid item xs={p.color ? 11 : 12}>
                                            <Grid><b>{city ? city.name : ''}</b></Grid>
                                            <Grid>{p.location || ''}</Grid>
                                            <Grid><b>{p.code || ''}</b>{` ${`- ${p.parcel || ''} ${p.section || ''}${p.name ? ` - ${p.name}` : ''}`}`}</Grid>
                                            {p.footerContent}
                                        </Grid>
                                        {p.color && <Grid item xs={1} style={{ backgroundColor: p.color, border: '1px solid black' }} />}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    render() {
        const {
            classes,
            pointsUsed,
            pointsNotUsed,
            pointsDeleted,
            cities = [],
            map,
            style,
            className,
        } = this.props
        const total = pointsUsed.length + pointsNotUsed.length + pointsDeleted.length

        if (cities.length) {
            return (
                <Card className={className} style={{ width: '100%', ...style }}>
                    <Container
                        justifyContent='space-between'
                        alignItems='center'
                        className={classes.title}
                    >
                        <Item />
                        <Item>
                            <p>
                                <b style={{ fontSize: '1.7rem' }}>{total}</b>
                                {total > 1
                                    ? ` ${i18n.pointsPrelevement}`
                                    : ` ${i18n.pointPrelevement}`}
                            </p>
                        </Item>
                        <Item>
                            {map && (
                                <Icon
                                    className='clickable'
                                    onClick={() => this.props.push('/exploitation/map')}
                                    style={{ fontSize: '3rem', paddingRight: 10 }}
                                >
                                    map
                                </Icon>

                            )}
                        </Item>
                    </Container>
                    <>
                        {pointsUsed.length ? this.getAccordeon(pointsUsed, pointsUsed.length > 1 ? ` ${i18n.usedPoints}` : ` ${i18n.usedPoint}`, 'openUsedPoints') : null}
                        {pointsNotUsed.length ? this.getAccordeon(pointsNotUsed, pointsNotUsed.length > 1 ? ` ${i18n.noUsedPoints}` : ` ${i18n.noUsedPoint}`, 'openNoUsedPoints') : null}
                        {pointsDeleted.length ? this.getAccordeon(pointsDeleted, pointsDeleted.length > 1 ? ` ${i18n.deletePoints}` : ` ${i18n.deletePoint}`, 'openDeletePoints') : null}
                    </>
                </Card>
            )
        }
        return ''
    }
}

PointsCard.propTypes = {
    pointsUsed: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    pointsNotUsed: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    pointsDeleted: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    classes: PropTypes.instanceOf(PropTypes.object),
    style: PropTypes.instanceOf(PropTypes.object),
    className: PropTypes.string,
    push: PropTypes.func,
    onClickPoint: PropTypes.func,
    openUsedPoints: PropTypes.bool,
    openNoUsedPoints: PropTypes.bool,
    openDeletePoints: PropTypes.bool,
    map: PropTypes.bool,
}

PointsCard.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PointsCard))
