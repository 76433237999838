import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { InputRow } from '../styled/inputs'
import DtoCity from '../../referencials/city/dto/DtoCity'
import { isEmpty } from '../../../../utils/ObjectUtils'
import EditableCard from './EditableCard'

class LocalizationCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data
        }
    }

    getSelectedItem = () => {
        const { citiesIndex } = this.props
        const { data } = this.state
        const city = citiesIndex[data.townCode]
        return city || {}
    }

    getContent = (editMode) => {
        const { data } = this.props
        const city = this.getSelectedItem()

        if (editMode) {
            return (
                <>
                    <InputRow item id='city' label={i18n.city} type='text' variant='outlined' />
                    <InputRow item id='parcel' label={i18n.codeParcelle} type='text' variant='outlined' />
                    <InputRow item id='address' label={i18n.lieuDit} type='text' variant='outlined' />
                    <InputRow item id='x' label={i18n.xLambert} type='text' variant='outlined' />
                    <InputRow item id='y' label={i18n.yLambert} type='text' variant='outlined' />
                </>
            )
        }
        const codeParcelle = data.link_geo[0] ? `${data.link_geo[0].parcel} ${data.link_geo[0].section}` : ''
        return (
            isEmpty(data) ? (
                <p>{i18n.noInformation}</p>
            ) : (
                <>
                    <p>{`${i18n.city} : ${city && city.name ? city.name : ''}`}</p>
                    <p>{i18n.codeParcelle} : {codeParcelle}</p>
                    <p>{i18n.lieuDit} : {data.location}</p>
                    <p>{i18n.xLambert} : {data.x}</p>
                    <p>{i18n.yLambert} : {data.y}</p>
                </>
            )
        )
    }

    render() {
        const { title, editMode, hideIcon, cities, styleContainer, style } = this.props
        if (cities.length) {
            return (
                <EditableCard
                    title={title}
                    editMode={editMode}
                    toggleEditMode={this.props.toggleEditMode}
                    hideIcon={hideIcon}
                    styleContainer={styleContainer}
                    style={style}
                >
                    {this.getContent(editMode)}
                </EditableCard>
            )
        }
        return ''
    }
}

LocalizationCard.propTypes = {
    title: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    selectType: PropTypes.bool,
    hideIcon: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    data: PropTypes.instanceOf(PropTypes.object),
    styleContainer: PropTypes.instanceOf(PropTypes.object),
    style: PropTypes.instanceOf(PropTypes.object),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
}

LocalizationCard.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
    }
}


export default connect(mapStateToProps)(LocalizationCard)
