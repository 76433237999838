import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, LinearProgress } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import DtoContact from '../referencials/dto/DtoContact'
import ToastrAction from '../components/toasters/ToastrAction'
import { MainButton } from '../components/styled/buttons'
import { isValidEmail } from '../../../utils/FormUtils'
import { InputRow } from '../components/styled/inputs'
import { Item } from '../components/styled/grid'
import EditableCard from '../components/cards/EditableCard'
import DtoUser from './dto/DtoUser'
import AccountAction from './actions/AccountAction'
import ModalUpdatePassword from './components/ModalUpdatePassword'

class MyAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            openModal: false,
            isUnvalidEmail: false,
            contact: {},
            name: '',
            phone: '',
            email: '',
            dateValidCgu: '',
            mdp: '',
            newMdp: '',
            mdpConfirmation: '',
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.myAccount,
                href: 'account',
            },
        ])
        this.getContact()
        this.getDateCgu()
    }

    handleChangeValue = (value) => {
        this.setState({ ...value })
    }

    getContact = () => {
        const login = getLogin()
        this.props.fetchUser(login).then(() => {
            const { accountUser } = this.props
            if (accountUser.contactCode) {
                this.props.fetchContact(accountUser.contactCode).then(() => {
                    const { contact } = this.props
                    this.setState({
                        dataLoaded: true,
                        contact: contact.id ? contact : new DtoContact({}),
                        name: contact.name || '',
                        phone: contact.phoneTel || '',
                        email: contact.email || '',
                    })
                })
            } else {
                this.createContact(login)
            }
        })
    }

    createContact = (login) => {
        const { accountUser } = this.props
        this.props.createContact({ name: login }).then((contactCode) => {
            this.props.updateUser({ ...accountUser, contactCode, contactName: login })
            this.props.fetchContact(contactCode).then(() => {
                const { contact } = this.props
                this.setState({
                    dataLoaded: true,
                    contact: contact.id ? contact : new DtoContact({}),
                    name: contact.name || '',
                    phone: contact.phoneTel || '',
                    email: contact.email || '',
                })
            })
        })
    }

    getDateCgu = () => {
        const login = getLogin()
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu } = this.props
            this.setState({ dateValidCgu })
        })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSavePassword = () => {
        const { newMdp, mdpConfirmation, mdp } = this.state
        const { applicationSettings } = this.props
        const login = getLogin()
        if (mdp) {
            this.props.login(login, mdp).then(() => {
                if (
                    newMdp !== null &&
                    newMdp.length &&
                    mdpConfirmation !== null &&
                    mdpConfirmation.length
                ) {
                    if (newMdp === mdpConfirmation) {
                        const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                        if (regex.test(newMdp)) {
                            if (newMdp !== login) {
                                const newMdpEncoding = btoa(unescape(encodeURIComponent(newMdp)))
                                const password = { password: newMdpEncoding }
                                this.props.changePassword(password)
                                this.toggleModal()
                            } else {
                                ToastrAction.error('Le mot de passe doit être différent de celui qui vous a été fourni', true)
                            }
                        } else {
                            ToastrAction.error(regexHelp, true)
                        }
                    } else {
                        ToastrAction.error('Les mots de passe ne sont pas identiques', true)
                    }
                }
            })
        }
    }

    onSubmit = () => {
        const { contact, name, phone, email } = this.state
        const newContact = { ...contact, name, phoneTel: phone, email }
        if (isValidEmail(email)) {
            this.setState({ isUnvalidEmail: false })
            this.props.updateContact(newContact).then(() => {
                this.getContact()
            })
        } else {
            this.setState({ isUnvalidEmail: true })
        }
    }

    onSubmitPassword = () => {
        this.toggleModal()
    }

    render() {
        const login = getLogin()
        const { dataLoaded, openModal, dateValidCgu, name, phone, email, isUnvalidEmail } = this.state
        const date = new Date(dateValidCgu).toLocaleString()
        return (
            <>
                {dataLoaded ? (
                    <Grid container>
                        <Grid
                            container
                            direction='column'
                            justifyContent='space-around'
                            className='padding-top-1'
                        >
                            <EditableCard
                                title={`${i18n.myAccount} : ${login}`}
                                hideIcon
                            >
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    spacing={1}
                                >
                                    <Grid item xs={12} md={6}>
                                        <InputRow
                                            id='compte'
                                            label='Nom'
                                            type='text'
                                            value={name}
                                            onChange={(event) =>
                                                this.handleChangeValue({ name: event.target.value })
                                            }
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputRow
                                            id='compte'
                                            label='Tel'
                                            value={phone}
                                            type='number'
                                            onChange={(event) =>
                                                this.handleChangeValue({ phone: event.target.value })
                                            }
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputRow
                                            id='compte'
                                            label='Email'
                                            type='text'
                                            value={email}
                                            onChange={(event) =>
                                                this.handleChangeValue({ email: event.target.value })
                                            }
                                            variant='outlined'
                                            error={isUnvalidEmail}
                                            helperText={isUnvalidEmail ? 'Adresse non valide' : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MainButton
                                            variant='contained'
                                            onClick={this.onSubmit}
                                        >
                                            {i18n.save}
                                        </MainButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MainButton
                                            reverse
                                            variant='contained'
                                            onClick={this.onSubmitPassword}
                                        >
                                            {i18n.changePassword}
                                        </MainButton>
                                    </Grid>
                                </Grid>
                            </EditableCard>
                        </Grid>
                        <Grid
                            container
                            direction='column'
                            justifyContent='space-around'
                        >
                            <EditableCard
                                title='CGU'
                                hideIcon
                            >
                                <>
                                    {`${i18n.lastAccept} ${date}`}
                                    <Item>
                                        <MainButton
                                            reverse
                                            onClick={() => this.props.push('/about')}
                                            margintop
                                        >
                                            {i18n.consultCGU}
                                        </MainButton>
                                    </Item>
                                </>
                            </EditableCard>
                        </Grid>
                        <ModalUpdatePassword
                            open={openModal}
                            toggleModal={this.toggleModal}
                            handleChangeMdp={(e) =>
                                this.handleChangeValue({ mdp: e.target.value })
                            }
                            handleChangeNewMdp={(e) =>
                                this.handleChangeValue({ newMdp: e.target.value })
                            }
                            handleChangeConfirmation={(e) =>
                                this.handleChangeValue({ mdpConfirmation: e.target.value })
                            }
                            onSavePassword={this.onSavePassword}
                        />
                    </Grid>
                ) : (
                    <LinearProgress />
                )}
            </>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchUser: AccountAction.fetchUser,
    updateUser: AccountAction.updateUser,
    fetchContact: ContactAction.fetchContact,
    updateContact: ContactAction.updateContact,
    createContact: ContactAction.createContact,
    getDateValidCGU: HomeAction.getDateValidCGU,
    changePassword: AccountAction.changePassword,
    login: HomeAction.login,
    push,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        contact: store.ContactReducer.contact,
        dateValidCgu: store.HomeReducer.dateValidCgu,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

MyAccount.propTypes = {
    setTitle: PropTypes.func,
    fetchUser: PropTypes.func,
    updateUser: PropTypes.func,
    fetchContact: PropTypes.func,
    updateContact: PropTypes.func,
    createContact: PropTypes.func,
    getDateValidCGU: PropTypes.func,
    changePassword: PropTypes.func,
    login: PropTypes.func,
    accountUser: PropTypes.instanceOf(DtoUser),
    contact: PropTypes.instanceOf(DtoContact),
    dateValidCgu: PropTypes.array,
    applicationSettings: PropTypes.array,
    push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
