import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Icon, Grid, Breadcrumbs } from '@mui/material'
import { compact, orderBy } from 'lodash'
import ReferencialAction from '../referencials/actions/ReferencialAction'

import AgriAction from '../agri/actions/AgriAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import HomeAction from '../../offline/actions/HomeAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import CityAction from '../referencials/city/actions/CityAction'

import CmsAction from '../cms/actions/CmsAction'
import AccountAction from '../account/actions/AccountAction'
import DtoAccountStatistic from '../account/dto/DtoAccountStatistic'
import { getLogin } from '../../../utils/UserUtils'
import ModalActualities from '../home/ModalActualities'
import DtoUser from '../account/dto/DtoUser'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import { loadWMSProjections } from '../../../utils/mapUtils/CoordinateUtils'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoCity from '../referencials/city/dto/DtoCity'
import DtoInstallationGeo from '../referencials/installations/dto/DtoInstallationGeo'
import FileAction from '../referencials/documents/actions/FileAction'
import { mainOrange } from './styled/theme'

class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModalActu: false,
            actuToShow: [],
        }
    }

    componentDidMount() {
        const { cities } = this.props
        this.props.fetchCultures()
        this.props.fetchCulturesFamilies()
        this.props.fetchCodesSandre()
        this.props.fetchMatEventsTypes()
        this.props.fetchTanksTypes()
        this.props.fetchContributors()
        this.props.fetchExploitation().then(() => {
            const { exploitation } = this.props
            if (exploitation.idExploitation) {
                const { idExploitation } = exploitation
                this.props.fetchPictures(exploitation.codification.replaceAll(' ', ''), 10)
                this.props.fetchFiles(exploitation.codification.replaceAll(' ', ''), 10)
                this.props.fetchWaterTurnsExploitation(idExploitation)
                this.props.fetchInstallationsByExploitationId(idExploitation)
                this.props.fetchContributor(exploitation.operatorCode)
                this.props.fetchExploitationVolumes(idExploitation)
                this.props.fetchMatEventsByExploitation(idExploitation)
            }
        })
        if (!cities.length) {
            this.props.fetchCities()
        }
        this.props.fetchAllVariousMatTypes()
        this.props.fetchSurveys()
        this.props.fetchApplicationSettings().then(() => {
            const { applicationSettings } = this.props
            const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
            if (applicationName.value) {
                document.title = applicationName.value
            }
        })
        this.props.fetchCMSLogo()
        this.props.fetchAllVariousMateriels()
        this.props.fetchVariousMatSituations()
        this.props.fetchManagementUnits()
        this.props.fetchWaterTurnsRestrictions()
        this.props.fetchWaterTurnsSlots()
        this.props.fetchUserData(getLogin()).then(() => {
            this.props.fetchCmsActualities().then(() => {
                const { accountUser, accountStatistics, actualities } = this.props
                const orderedStats = orderBy(accountStatistics.filter((s) => s.eventType === 'LOGIN'), 'statisticDate', 'desc')
                const lastConnection = orderedStats.length >= 2 ? orderedStats[1] : orderedStats[0]
                const actuNotSeen = !lastConnection ? [...actualities] : compact(actualities.map((c) => {
                    if (lastConnection.statisticDate < c.updateDate) {
                        if (c.status === 1 || accountUser.isAdmin === '1') {
                            return c
                        }
                    }
                    return null
                }))
                const actuToShow = orderBy(actuNotSeen, 'updateDate')
                this.setState({ actuToShow, openModalActu: !!actuToShow.length })
            })
        })
        loadWMSProjections()
    }

    getBreadcrumbs = () => {
        return this.props.title.map((o) => {
            if (o && o.title) {
                const title = o.title.length > 100 ? `${o.title.substring(0, 100)}...` : o.title
                return (
                    <span
                        key={o.title}
                        className='bold clickable'
                        style={{ display: 'inline-block', color: 'white' }}
                        onClick={() => this.props.push(o.href)}
                    >
                        {title}
                    </span>
                )
            }
            return null
        })
    }

    backHome = () => {
        this.props.push('/home')
        window.scrollTo(0, 0)
    }

    toggleModal = () => {
        this.setState(({ openModalActu }) => ({ openModalActu: !openModalActu }))
    }

    render() {
        const { openModalActu, actuToShow } = this.state
        const { contributor, exploitation } = this.props
        return (
            <div>
                <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        top: '0',
                        height: '70px',
                        backgroundColor: mainOrange,
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.3rem',
                        zIndex: '1000',
                        padding: '0 10px',
                        borderBottom: '1px solid grey'
                    }}
                >
                    <Grid item xs={12} style={{ display: 'flex', overflowX: 'auto' }}>
                        <span className='bold' style={{ margin: 'auto', whiteSpace: 'nowrap', fontSize: '1.4rem' }}>{`${contributor.name || ''} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`}</span>
                    </Grid>
                    <Grid item className='clickable' style={{ margin: '8px 0' }} onClick={this.backHome}>
                        <Icon>home</Icon>
                        <Icon style={{ color: 'white' }}>keyboard_arrow_right</Icon>
                    </Grid>
                    <Grid item style={{ margin: '8px 0' }}>
                        <Breadcrumbs
                            separator={<Icon style={{ color: 'white' }}>keyboard_arrow_right</Icon>}
                            aria-label='breadcrumb'
                            style={{
                                background: 'transparent',
                                boxShadow: 'none',
                                display: 'flex',
                                fontSize: '1rem',
                            }}
                        >
                            {this.getBreadcrumbs()}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                {openModalActu &&
                    <ModalActualities
                        open={openModalActu}
                        toggleModal={this.toggleModal}
                        cms={actuToShow}
                    />
                }
            </div>
        )
    }
}

NavBar.propTypes = {
    push: PropTypes.func,
    title: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)).isRequired,
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchCodesSandre: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchExploitationVolumes: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
    fetchCMSLogo: PropTypes.func,
    fetchAllVariousMateriels: PropTypes.func,
    fetchVariousMatSituations: PropTypes.func,
    fetchContributor: PropTypes.func,
    fetchCities: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchSurveys: PropTypes.func,
    fetchAllVariousMatTypes: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchCmsActualities: PropTypes.func,
    fetchUserData: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    fetchMatEventsTypes: PropTypes.func,
    fetchWaterTurnsRestrictions: PropTypes.func,
    fetchWaterTurnsExploitation: PropTypes.func,
    fetchWaterTurnsSlots: PropTypes.func,
    fetchTanksTypes: PropTypes.func,
    fetchPictures: PropTypes.func,
    fetchFiles: PropTypes.func,
    fetchContributors: PropTypes.func,
    contributor: PropTypes.instanceOf(DtoIntervenant),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    accountUser: PropTypes.instanceOf(DtoUser),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationGeo)),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (store) => ({
    title: store.HomeReducer.title,
    exploitation: store.AgriReducer.exploitation,
    contributor: store.ReferencialReducer.contributor,
    accountUser: store.AccountReducer.accountUser,
    accountStatistics: store.AccountReducer.accountStatistics,
    actualities: store.CmsReducer.actualities,
    applicationSettings: store.HomeReducer.applicationSettings,
    cities: store.CityReducer.cities,
    installations: store.InstallationsReducer.installations,
})

const mapDispatchToProps = {
    fetchCultures: ReferencialAction.fetchCultures,
    fetchCulturesFamilies: ReferencialAction.fetchCulturesFamilies,
    fetchCodesSandre: ReferencialAction.fetchCodesSandre,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchExploitationVolumes: AgriAction.fetchExploitationVolumes,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    fetchCMSLogo: HomeAction.fetchCMSLogo,
    fetchAllVariousMateriels: InstallationsAction.fetchAllVariousMateriels,
    fetchAllVariousMatTypes: InstallationsAction.fetchAllVariousMatTypes,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchVariousMatSituations: InstallationsAction.fetchVariousMatSituations,
    fetchContributor: ReferencialAction.fetchContributor,
    fetchCities: CityAction.fetchCities,
    fetchManagementUnits: ReferencialAction.fetchManagementUnits,
    fetchSurveys: AgriAction.fetchSurveys,
    fetchWaterTurnsRestrictions: AgriAction.fetchWaterTurnsRestrictions,
    fetchCmsActualities: CmsAction.fetchCmsActualities,
    fetchUserData: AccountAction.fetchUserData,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    fetchMatEventsTypes: MaterielAction.fetchMatEventsTypes,
    fetchWaterTurnsExploitation: AgriAction.fetchWaterTurnsExploitation,
    fetchWaterTurnsSlots: AgriAction.fetchWaterTurnsSlots,
    fetchTanksTypes: ReferencialAction.fetchTanksTypes,
    fetchContributors: ReferencialAction.fetchContributors,
    fetchPictures: FileAction.fetchPictures,
    fetchFiles: FileAction.fetchFiles,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
