import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { MainButton } from '../components/styled/buttons'
import { InputRow } from '../components/styled/inputs'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import { Container, Item } from '../components/styled/grid'
import { pointStatus } from '../referencials/installations/constants/InstallationsConstants'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import LittleMap from './LittleMap'

const ModalEditPoint = ({ open,
    toggleModal,
    onSave = () => { },
    onChange = () => { },
    point = {},
    nature = '',
    citiesIndex = '',
    stateCode,
}) => {
    const handleClose = () => {
        toggleModal()
    }

    const onClickButton = () => {
        toggleModal()
        onSave()
    }

    const city = citiesIndex[point.townCode] || {}

    const codeParcelle = point.link_geo[0] ? `${point.link_geo[0].parcel} ${point.link_geo[0].section}` : ''

    const getStatus = () => {
        if (stateCode) {
            const status = pointStatus.find(({ code }) => code === stateCode) || {}
            return status.label
        }
        return ''
    }

    return (
        <DialogMUI
            open={open}
        >
            <DialogTitleMUI><b>{i18n.changeInfos}</b></DialogTitleMUI>
            <DialogContentMUI>
                <Container
                    className='overflowY'
                    style={{
                        height: isLargeWindow() ? '-webkit-fill-available' : 'auto',
                    }}
                >
                    <Item xs={12} md={6} style={{ height: isLargeWindow() ? 'calc(100% - 65px)' : '' }}>
                        <InputRow
                            item
                            id='name'
                            label={i18n.name}
                            type='text'
                            value={point.name}
                            onChange={(e) => onChange({ name: e.target.value })}
                            variant='outlined'
                        />
                        <p>{i18n.code} : {point.code}</p>
                        <p>{i18n.naturePrel} : {nature}</p>
                        <p>{i18n.status} : {getStatus()}</p>
                        <p>{`${i18n.city} : ${city && city.name ? city.name : ''}`}</p>
                        <p>{i18n.codeParcelle} : {codeParcelle}</p>
                        <p>{i18n.lieuDit} : {point.location}</p>
                        <p>{i18n.xLambert} : {point.x}</p>
                        <p>{i18n.yLambert} : {point.y}</p>
                    </Item>
                    <Item xs={12} md={6} style={{ height: '100%', padding: 4 }}>
                        <LittleMap point={point} stateCode={stateCode} height={isLargeWindow() ? 500 : 200} />
                    </Item>
                </Container>

            </DialogContentMUI>
            <DialogActionsMUI>
                <Container
                    direction='column'
                    justifyContent='stretch'
                    alignItems='center'
                >
                    <MainButton onClick={onClickButton}>
                        {i18n.validate}
                    </MainButton>
                    <MainButton reverse onClick={handleClose}>
                        {i18n.cancel}
                    </MainButton>
                </Container>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ModalEditPoint.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    point: PropTypes.instanceOf(DtoInstallation),
    nature: PropTypes.string,
    status: PropTypes.string,
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    stateCode: PropTypes.number,
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(ModalEditPoint)