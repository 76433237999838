import moment from 'moment/moment'
import { DOCUMENT } from '../constants/FileConstants'

export default class DtoFile {
    constructor(obj) {
        const pointIndex = obj.name.lastIndexOf('.')
        this.code = obj.code
        this.name = obj.name
        this.url = obj.url
        this.updateDate = obj.updateDate
        const [, , , shortNameOld, dateOld] = obj.name.slice(0, pointIndex).split('_')
        const [stationCode, type, active, date, shortName] = obj.name.slice(0, pointIndex).split('_')
        this.isAllDataName = obj.name.slice(0, pointIndex).split('_').length == 5
        this.stationCode = stationCode
        this.type = type
        this.active = active || '0'
        this.shortName = new RegExp('^[0-9]+-[0-9]+$').test(date) ? shortName : shortNameOld
        this.date = new RegExp('^[0-9]+-[0-9]+$').test(date) ? moment(date, 'YYYYMMDD-Hmmss').valueOf() : parseInt(dateOld)
        this.fileType = DOCUMENT
    }
}