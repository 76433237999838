/* eslint-disable no-console */
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AuthRoute from '../src/pages/online/AuthRoute'
import AppStore, { history } from './store/AppStore'
import fr from './ressources/i18n/properties_fr.json'
// import en from './ressources/i18n/properties_en.json'
import Login from './pages/offline/Login'
import App from './pages/online/components/App'
import Home from './pages/online/home/Home'
import Enquete from './pages/online/enquete/Enquete'
import About from './pages/About'
import MessagesApp from './pages/online/contact/MessagesApp'
import MyExploitation from './pages/online/exploitation/MyExploitation'
import PointDetailsExploit from './pages/online/exploitation/PointDetailsExploit'
import PointDetailsDecla from './pages/online/enquete/components/PointDetailsDecla'
import PointUsages from './pages/online/enquete/components/PointUsages'
import MyAccount from './pages/online/account/MyAccount'
import { mainBlue, mainGreen } from './pages/online/components/styled/theme'
import Password from './pages/offline/Password'
import Tutoriel from './pages/Tutoriel'
import MyDocuments from './pages/online/documents/MyDocuments'
import MyConsumption from './pages/online/consommation/MyConsumption'
import PointConso from './pages/online/consommation/PointConso'
import Actualities from './pages/Actualities'
import MapPoints from './pages/online/points/MapPoints'
import Admin from './pages/offline/Admin'
import PointsHisto from './pages/online/points/PointsHisto'
import MyWaterTurns from './pages/online/waterTurns/MyWaterTurns'
import PointWaterTurns from './pages/online/waterTurns/PointWaterTurns'

i18n.addLang(fr, ['fr', 'FR'])
// i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

const theme = createTheme({
    typography: {
        fontFamily: 'IBM Plex Sans',
        button: {
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: mainBlue,
        },
        secondary: {
            main: mainGreen,
        },
        action: {
            disabledBackground: 'lightgrey',
            disabled: 'white',
        },
    },
})

function win(res) {
    console.log(`success  ${JSON.stringify(res)}`)
}

function fail(res) {
    console.log(`fail ${JSON.stringify(res)}`)
}

function readTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {
        if (window.cordova) {
            window.cordova.exec(win, fail, 'idfa', 'getInfo', [])
        }
    }
}

function askTrackingPermission() {
    if (this.platform.is('cordova') && this.platform.is('ios')) {
        if (window.cordova) {
            console.log('trying to request permission ')
            window.cordova.exec(win, fail, 'idfa', 'requestPermission', [])
        }
    }
}

function onDeviceReady() {
    window.open = window.cordova.InAppBrowser.open
}
document.addEventListener('deviceready', onDeviceReady, false)
document.addEventListener('deviceready', readTrackingPermission, false)
document.addEventListener('deviceready', askTrackingPermission, false)

render(
    <ThemeProvider theme={theme}>
        <Provider store={AppStore}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path='/login' component={(props) => <Login {...props} />} />
                    <Route exact path='/reset' component={(props) => <Password {...props} />} />
                    <Route exact path='/admin' component={(props) => <Admin {...props} />} />
                    <Route path='/' component={(props) => <AuthRoute {...props} component={App} />} />
                </Switch>

                <Route exact path='/home' component={(props) => <Home {...props} />} />
                <Route exact path='/account' component={(props) => <MyAccount {...props} />} />

                <Route exact path='/contact' component={(props) => <MessagesApp {...props} />} />
                <Route exact path='/actualities' component={(props) => <Actualities {...props} />} />
                <Route exact path='/about' component={(props) => <About {...props} />} />
                <Route exact path='/tutoriel' component={(props) => <Tutoriel {...props} />} />

                <Route exact path='/exploitation' component={(props) => <MyExploitation {...props} />} />
                <Route exact path='/exploitation/map' component={(props) => <MapPoints {...props} />} />
                <Route exact path='/exploitation/historical' component={(props) => <PointsHisto {...props} />} />
                <Route exact path='/exploitation/point/:id' component={(props) => <PointDetailsExploit {...props} />} />

                <Route exact path='/waterTurns' component={(props) => <MyWaterTurns {...props} />} />
                <Route exact path='/waterTurns/point/:id' component={(props) => <PointWaterTurns {...props} />} />

                <Route exact path='/consommation' component={(props) => <MyConsumption {...props} />} />
                <Route exact path='/consommation/point/:id' component={(props) => <PointConso {...props} />} />

                <Route exact path='/documents' component={(props) => <MyDocuments {...props} />} />

                <Route exact path='/enquete' component={(props) => <Enquete {...props} />} />
                <Route exact path='/enquete/point/:id' component={(props) => <PointDetailsDecla {...props} />} />
                <Route exact path='/enquete/point/:id/usages/:type' component={(props) => <PointUsages {...props} />} />
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app'),
)
