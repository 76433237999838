import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, LinearProgress } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import PointsCard from '../components/cards/PointsCard'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import IntervenantCard from '../components/cards/IntervenantCard'
import ContactCard from '../components/cards/ContactCard'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import { formatSiret } from '../../../utils/StringUtil'
import ContactAction from '../contact/actions/ContactAction'
import DtoContact from '../referencials/dto/DtoContact'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import ToastrAction from '../components/toasters/ToastrAction'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { MainButton } from '../components/styled/buttons'
import { Item } from '../components/styled/grid'

class MyExploitation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pointsUsed: [],
            pointsNotUsed: [],
            pointsDeleted: [],
            dataLoaded: false,
            pointsLoaded: false,
            contactsLoaded: false,
            preleveur: new DtoIntervenant({}),
            contactsExploitation: [],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.myFolder,
                href: '/exploitation',
            },
        ])
        this.getExploitation()
    }

    getExploitation = () => {
        this.props.fetchExploitation().then(() => {
            const { exploitation, installations, contacts, contributors } = this.props
            this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then((declaration) => {
                if (declaration && declaration.statusCode !== 4) {
                    ToastrAction.info(i18n.modifSurveyNotShow)
                }
            })
            if (!installations.length) {
                this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                    this.setPoints(exploitation)
                })
            } else {
                this.setPoints(exploitation)
            }
            if (!contacts.length) {
                this.props.fetchContacts().then(() => {
                    this.setContacts(exploitation)
                })
            } else {
                this.setContacts(exploitation)
            }
            if (!contributors.length) {
                this.props.fetchContributors().then(() => {
                    this.setPreleveur(exploitation)
                })
            } else {
                this.setPreleveur(exploitation)
            }
        })
    }

    setPreleveur = (exploitation) => {
        const { contributors } = this.props
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        this.setState({
            preleveur: preleveur ? preleveur : new DtoIntervenant({}),
            dataLoaded: true,
        })
    }

    setPoints = (exploitation) => {
        const { installations, citiesIndex } = this.props
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        exploitation.link_samplings.forEach((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                const pointFormatted = {
                    ...point,
                    cityName: city.name,
                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                    default:
                        pointsNotUsed.push(pointFormatted)
                }
            }
        })
        this.setState({
            pointsUsed: orderPointsList(pointsUsed),
            pointsNotUsed: orderPointsList(pointsNotUsed),
            pointsDeleted: orderPointsList(pointsDeleted),
            pointsLoaded: true
        })
    }

    setContacts = (exploitation) => {
        const { contacts } = this.props
        const contactsExploitation = []
        exploitation.link_contributors.forEach((c) => {
            const contributorFind = contacts.find(
                (contact) => contact.id === c.contactCode,
            )
            if (contributorFind) {
                contactsExploitation.push({
                    ...contributorFind,
                    contributorType: c.contributorType,
                })
            }
        })
        this.setState({
            contactsExploitation: contactsExploitation,
            contactsLoaded: true,
        })
    }

    getListContacts = () => {
        const { contactsExploitation, preleveur } = this.state
        const { codesSandre, contacts } = this.props
        const typesContributors = [{ code: -1, name: i18n.legalRepresentative }, ...codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]
        let allContacts = [...contactsExploitation]
        if (preleveur) {
            const legalRepresentative = contacts.find((c) => c.id === preleveur.legalRepresentative)
            if (legalRepresentative && !allContacts.find((c) => c.contributorType === -1 || c.id === legalRepresentative.id)) {
                allContacts.unshift({
                    ...legalRepresentative,
                    contributorType: -1,
                })
            }
        }
        return allContacts.map((c) => {
            const type = typesContributors.find((t) => t.code === c.contributorType)
            return (
                <Grid item xs={12} md={4}>
                    <ContactCard
                        key={c.id}
                        contact={c}
                        title={type ? type.name : 'Inconnu'}
                        hideIcon
                        styleContainer={{ height: 'calc(100% - 10px)' }}
                        style={{ height: '100%' }}
                    />
                </Grid>
            )
        })
    }

    render() {
        const {
            dataLoaded,
            pointsLoaded,
            contactsLoaded,
            pointsUsed,
            pointsNotUsed,
            pointsDeleted,
            preleveur,
        } = this.state
        return (
            <Grid
                container
                alignItems='stretch'
                justifyContent='flex-start'
            >
                {dataLoaded ? (
                    <>
                        <Grid item xs={12}>
                            <div style={{ padding: '0 16px' }}>
                                <p>
                                    {`${i18n.id} : `}
                                    <b>{preleveur.internalIdentifier || preleveur.name}</b>
                                </p>
                                <p>
                                    {`${i18n.siret} : `}
                                    <b>{formatSiret(preleveur.siret)}</b>
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <IntervenantCard
                                selectType
                                intervenant={preleveur}
                                title={i18n.preleveur}
                                hideIcon
                                styleContainer={{ height: 'calc(100% - 10px)' }}
                                style={{ height: '100%' }}
                            />
                        </Grid>
                        {contactsLoaded && this.getListContacts()}
                        {pointsLoaded ? (
                            <>
                                <PointsCard
                                    pointsUsed={pointsUsed}
                                    pointsNotUsed={pointsNotUsed}
                                    pointsDeleted={pointsDeleted}
                                    map={[...pointsUsed, ...pointsNotUsed, ...pointsDeleted].length}
                                    className='margin-top-1'
                                />
                                <Item xs={12} className='padding-left-2 padding-right-2 padding-top-2'>
                                    <MainButton
                                        reverse
                                        onClick={() => this.props.push('/exploitation/historical')}
                                    >
                                        {i18n.accessHistorical}
                                    </MainButton>
                                </Item>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
            </Grid>
        )
    }
}

MyExploitation.propTypes = {
    setTitle: PropTypes.func,
    fetchContact: PropTypes.func,
    fetchContacts: PropTypes.func,
    fetchOperator: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    push: PropTypes.func,
    operator: PropTypes.instanceOf(DtoIntervenant),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        contacts: store.ContactReducer.contacts,
        operator: store.AgriReducer.operator,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        codesSandre: store.ReferencialReducer.codesSandre,
        contributors: store.ReferencialReducer.contributors,
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchContacts: ContactAction.fetchContacts,
    fetchOperator: AgriAction.fetchOperator,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchContributors: ReferencialAction.fetchContributors,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyExploitation)
