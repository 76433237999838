import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Fragment } from 'react'
import { Grid, LinearProgress } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import PointsCard from '../components/cards/PointsCard'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import { orderPointsList } from '../../../utils/ObjectUtils'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import { getWaterTurns } from '../../../utils/AgriUtils'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'

class MyWaterTurns extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pointsLoaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchExploitation().then(() => {
            const { exploitation, installations } = this.props
            if (!installations.length) {
                this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                    this.setState({ pointsLoaded: true })
                })
            } else {
                this.setState({ pointsLoaded: true })
            }
        })
        this.props.setTitle([{
            title: i18n.myWaterTurns,
            href: 'waterTurns',
        }])
    }

    onClickPoint = (point) => {
        this.props.push(`waterTurns/point/${point.id}`)
    }

    getColor = (point) => {
        const { waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots } = this.props
        const waterTurns = exploitationWaterTurns.filter((w) => w.idInstallation === point.id)
        if (waterTurns) {
            const res = getWaterTurns(waterTurns, waterTurnsRestrictions, waterTurnsSlots)
            if (res) {
                return res.color || 'grey'
            }
            return 'grey'
        }
        return 'grey'
    }

    getPoints = () => {
        const { exploitation, installations, citiesIndex } = this.props
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        exploitation.link_samplings.forEach((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                const pointFormatted = {
                    ...point,
                    cityName: city.name,
                    color: this.getColor(point)
                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                    default:
                        pointsNotUsed.push(pointFormatted)
                }
            }
        })
        return {
            pointsUsed: orderPointsList(pointsUsed),
            pointsNotUsed: orderPointsList(pointsNotUsed),
            pointsDeleted: orderPointsList(pointsDeleted),
        }
    }

    render() {
        const { pointsLoaded } = this.state

        if (pointsLoaded) {
            const {
                pointsUsed,
                pointsNotUsed,
                pointsDeleted,
            } = this.getPoints()
            return (
                <>
                    <Grid
                        container
                        alignItems='stretch'
                        style={{ backgroundColor: 'white' }}
                    >
                        <PointsCard
                            pointsUsed={pointsUsed}
                            pointsNotUsed={pointsNotUsed}
                            pointsDeleted={pointsDeleted}
                            openUsedPoints
                            openNoUsedPoints
                            openDeletePoints
                            onClickPoint={this.onClickPoint}
                        />
                    </Grid>
                </>
            )
        }
        return (
            <Grid
                container
                alignItems='stretch'
                style={{ backgroundColor: 'white' }}
            >
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            </Grid>
        )
    }
}

MyWaterTurns.propTypes = {
    setTitle: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    push: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        codesSandre: store.ReferencialReducer.codesSandre,
        citiesIndex: store.CityReducer.citiesIndex,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWaterTurns)
