import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { Grid, InputLabel, MenuItem, Select, styled } from '@mui/material'
import { FormControlRow, InputRow } from '../styled/inputs'
import DtoVariousMatType from '../../referencials/installations/dto/DtoVariousMatType'
import { getSetting } from '../../../../utils/FormUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getDate } from '../../../../utils/DateUtil'
import EditableCard from './EditableCard'

const Container = styled(Grid)({
    margin: '8 0',
})

class ComptageCard extends Component {
    getContent = (editMode, data) => {
        const { variousMatTypes, applicationSettings } = this.props

        const materielReadingCoeffEntry = getSetting(applicationSettings, 'materielReadingCoeffEntry')
        if (editMode) {
            return (
                <>
                    <InputRow item id='type' label={i18n.type} type='text' variant='outlined' />
                    <InputRow item id='ref' label={i18n.ref} type='text' variant='outlined' />
                    <InputRow item id='marque' label={i18n.marque} type='text' variant='outlined' />
                    <InputRow
                        item
                        id='poseYear'
                        label={i18n.poseYear}
                        type='number'
                        variant='outlined'
                    />
                    <InputRow
                        item
                        id='revisionDate'
                        label={i18n.revisionDate}
                        type='number'
                        variant='outlined'
                    />
                    {materielReadingCoeffEntry === '1' || data.materielType === 10 ? (
                        <FormControlRow variant='outlined'>
                            <InputLabel>{i18n.coefLecture}</InputLabel>
                            <Select
                                id='readingCoefficient'
                                label={i18n.coefLecture}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                            </Select>
                        </FormControlRow>
                    ) : (
                        <InputRow
                            item
                            id='maxFlow'
                            variant='outlined'
                            label={i18n.coefLecture}
                            type='number'
                        />
                    )}
                    <InputRow
                        item
                        id='sharedDispositif'
                        label={i18n.sharedDispositif}
                        type='text'
                        variant='outlined'
                    />
                    <InputRow
                        item
                        id='comment'
                        label={i18n.comment}
                        type='text'
                        variant='outlined'
                    />
                    <InputRow
                        item
                        id='incident'
                        label={i18n.incident}
                        type='text'
                        variant='outlined'
                    />
                    <InputRow
                        item
                        id='dateReleve'
                        label={i18n.dateReleve}
                        type='text'
                        variant='outlined'
                    />
                    <InputRow item id='index' label={i18n.index} type='text' variant='outlined' />
                </>
            )
        }
        const counterType = variousMatTypes.find((t) => t.materielType === data.materielType)
        return (
            <>
                <Container>{i18n.name} : {data.name || ''}</Container>
                <Container>{i18n.type} : {counterType ? counterType.name : ''}</Container>
                <Container>{i18n.marque} : {data.brand || ''}</Container>
                <Container>{i18n.reference} : {data.serialNumber || ''}</Container>
                <Container>{i18n.coefLecture} : {data.readingCoefficient || ''}</Container>
                <Container>{i18n.installationDate} : {getDate(data.installationDate) || ''}</Container>
                <Container>{i18n.revisionDate} : {getDate(data.revisionDate) || ''}</Container>
                <Container>{i18n.tauxAffectation} : {hasValue(data.assignmentRate) ? `${data.assignmentRate}%` : ''}</Container>
                <Container>{i18n.sharedDispositif} : {data.isShared ? i18n.yes : i18n.no}</Container>
                <Container>{'IAM'} : {data.provenImpossibility ? i18n.yes : i18n.no}</Container>
                <Container>{i18n.agencyDecisionDate} : {data.agencyDecisionDate ? getDate(data.agencyDecisionDate) : ''}</Container>
                {hasValue(data.comment) && <Container>{i18n.comment} : {data.comment || ''}</Container>}
            </>
        )
    }

    render() {
        const { title, editMode, hideIcon, data, colorTitle } = this.props
        return (
            <EditableCard
                title={title}
                editMode={editMode}
                toggleEditMode={this.props.toggleEditMode}
                hideIcon={hideIcon}
                colorTitle={colorTitle}
            >
                {this.getContent(editMode, data)}
            </EditableCard>
        )
    }
}

ComptageCard.propTypes = {
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    colorTitle: PropTypes.string,
    selectType: PropTypes.bool,
    hideIcon: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    variousMatTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatType)),
    applicationSettings: PropTypes.arrayOf(PropTypes.object),
}

ComptageCard.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

export default connect(mapStateToProps)(ComptageCard)
