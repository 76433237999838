import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { makeStyles } from '@mui/styles'
import { Modal } from '@mui/material'
import { MainButton } from '../components/styled/buttons'

function getModalStyle() {
    return {
        top: '0%',
        left: '0%',
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1),
        margin: theme.spacing(10, 3),
    },
}))

export default function ModalEnquete({ open, toggleModal, onClick, enquete = {} }) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    const handleClose = () => {
        toggleModal()
    }

    const onClickButton = () => {
        onClick()
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id='modal-title'>
                {i18n.enquete}&nbsp;{enquete.year}
            </h2>
            <p id='modal-text'>
                La déclaration de vos prélèvements d'eau prévisionnels et réels est maintenant
                disponible. Vous avez jusqu'au {new Date(enquete.endDate).toLocaleString()} pour renseigner votre dossier.
            </p>
            <p id='modal-description' className='italic'>Pour tout renseignement, veuillez contacter votre OUGC.</p>
            <MainButton variant='contained' onClick={onClickButton}>
                {i18n.start}
            </MainButton>
            <MainButton reverse variant='contained' onClick={handleClose} margintop>
                {i18n.later}
            </MainButton>
        </div>
    )

    return (
        <Modal
            open={open}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            {body}
        </Modal>
    )
}

ModalEnquete.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    enquete: PropTypes.instanceOf(PropTypes.object),
}
